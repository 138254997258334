.container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  max-width: 100%;
}

aside {
  width: 25%;
  padding: 1em;
  background-color: #ecf0f1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

aside .member-info {
  text-align: center;
  margin-bottom: 1em;
}

aside .member-info img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 2px solid #106C65;
}

aside .member-info input {
  width: 100%;
  padding: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
}

aside .member-info button {
  background-color: #32A8A2;
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  cursor: pointer;
}

aside .levels, .referrals, .earnings {
  margin-top: 1em;
}

aside .levels h4, .referrals h4, .earnings h4 {
  margin-top: 0;
  font-size: 1.2em;
 
}

aside .levels ul, .referrals ul {
  list-style-type: none;
  padding: 0;
}

aside .levels ul li, .referrals ul li {
  padding: 0.5em;
  margin-bottom: 0.5em;
  background-color: #bdc3c7;
  border-radius: 5px;
  text-align: center;
}
.levels li{
display: flex;
justify-content: space-between;
font-size: 14px;
}
.levels li p{
padding: 0px;
margin: 0px;
}
.referrals ul li{
  display: flex;
  justify-content: space-between;
  }
.referrals ul li p{
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  }
.referrals ul li .fa-circle{
  color: #106C65;
  font-size: 13px;
  margin-top: 3px;
  padding-left: 6px;
  }

aside .levels ul li.completed {
  background-color: #32A8A2;
  color: #fff;
}

aside .levels ul li.current {
  background-color: #f39c12;
  color: #fff;
}

aside .levels ul li.locked {
  background-color: #f8d7da;
  color: black;
}

main {
  flex: 1;
  padding: 1em;
}

main h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
  color: #106C65;
}

main p {
  margin-bottom: 1.5em;
}

.levels-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.level-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 1em;
  text-align: center;
  width: calc(33.333% - 1em);
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.level-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.level-card.completed {
  background-color: #d4edda;
}

.level-card.current {
  background-color: #fff3cd;
}

.level-card.locked {
  background-color: #f8d7da;
}

.level-card h2 {
  margin-top: 0;
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #106C65;
}

.level-card p {
  margin-bottom: 1em;
}

.level-card button {
  background-color: #106C65;
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  cursor: pointer;
}
.buttondiv{
 display: flex;
 justify-content: space-between;
 
}
.fa-circle-check{
color: #106C65;
font-size: 28px;
margin-top: 4px;
}

.level-card button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

footer {
  margin-top: auto;
}

footer .quick-links a {
  margin-right: 1em;
  color: #ecf0f1;
  text-decoration: none;
}
aside main{
  display: none;
  }

footer .contact-info {
  margin-top: 1em;
}

aside .earnings {
  padding: 1em;
  background-color: #eaf2f8;
  border-radius: 5px;
  text-align: center;
}

aside .earnings h4 {
  margin: 0 0 0.5em 0;
  color: #106C65;
}
.details-modal {
  position: fixed;
  top: 0;
  left: 0;
  border: #106C65 2px solid;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.details-content {
  background-color: white;
  border: #32A8A2 5px solid;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 500px;
  height: 90vh;
  text-align: center;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 34px;
  cursor: pointer;
}

.done-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #106C65;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.modal-body p {
  text-align: start;
  margin-bottom: 10px; 
  line-height: 1.6; 
  font-size: 19px;
}




@media (min-width: 768px) and (max-width: 1180px) {
  aside{
  width: 32%;
  }
  .level-card {
    width: calc(51% - 1em);
    
  }
  .level-card button {
    background-color: #106C65;
    color: #fff;
    border: none;
    padding: 0.5em 1em;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  aside main{
    display: none;
    }
    .levels li{
      font-size: 10px;
      }
}



@media (min-width: 480px) and (max-width: 768px) {
  aside {
    width: 100%;
    margin-bottom: 1em;
  }

  .level-card {
    width: calc(51% - 1em);
  }
  .level-card button {
    background-color: #106C65;
    color: #fff;
    border: none;
    padding: 0.5em 1em;
    font-size: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  aside main{
  display: block;
  }
  .levels-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
  }
  main{
    display: none;
    }
    .details-content {
      width: 100%;
      max-width: 500px;
      max-height: 85vh; /* Prevent the modal from exceeding 90% of the viewport height */
      overflow-y: auto; /* Allow scrolling if the content is too tall */
      padding: 20px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }
    .modal-body p {
      text-align: start;
      margin-bottom: 10px; 
      line-height: 1.6; 
      font-size: 17px;
    }
}

@media (max-width: 480px) {
  aside {
    width: 100%;
    margin-bottom: 1em;
  }
  header .logo {
    font-size: 1.2em;
  }

  header nav ul li {
    display: block;
    margin-bottom: 0.5em;
  }

  .level-card {
    width: 100%;
  }
  aside main{
    display: block;
    }
    .levels-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1em;
    }
    main{
      display: none;
      }
      .level-card button {
        background-color: #106C65;
        color: #fff;
        border: none;
        padding: 0.5em 1em;
        font-size: 11px;
        border-radius: 5px;
        cursor: pointer;
      }
      .levels li{
      font-size: 12px;
      }
      .details-content {
        width: 100%;
        max-width: 350px;
        max-height: 80vh; /* Prevent the modal from exceeding 90% of the viewport height */
        overflow-y: auto; /* Allow scrolling if the content is too tall */
        padding: 15px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }
      .modal-body p {
        text-align: start;
        margin-bottom: 10px; 
        line-height: 1.6; 
        font-size: 15px;
      }
}
.stats{

padding: 5px;
border-radius: 15px;
}
.stats ul{
list-style-type: none;
padding: 7px 5px;
margin: 0px;
}
.stats li{
margin-bottom: 8px;
}
.sidebar {
  background-color: #106C65;
  padding: 15px;
  color: white;
  font-family: Arial, sans-serif;
  width: 99%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.team-stats {
  background-color: #32A8A2;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.team-stats h2 {
  margin-top: 0;
  font-size: 19px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  transition: background-color 0.3s;
}

.stat-item:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.stat-label {
  font-size: 16px;
  margin-right: 10px;
  color: #fff;
}

.stat-circle {
background-color: #fff;
color: #106C65;
min-width: 50px; /* Ensure minimum width to accommodate larger numbers */
min-height: 50px; /* Ensure minimum height to accommodate larger numbers */
padding: 5px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
font-size: 16px;
font-weight: bold;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
transition: transform 0.3s, box-shadow 0.3s;
text-align: center;
line-height: 1.2;
}

.stat-circle:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.back-button {
display: flex;
align-items: center;
background-color: transparent;
border: none;
color: #106C65;
font-size: 16px;
cursor: pointer;
}

.back-button svg {
margin-right: 2px;
}
.level-card.green {
  background-color: #d4edda;
  color: black;
}

.level-card.yellow {
  background-color: #fff3cd;
  color: black;
}

.level-card.red {
  background-color: #f8d7da;
  color: black;
}
