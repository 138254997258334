.verified-members-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #e0f7e9, #a1e1b5);
  min-height: 100vh;
}

.verified-members-list {
  width: 100%;
  max-width: 800px;
}

.verified-member-card {
  background-color: #fff;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 20px; /* زيادة المسافة بين الكروت */
  cursor: pointer;
  transition: transform 1.3s ease, box-shadow 1.3s ease, max-height 1.5s ease; /* تحسين التأثيرات */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-height: 170px; /* زيادة الارتفاع الأساسي */
}

.verified-member-card.active {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  max-height: 450px; /* زيادة الارتفاع عند تفعيل الكارت */
  transition: max-height 1.5s ease, transform 1.5s ease; /* تقليل سرعة الفتح */
}

.verified-member-info {
  color: #106C65;
}

.member-name2 {
  font-size: 1.3rem; /* تكبير حجم الخط للاسم */
  font-weight: bold;
  margin-bottom: 10px; /* مسافة بين الاسم والعناصر الأخرى */
  margin-left: 0px;
  text-align: left;
}

.member-id,
.member-date {
  font-size: 1.1rem;
  margin-top: 5px;
  margin-bottom: 5px; /* مسافة بين ID والتاريخ */
}

.member-actions22 {
  margin-top: 15px;
  display: none; /* إخفاء الأزرار في الوضع العادي */
  justify-content: space-between;
  opacity: 0; /* جعل الأزرار غير مرئية في البداية */
  transition: opacity 1.5s ease; /* تأثير تلاشي */
}

.verified-member-card.active .member-actions22 {
  display: flex; /* إظهار الأزرار عند الضغط على الكارت */
  opacity: 1; /* جعل الأزرار مرئية */
  animation: fadeIn 1.5s forwards; /* إضافة انيميشن الظهور */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.action-btn {
  background-color: #34c759;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.action-btn:hover {
  background-color: #28a745;
  transform: translateY(-3px);
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center; /* توسيط العناصر */
  margin-bottom: 20px; /* مسافة أسفل شريط البحث */
  width: 100%;
}

.search-input {
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none; /* إزالة التأثير الخارجي عند التركيز */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  width: 100%;
  max-width: 400px; /* تحديد عرض أقصى */
  margin-right: 10px; /* مسافة بين الحقل والأيقونة */
}

.search-icon {
  color: #106C65; /* لون الأيقونة */
  font-size: 1.2rem;
  cursor: pointer; /* تغيير شكل المؤشر عند التحويم */
  transition: color 0.3s ease; /* تأثير التحويم */
}

.search-icon:hover {
  color: #32A8A2; /* تغيير لون الأيقونة عند التحويم */
}


@media (max-width: 768px) {
  .verified-member-card {
    padding: 15px;
    height: 100%;
  }

  .action-btn {
    flex: 1;
    padding: 8px 12px;
    text-align: center;
  }

  .member-actions22 {
    flex-direction: column;
    gap: 10px;
  }
  .verified-member-card.active {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    max-height: 650px; /* زيادة الارتفاع عند تفعيل الكارت */
    transition: max-height 1.5s ease, transform 1.5s ease; /* تقليل سرعة الفتح */
  }
  
}
.reward-input-container {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.reward-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 1rem;
}

.send-reward-btn {
  background-color: #34c759;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.send-reward-btn:hover {
  background-color: #28a745;
  transform: translateY(-3px);
}

.success-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #34c759;
  color: white;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-size: 1.2rem;
  animation: fadeInOut 3s ease forwards;
  z-index: 50;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* لجزء Send Rewards */
.reward-input-container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.reward-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
}

.send-reward-btn {
  background-color: #34c759;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.send-reward-btn:hover {
  background-color: #28a745;
}

/* لجزء Free Membership */
.level-input-container {
  display: flex;
  flex-direction: row; /* تغيير إلى عمود ليكون متجاوبًا */
  margin-top: 15px;
  gap: 20px;
}

.level-checkboxes {
  display: flex;
  flex-wrap: wrap; /* السماح للمربعات بالتفاف */
  gap: 10px; /* الفجوة بين المربعات */
}

.level-box {
  padding: 10px 15px;
  background-color: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  flex: 1 1 calc(20% - 10px); /* تحديد العرض ليكون 20% مع فجوة */
  min-width: 100px; /* تحديد عرض أدنى للمربعات */
  max-width: 150px; /* تحديد عرض أقصى للمربعات */
}

.level-box.checked {
  background: linear-gradient(135deg, #6dd5ed, #2193b0);
  color: white;
}

.save-level-btn {
  background-color: #34c759;
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  
}

.save-level-btn:hover {
  background-color: #28a745;
}

/* تحسين التفاعل مع الشاشات الصغيرة */
@media (max-width: 768px) {
  .level-input-container {
    display: flex;
    flex-direction: column; /* تغيير إلى عمود ليكون متجاوبًا */
    align-items: flex-start; /* محاذاة العناصر إلى اليسار */
    margin-top: 15px;
  }

  .save-level-btn {
    margin-top: 10px;
    width: 100%; /* جعل زر الحفظ بعرض كامل */
  }
}


/* رسالة النجاح */
.notification-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #34c759;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeInOut 2s ease;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  10%, 90% {
    opacity: 1;
  }
}

/* تحسين التفاعل مع الشاشات الصغيرة */
@media (max-width: 768px) {
  .reward-input-container, .level-input-container {
    flex-direction: column;
    align-items: stretch;
  }

  .save-level-btn {
    margin-top: 10px;
  }
}
