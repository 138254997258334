/* إعداد الصفحة */
.user-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #74ebd5, #ACB6E5);
    padding: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  /* تصميم بطاقة الملف الشخصي */
  .user-profile-card {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    max-width: 550px;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .user-profile-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  }
  
  /* تصميم صورة الملف الشخصي */
  .user-profile-picture img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #3498db;
    transition: border 0.3s ease;
    margin-bottom: 20px;
  }
  
  .user-profile-card:hover .user-profile-picture img {
    border-color: #2ecc71;
  }
  
  /* معلومات الملف الشخصي */
  .user-profile-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .user-profile-details p {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    border-left: 5px solid #3498db;
    margin: 0;
  }
  
  /* صور بطاقة الهوية */
  .user-id-images {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; /* مسافة بين قسم المعلومات والصور */
    flex-wrap: wrap; /* للسماح بلف العناصر على الشاشات الصغيرة */
  }
  
  .user-id-image {
    text-align: center;
    width: 48%; /* عرض كل صورة مع وجود مسافة بينها */
    margin-bottom: 15px; /* مسافة بين الصور */
  }
  
  .user-id-image img {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ddd;
  }
  /* نمط النافذة المنبثقة */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* تأكد من أن النافذة فوق جميع العناصر الأخرى */
    padding: 20px; /* إضافة حيز padding */
  }
  
  .modal-content270 {
    position: relative;
    max-width: 100%; /* استخدام 100% لتناسب الإطار */
    max-height: 100%; /* استخدام 100% لتناسب الإطار */
    overflow: hidden; /* إخفاء أي تجاوز */
    border-radius: 10px; /* إضافة بعض الزوايا المدورة */
  }
  
  .modal-content270 img {
    max-width: 100%; /* التأكد من أن الصورة لا تتجاوز عرض الإطار */
    max-height: 80vh; /* التأكد من أن الصورة لا تتجاوز ارتفاع الإطار */
    object-fit: contain; /* الحفاظ على نسبة الطول والعرض للصورة */
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: black;
    font-size: 24px;
    cursor: pointer;
  }

  .user-profile-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; /* مسافة أعلى الأزرار */
  }
  
  .approve-button,
  .reject-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .approve-button {
    background-color: #28a745; /* لون زر الموافقة */
  }
  
  .reject-button {
    background-color: #dc3545; /* لون زر الرفض */
  }
  
  .approve-button:hover {
    background-color: #218838; /* لون عند التمرير على زر الموافقة */
  }
  
  .reject-button:hover {
    background-color: #c82333; /* لون عند التمرير على زر الرفض */
  }
  
  .rejection-comment {
    display: flex;
    align-items: center;
    margin-top: 10px; /* مسافة أعلى حقل التعليق */
  }
  
  .rejection-comment input {
    flex: 1; /* يأخذ الحقل كل المساحة المتاحة */
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px; /* مسافة بين الحقل وزر الإرسال */
  }
  
  .send-comment {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff; /* لون زر الإرسال */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .send-comment:hover {
    background-color: #0056b3; /* لون عند التمرير على زر الإرسال */
  }
  
  /* نافذة الصورة المنبثقة */
.image-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
  }
  
  .modal-content270 {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
    position: relative; /* لجعل علامة الـ X داخل إطار الصورة */
  }
  
  .close-button {
    position: absolute;
    top: 10px; /* وضعه في أعلى الصورة */
    right: 10px; /* وضعه في أعلى اليمين */
    color: white;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5); /* خلفية نصف شفافة لعلامة X */
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  /* زيادة التباعد على الشاشات الصغيرة */
  @media (max-width: 768px) {
    .user-profile-card {
      padding: 20px;
    }
  
    .user-profile-details p {
      font-size: 14px;
    }
  
    .user-id-images {
        justify-content: space-around; /* توزيع العناصر بالتساوي */
      }
    
      .user-id-image {
        width: 48%; /* عرض الصورة مع وجود مسافة */
        margin-bottom: 15px; /* مسافة بين الصور */
      }
  }
  
  