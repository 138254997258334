@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');



.containerSU {
    display: flex;
    height: 100%;
    width: 100%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    overflow: hidden;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-section {
    flex: 1;
    background: url('/public/images/Screenshot(321).jpg') no-repeat center center;
    background-size: cover;
    position: relative;
}

.form-section {
    flex: 1;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
}

.form-section h2 {
    color: #106C65;
    margin-bottom: 20px;
    font-size: 2.5em;
    font-weight: 600;
}

.form-section .input-group {
    margin-bottom: 20px;
    position: relative;
}

.form-section .input-group label {
    display: block;
    color: #106C65;
    margin-bottom: 8px;
    font-weight: 600;
}

.form-section .input-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 30px;
    transition: border-color 0.3s, box-shadow 0.3s;
    font-size: 1em;
    background: #f9f9f9;
}

.form-section .input-group input:focus {
    border-color: #32A8A2;
    box-shadow: 0 0 15px rgba(50, 168, 162, 0.4);
    outline: none;
    background: #fff;
}

.form-section .input-group .error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    position: absolute;
    bottom: -20px;
    left: 0;
}

.form-section .input-group .toggle-password {
    position: absolute;
    right: 10px;
    top: 70%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: url('/public/images/eye.png') no-repeat center center;
    background-size: cover;
}

.form-section .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.form-section .options a {
    color: #32A8A2;
    text-decoration: none;
    font-weight: 600;
}

.form-section .options a:hover {
    text-decoration: underline;
}

.form-section .buttons {
    display: flex;
    justify-content: space-between;
}

.form-section .buttons button {
    padding: 12px 25px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.form-section .buttons .sign-up {
    background-color: #106C65;
    color: #fff;
}

.form-section .buttons .sign-up:hover {
    background-color: #0e5e59;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.form-section .buttons .sign-in {
    background-color: transparent;
    border: 2px solid #106C65;
    color: #106C65;
}

.form-section .buttons .sign-in:hover {
    background-color: #106C65;
    color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Responsive styles */
@media (max-width: 1024px) {
    .containerSU {
        flex-direction: column;
        height: auto;
    }
    .image-section {
        height: 250px; 
        display: none;
    }
    .form-section {
        padding: 40px 20px;
    }
    .form-section h2 {
        font-size: 2em;
    }
}

@media (max-width: 768px) {
    .image-section {
        height: 200px; /* Adjust height for smaller tablets */
    }
    .form-section {
        padding: 20px;
    }
    .form-section h2 {
        font-size: 1.5em;
    }
    .form-section .buttons {
        flex-direction: column;
        align-items: center;
    }
    .form-section .buttons button {
        width: 100%;
        font-size: 1.1em;
        margin: 10px 0;
    }
}

@media (max-width: 480px) {
    .image-section {
        height: 150px; /* Adjust height for mobile phones */
    }
    .form-section {
        padding: 15px;
    }
    .form-section h2 {
        font-size: 1.2em;
    }
    .form-section .buttons button {
        padding: 10px;
        font-size: 0.9em;
    }
}
