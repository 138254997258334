.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  h2 {
    margin: 0 0 20px;
  }
  
  input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    background-color: #32A8A2;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
  }
  










body {
    font-family: 'Arial', sans-serif;
    background: #f4f4f4;
    margin: 0px;
    color: #333;
    animation: fadeIn 2s ease-in-out;
    overflow-x: hidden; 
}

.wallet-container {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 90%;
    margin-top: 20px;
    max-width: 800px;
    text-align: center;
    animation: fadeInUp 1s ease-in-out;
}

.balance-section {
    margin-bottom: 40px;
    animation: fadeInUp 1s ease-in-out 0.2s;
}

.balance-section h2 {
    font-size: 1.5em;
    color: #666;
    font-weight: 400;
}

.balance-section h1 {
    font-size: 3em;
    color: #106C65;
    margin: 10px 0;
    font-weight: 700;
}

.balance-section p {
    font-size: 1em;
    color: #999;
    margin-top: 10px;
}

.buttons2 {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    animation: fadeInUp 1s ease-in-out 0.4s;
}

.buttons2 button {
    background-color: #106C65;
    color: #fff;
    border: none;
    padding: 15px 40px;
    border-radius: 30px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}
.buttons2 .topup {
    background-color: #fff;
    color: #106C65;
    border: none;
    padding: 10px 35px;
    border-radius: 30px;
    border: #106C65 4px solid;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s,  transform 0.3s, box-shadow 0.3s;
}

.buttons2 button:hover {
    background-color: #32A8A2;
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}
.buttons2 .topup:hover {
    background-color: #32A8A2;
    color: #fff;
    border-color: #32A8A2;
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}


.withdrawal-address-section {
    margin-bottom: 40px;
    animation: fadeInUp 1s ease-in-out 0.6s;
    text-align: center;
}

.withdrawal-address-section h3 {
    font-size: 1.5em;
    color: #666;
    margin-bottom: 20px;
}

.withdrawal-address-section input {
    padding: 15px;
    width: calc(100% - 170px);
    border: 1px solid #ccc;
    border-radius: 30px;
    margin-right: 10px;
    font-size: 1.2em;
}

.withdrawal-address-section .save-address {
    background-color: #106C65;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 30px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.withdrawal-address-section .save-address:hover {
    background-color: #32A8A2;
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.transactions {
    text-align: left;
    margin-bottom: 20px;
    animation: fadeInUp 1s ease-in-out 0.8s;
}

.transactions h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #666;
}

.transactions ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.transactions li {
    background-color: #f9f9f9;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: fadeInUp 1s ease-in-out 1s;
    position: relative;
}

.transactions li .transaction-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.transactions li .transaction-name {
    display: inline-block;
    width: auto;
    font-weight: 600;
}

.transactions li .transaction-amount2 {
    background-color: #106C65;
    color: #fff;
    width: 170px;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 600;
    font-feature-settings: "tnum";
    text-align: center;
    margin-left: 10px;
}

.transactions li .transaction-amount2.negative {
    background-color: #d9534f;
}

.view-all {
    background-color: #106C65;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 30px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    animation: fadeInUp 1s ease-in-out 1.2s;
}

.view-all:hover {
    background-color: #32A8A2;
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.wallet-container, .balance-section, .buttons2, .withdrawal-address-section, .transactions, .view-all, .transactions li {
    animation: fadeInUp 1s ease-in-out;
}
.totaalDiv{
width: 91%;
}

/* Responsive Design */
@media (max-width: 768px) {
    .wallet-container {
        width: 95%;
        padding: 20px;
    }

    .balance-section p, .buttons2 button, .withdrawal-address-section h3, .withdrawal-address-section input, .withdrawal-address-section .save-address, .transactions h3, .transactions li {
        font-size: 1em;
    }


    .withdrawal-address-section input {
        width: calc(100% - 150px);
    }

    .transactions li .transaction-name, .transactions li .transaction-amount2 {
        font-size: 0.9em;
    }
    .totaalDiv{
        width: 89%;
        }
}

@media (max-width: 480px) {
    .wallet-container {
        width: 100%;
        padding: 10px;
    }

    .balance-section h2 {
        font-size: 1.2em;
    }

    .balance-section h1 {
        font-size: 2em;
    }

    .balance-section p {
        font-size: 0.9em;
    }

    .buttons2 button {
        font-size: 1.2em;
        padding: 12px 45px;
    }

    .withdrawal-address-section h3 {
        font-size: 1.2em;
    }

    .withdrawal-address-section input {
        font-size: 1em;
        width: calc(100% - 130px);
    }

    .withdrawal-address-section .save-address {
        font-size: 1em;
        padding: 10px 20px;
    }

    .transactions h3 {
        font-size: 1.2em;
    }

    .transactions li {
        font-size: 0.9em;
        padding: 10px;
    }

    .transactions li .transaction-name, .transactions li .transaction-amount2 {
        font-size: 0.9em;
    }

    .view-all {
        font-size: 1em;
        padding: 10px 20px;
    }
    .buttons2 .topup {
        font-size: 1em;
        padding: 12px 45px;
    }
    .totaalDiv{
        width: 85%;
        }
}
