body {
    font-family: 'Arial', sans-serif;
    background: #f4f4f4;
    margin: 0px;
    color: #333;
    
}

.error-message {
    color: red;
    font-size: 0.875rem;
    margin-left: 0.5rem;
    display: block; 
}


.profile-container {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 90%;
    max-width: 600px;
    text-align: center;
    animation: fadeInUp 1s ease-in-out;
    margin: 20px;
    overflow-y: auto;
}

.profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.profile-picture {
    position: relative;
    width: 100px;
    height: 100px;
    margin-bottom: 45px;
  }
  
  .profile-picture img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

.profile-id {
    margin-top: 5px;
    
    font-size: 0.9em;
    display: flex;
    align-items: center;
    gap: 5px;
}

.profile-id .copy-icon {
    cursor: pointer;
    font-size: 1.2em;
}

.profile-status {
    padding: 9px;
    border-radius: 10px;
    display: inline-block;
    min-width: 120px;
    text-align: center;
    font-size: 0.9em;
}

.status span {
    font-weight: bold;
    color: #fff;
}

.status-under-review {
    background-color: #f1c40f;
}

.status-verified {
    background-color: #27ae60;
}

.status-rejected {
    background-color: #e74c3c;
}

.form-group {
    margin-bottom: 10px;
    text-align: left;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 1em;
}

.phone-input {
    display: flex;
    gap: 10px;
}

.phone-input input {
    flex: 1;
}

.phone-input input:first-child {
    flex: 0.23;
}

.upload-fields {
    display: flex;
    gap: 10px;
}

.save-button2 {
    padding: 10px 60px;
    font-size: 1.2em;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background-color: #106C65;
    color: #fff;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.save-button2:hover {
    background-color: #32A8A2;
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .profile-container {
        padding: 15px;
        margin: 20px;
    }



    .profile-status {
        margin-top: 10px;
    }

    .upload-fields {
        flex-direction: column;
    }

    .upload-fields div {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .upload-fields div:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 480px) {
    .profile-container {
        width: 95%;
        padding: 20px;
    }
    .profile-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .input-field {
        font-size: 0.9em;
    }

    .save-button2 {
        font-size: 1em;
    }

    .upload-fields {
        flex-direction: column;
    }
}

  
  .add-photo-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-photo-icon i {
    font-size: 16px;
  }
  
  .add-photo-icon:hover {
    background-color: #106C65;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
}

.edit-icon {
    cursor: pointer;
    margin-left: 8px;
}

.notification-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #34c759;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeInOut 2s ease;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.review-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* محاذاة العناصر للأعلى */
    height: 100%;
    border: 4px solid #106C65;
    background-color: #f9f9f9; /* لون الخلفية العام */
    padding-top: 20px; /* مسافة علوية */
    max-width: 100%;
  }
  
  .logo-container {
    margin-bottom: 20px; /* مسافة بين الشعار والمستطيل */
  }
  
  .review-logo {
    width: 200px;
    height: auto;
  }
  
  .review-box {
    width: 80%;
    max-width: 600px;
    padding: 20px;
    border: 2px solid #106C65; /* حدود الصفحة باللون الأخضر */
    border-radius: 10px;
    text-align: center;
    background-color: #fff; /* خلفية الصندوق */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* تأثير الظل */
  }
  
  .welcome-text {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .team-name {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .thanks-text {
    font-size: 1rem;
    margin-bottom: 10px;
    font-style: italic;
  }
  
  .review-message {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 30px;
    line-height: 1.5;
  }
  
  .owner-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    background-color: #106C65; /* لون مشابه للوجو */
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .owner-button:hover {
    background-color: #32A8A2;
    color: #fff;
  }
  