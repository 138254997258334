/* إعداد الخلفية المتدرجة الخضراء */
.SanadMembers{
  width: 100%;
  padding: 10px 5px;
  background: linear-gradient(135deg, #2e8b57, #22543d); /* أخضر غامق متدرج */
}
/* إعداد الخلفية المتدرجة الخضراء */
.sanad-members-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  height: 100vh;
  padding: 20px;
  position: relative;
}

/* السهم للرجوع */
.back-arrow {
  position: absolute;
  top: 20px;
  left: 15px;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.back-arrow:hover {
  transform: scale(1.1);
}

/* إعداد تصميم المربعات */
.member-card55 {
  width: 280px;
  height: 220px;
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* ظل جذاب */
  text-align: left;
  padding: 40px 20px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s;
  color: white; /* نص أبيض */
  margin: 10px;
  flex: 1 1 calc(33.33% - 20px);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.member-card55 i {
  font-size: 40px;
  margin-bottom: 5px;
}
.member-card55 i:hover {
color: white;
}

/* تأثير التدرج والأنماط للمربعات */
.member-card55::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.2));
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.member-card55:hover::before {
  opacity: 1;
}


.red {
 background-color: #FF6347;
}

/* مربع Under review Members */
.yellow {
  background: #FF8C00 ;
}

/* مربع Verified Members */
.green {
  background: #32CD32;
}

/* إعداد التأثير عند التحويم */
.member-card55:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* ظل أعمق عند التحويم */
}

/* إعداد النص */
.member-card55 h3 {
  font-size: 27px;
  font-weight: 700;
}

/* إعداد المربعات لتكون متجاوبة مع جميع الشاشات */
@media (max-width: 768px) {
  .sanad-members-page {
    flex-direction: column;
  }

  .member-card55 {
    width: 80%;
    margin: 10px auto;
  }
}

@media (max-width: 480px) {
  .member-card55 {
    width: 100%;
  }

  .back-arrow {
    font-size: 20px;
  }
}