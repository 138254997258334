.user-transactions-container {
    padding: 20px;
    background: linear-gradient(135deg, #f3f4f6, #e2e8f0);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .transactions-list {
    width: 100%;
    max-width: 800px;
  }
  
  .transaction-card {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .transaction-card.success {
    border-left: 5px solid #34c759;
  }
  
  .transaction-card.failed {
    border-left: 5px solid #ff3b30;
  }
  
  .transaction-info {
    margin-bottom: 10px;
    word-wrap: break-word;
  }
  
  .transaction-type {
    font-weight: bold;
    font-size: 1.2rem;
    color: #4a5568;
  }
  
  .transaction-amount,
  .transaction-date,
  .transaction-time,
  .transaction-status,
  .transaction-wallet {
    margin-top: 5px;
    color: #6b7280;
    flex-wrap: wrap;
  }
  
  .view-details-btn {
    background-color: #3182ce;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .view-details-btn:hover {
    background-color: #2b6cb0;
  }
  
  @media (max-width: 768px) {
    .transaction-card {
      padding: 15px;
    }
  
    .view-details-btn {
      width: 100%;
    }
  }
  .transaction-status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    color: white;
  }
  
  /* اللون الأخضر للحالة Paid */
  .status-paid {
    background-color: #28a745;
    color: white;
  }
  
  /* اللون الأحمر للحالتين Failed و Canceled */
  .status-failed {
    background-color: #dc3545;
    color: white;
  }
  .status-check{
     background-color: gray;
    color: white;
  }