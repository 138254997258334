::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(125deg, #fb3c7f 0%, #ffc05e 100%);
  border-radius: 10px;
}



ol {
  counter-reset: counter;
  padding-left: 0;
}

ol li {
  list-style: none;
  margin-bottom: 1rem;
}

ol li:before {
  counter-increment: counter;
  content: counter(counter);
  font-weight: 500;
  margin-right: 10px;
}


.dark-bg {
  background-color: #111d5c;
}

.section-bg-1 {
  background-color: #f4f7fc;
}

.check-list-02 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.check-list-02 li {
  display: block;
  padding-left: 20px;
  position: relative;
  z-index: 0;
}
.check-list-02 li:after {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "fontawesome";
  content: "";
  color: var(--main-color-one);
}

.error {
  color: #dc3545 !important;
}

.check-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.check-list li {
  display: block;
  padding-left: 20px;
  position: relative;
  z-index: 0;
}
.check-list li:after {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "fontawesome";
  content: "";
  color: var(--main-color-one);
}

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
  clear: both;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
  float: left;
  width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%;
}


.comment-navigation .nav-previous > a,
.posts-navigation .nav-previous > a,
.post-navigation .nav-previous > a,
.comment-navigation .nav-next > a,
.posts-navigation .nav-next > a,
.post-navigation .nav-next > a {
  transition: 0.3s ease-in;
}

.comment-navigation .nav-previous:hover > a,
.posts-navigation .nav-previous:hover > a,
.post-navigation .nav-previous:hover > a,
.comment-navigation .nav-next:hover > a,
.posts-navigation .nav-next:hover > a,
.post-navigation .nav-next:hover > a {
  color: var(--main-color-one);
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
          clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Globals
--------------------------------------------------------------*/
.padding-left-0 {
  padding-left: 0;
}

.padding-right-0 {
  padding-left: 0;
}

.gray-bg {
  background-color: #f5f5f5;
}

.secondary-bg {
  background-color: var(--secondary-color);
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-35 {
  padding-top: 35px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-45 {
  padding-top: 45px;
}

.padding-top-55 {
  padding-top: 55px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-60 {
  padding-top: 60px;
}

.padding-top-65 {
  padding-top: 65px;
}

.padding-top-70 {
  padding-top: 70px;
}

.padding-top-75 {
  padding-top: 75px;
}

.padding-top-80 {
  padding-top: 80px;
}

.padding-top-85 {
  padding-top: 85px;
}

.padding-top-90 {
  padding-top: 90px;
}

.padding-top-95 {
  padding-top: 95px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-top-105 {
  padding-top: 105px;
}

.padding-top-110 {
  padding-top: 110px;
}

.padding-top-115 {
  padding-top: 115px;
}

.padding-top-120 {
  padding-top: 120px;
}

.padding-top-140 {
  padding-top: 140px;
}

.padding-top-150 {
  padding-top: 150px;
}

.padding-top-160 {
  padding-top: 160px;
}

.padding-top-190 {
  padding-top: 190px;
}

.padding-top-200 {
  padding-top: 200px;
}

.padding-top-210 {
  padding-top: 210px;
}

.padding-top-260 {
  padding-top: 260px;
}

.padding-top-310 {
  padding-top: 310px;
}

.padding-top-360 {
  padding-top: 360px;
}

.padding-top-390 {
  padding-top: 390px;
}

.padding-top-400 {
  padding-top: 400px;
}

.padding-top-600 {
  padding-top: 600px;
}

.padding-top-640 {
  padding-top: 640px;
}

.padding-top-720 {
  padding-top: 720px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-35 {
  padding-bottom: 35px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-45 {
  padding-bottom: 45px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-55 {
  padding-bottom: 55px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}

.padding-bottom-65 {
  padding-bottom: 65px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.padding-bottom-75 {
  padding-bottom: 75px;
}

.padding-bottom-80 {
  padding-bottom: 80px;
}

.padding-bottom-85 {
  padding-bottom: 85px;
}

.padding-bottom-90 {
  padding-bottom: 90px;
}

.padding-bottom-95 {
  padding-bottom: 95px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-bottom-110 {
  padding-bottom: 110px;
}

.padding-bottom-105 {
  padding-bottom: 105px;
}

.padding-bottom-115 {
  padding-bottom: 115px;
}

.padding-bottom-120 {
  padding-bottom: 120px;
}

.padding-bottom-130 {
  padding-bottom: 130px;
}

.padding-bottom-140 {
  padding-bottom: 140px;
}

.padding-bottom-135 {
  padding-bottom: 135px;
}

.padding-bottom-200 {
  padding-bottom: 200px;
}

.padding-bottom-280 {
  padding-bottom: 280px;
}

.padding-bottom-285 {
  padding-bottom: 285px;
}

.padding-bottom-150 {
  padding-bottom: 150px;
}

.padding-bottom-160 {
  padding-bottom: 160px;
}

.padding-bottom-165 {
  padding-bottom: 165px;
}

.padding-bottom-170 {
  padding-bottom: 170px;
}

.padding-bottom-180 {
  padding-bottom: 180px;
}

.padding-bottom-200 {
  padding-bottom: 200px;
}

.padding-bottom-215 {
  padding-bottom: 215px;
}

.padding-bottom-260 {
  padding-bottom: 260px;
}

.padding-bottom-300 {
  padding-bottom: 300px;
}

.padding-bottom-350 {
  padding-bottom: 350px;
}

.padding-bottom-460 {
  padding-bottom: 460px;
}

.margin-top-minus-90 {
  margin-top: -90px;
}

.margin-top-minus-100 {
  margin-top: -100px;
}

.margin-top-120 {
  margin-top: 120px;
}

.margin-top-150 {
  margin-top: 150px;
}

.margin-top-160 {
  margin-top: 160px;
}

.padding-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.padding-110 {
  padding-top: 110px;
  padding-bottom: 110px;
}

.padding-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.padding-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.padding-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.padding-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.padding-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.padding-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-55 {
  margin-top: 55px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-top-90 {
  margin-top: 90px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-55 {
  margin-bottom: 55px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-bottom-90 {
  margin-bottom: 90px;
}

.margin-bottom-120 {
  margin-bottom: 120px;
}

.margin-bottom-150 {
  margin-bottom: 150px;
}

.margin-top-100 {
  margin-bottom: 100px;
}

.margin-top-110 {
  margin-top: 110px;
}

.margin-top-115 {
  margin-top: 115px;
}

.margin-top-120 {
  margin-top: 120px;
}

.min-height-600 {
  min-height: 600px;
}

.overflow-hidden {
  overflow: hidden;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  float: left;
  clear: both;
  margin-right: 20px;
}

.alignright {
  float: right;
  clear: both;
  margin-left: 20px;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

.alignfull {
  margin: 1.5em 0;
  max-width: 100%;
}

.alignwide {
  max-width: 1100px;
}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
  display: block;
}

.updated:not(.published) {
  display: none;
}

.blog-pagination ul li {
  display: inline-block;
}

.blog-pagination ul li + li {
  margin: 0 5px;
}

.blog-pagination ul li {
  display: inline-block;
}

.blog-pagination ul li + li {
  margin: 0 5px;
}

.blog-pagination {
  display: block;
  width: 100%;
}

.blog-pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.blog-pagination ul li a,
.blog-pagination ul li span {
  display: block;
  padding: 3px 25px;
  border: 1px solid #e2e2e2;
  line-height: 40px;
  text-align: center;
  font-weight: 600;
  transition: 0.3s ease-in;
}

.blog-pagination ul li span.current,
.blog-pagination ul li a:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
  clear: both;
}

.wp-caption img[class*=wp-image-] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

/*----------------------------------------
    # Unit test
------------------------------------------*/
.wp-link-pages a {
  margin: 0 5px;
  transition: 0.3s ease-in;
}

.wp-link-pages {
  margin-bottom: 30px;
  margin-top: 25px;
}

.wp-link-pages span,
.wp-link-pages a {
  border: 1px solid #e2e2e2;
  padding: 5px 15px;
  display: inline-block;
}

.wp-link-pages .current,
.wp-link-pages a:hover {
  background-color: var(--main-color-one);
  color: #fff;
  border-color: var(--main-color-one);
}

.wp-link-pages span:first-child {
  margin-right: 5px;
}

dl,
ol,
ul {
  padding-left: 15px;
}

.post-password-form input {
  display: block;
  border: 1px solid #e2e2e2;
  height: 50px;
  border-radius: 3px;
  padding: 0 20px;
}

.post-password-form label {
  font-weight: 600;
  color: #333;
}

.post-password-form input[type=submit] {
  width: 100px;
  height: 50px;
  background-color: var(--main-color-one);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in;
}

.post-password-form input[type=submit]:hover {
  background-color: #121A2F;
}

.footer-widget .table td,
.footer-widget .table th {
  padding: 0.5rem !important;
}

/*--------------------------------------------------------------
# Gutenberg	Default Style
--------------------------------------------------------------*/
.single-post-details-item .entry-content > .alignwide {
  max-width: 1100px;
}

.single-post-details-item .entry-content > .alignfull {
  margin: 1.5em 0;
  max-width: 100%;
}

.wp-block-video video {
  max-width: 636px;
}

.wp-block-image img {
  display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
  width: 100%;
}

.wp-block-image.alignfull img {
  width: 100vw;
}

.wp-block-gallery:not(.components-placeholder) {
  margin: 1.5em auto;
}

.wp-block-cover-text p {
  padding: 1.5em 14px;
}

ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
  padding: 0 14px;
}

.wp-block-table {
  display: block;
  overflow-x: auto;
}

.wp-block-table table {
  border-collapse: collapse;
  width: 100%;
}

.wp-block-table td, .wp-block-table th {
  padding: 0.5em;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wp-block-quote.is-large {
  margin: 0 auto 16px;
}

.wp-block-pullquote > p:first-child {
  margin-top: 0;
}

.wp-block-separator {
  margin: 3em auto;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .wp-block-cover-text p {
    padding: 1.5em 0;
  }
}
wp-block-video video {
  max-width: 636px;
}

.wp-block-image img {
  display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
  width: 100%;
}

.wp-block-image.alignfull img {
  width: 100vw;
}

.wp-block-gallery:not(.components-placeholder) {
  margin: 1.5em auto;
}

.wp-block-cover-text p {
  padding: 1.5em 14px;
}

ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
  padding: 0 14px;
}

.wp-block-table {
  display: block;
  overflow-x: auto;
}

.wp-block-table table {
  border-collapse: collapse;
  width: 100%;
}

.wp-block-table td, .wp-block-table th {
  padding: 0.5em;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wp-block-quote.is-large {
  margin: 0 auto 16px;
}

.wp-block-pullquote > p:first-child {
  margin-top: 0;
}

.wp-block-quote:not(.is-large):not(.is-style-large) {
  border-left: 4px solid #000;
  padding-left: 1em;
}

.wp-block-separator {
  margin: 3em auto;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .wp-block-cover-text p {
    padding: 1.5em 0;
  }
}
.wp-block-pullquote {
  border-top: 4px solid #555d66;
  border-bottom: 4px solid #555d66;
  color: #40464d;
}

/*--------------------------------------------------------------
## Block Color Palette Colors
--------------------------------------------------------------*/
.has-strong-blue-color {
  color: #0073aa;
}

.has-strong-blue-background-color {
  background-color: #0073aa;
}

.has-lighter-blue-color {
  color: #229fd8;
}

.has-lighter-blue-background-color {
  background-color: #229fd8;
}

.has-very-light-gray-color {
  color: #eee;
}

.has-very-light-gray-background-color {
  background-color: #eee;
}

.has-very-dark-gray-color {
  color: #444;
}

.has-very-dark-gray-background-color {
  background-color: #444;
}

/*---------------------
    ## Breadcumb 
----------------------*/
.breadcrumb-area {
  background-image: linear-gradient(50deg, #5e2ced 0%, #9749f8 100%);
  position: relative;
  z-index: 0;
  background-size: cover;
  background-position: center;
  padding: 228px 0 155px 0;
}
.breadcrumb-area.extra {
  padding-bottom: 138px;
}
.breadcrumb-area .shape {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(45deg);
  width: 430px;
  height: 445px;
  border-radius: 50px;
  background-image: linear-gradient(180deg, rgba(81, 185, 245, 0.38) 0%, rgba(82, 185, 245, 0.01) 100%);
  z-index: -2;
  animation: rotate10deg 15s infinite;
}
.breadcrumb-area .page-title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 15px;
  width: 340px;
}
.breadcrumb-area .page-title.style-01 {
  width: initial;
  font-size: 60px;
  font-weight: 600;
  line-height: 70px;
}
.breadcrumb-area p {
  font-size: 14px;
  line-height: 26px;
  max-width: 600px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
}
.breadcrumb-area .icon {
  display: inline-block;
  padding: 5px 30px 5px 5px;
  background-color: #683dd7;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
}
.breadcrumb-area .icon i {
  margin-right: 15px;
  color: #fff;
  background-color: #2abef5;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  line-height: 55px;
  text-align: center;
  font-size: 35px;
  animation: lightup 5s linear infinite both;
}
.breadcrumb-area .icon p {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
}
@keyframes lightup {
  0% {
    color: #ffff33;
  }
  50% {
    color: transparent;
  }
  100% {
    color: #ffff33;
  }
}
.breadcrumb-area .page-list {
  margin: 0;
  padding: 0;
}
.breadcrumb-area .page-list li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.breadcrumb-area .page-list li:first-child {
  padding-left: 0;
}
.breadcrumb-area .page-list li:first-child a {
  color: rgba(255, 255, 255, 0.7);
}
.breadcrumb-area .page-list li:first-child:after {
  display: none;
}
.breadcrumb-area .page-list li:after {
  position: absolute;
  left: 7px;
  top: 0;
  font-weight: 700;
  content: "/";
  font-family: "fontawesome";
}
.breadcrumb-area .page-list li a {
  color: var(--main-color-one);
  transition: all 0.3s ease-in;
}
.breadcrumb-area .page-list li a:hover {
  color: #fff;
}

.notify-form {
  margin-top: 30px;
  display: flex;
}
.notify-form .form-group {
  width: 100%;
  flex: 1;
  margin-bottom: 0;
}
.notify-form .form-group .form-control {
  flex: 1;
  width: 100%;
  border-radius: 0;
  width: 100%;
  height: 55px;
  background-color: #fff;
  color: #5f5f5f;
}
.notify-form .form-group .form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  color: rgba(95, 95, 95, 0.6);
}
.notify-form .form-group .form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(95, 95, 95, 0.6);
}
.notify-form .form-group .form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(95, 95, 95, 0.6);
}
.notify-form .form-group .form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(95, 95, 95, 0.6);
}
.notify-form .submit-btn {
  border: none;
  background-color: #2abef5;
  padding: 7px 30px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  transition: all 0.5s;
  cursor: pointer;
}
.notify-form .submit-btn:hover {
  background-color: #fff;
  color: var(--main-color-one);
}

/*--------------------------------------------------------------
	## Comments
--------------------------------------------------------------*/
.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

.comments-area {
  margin-top: 30px;
}

.comments-area .comments-title {
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 38px;
  text-transform: uppercase;
}

.comments-area .comment-list .comment-respond {
  margin-bottom: 30px;
}

.comments-area .comment-list {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  margin-bottom: 20px;
}

.comments-area .comment-list li {
  margin-bottom: 30px;
}

.comments-area .comment-list li:last-child {
  margin-bottom: 0;
}

.comments-area .comment-list li ul.children {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}

.comments-area .comment-list li ul {
  list-style: none;
}

.comments-area .comment-list li ul.children li {
  margin-left: 50px;
  list-style-type: none;
  list-style: none;
}

.comments-area .comment-list li .single-comment-wrap {
  display: flex;
  align-self: flex-start;
}

.comments-area .comment-list li .single-comment-wrap .thumb {
  margin-right: 20px;
  min-width: 80px;
  border-radius: 50%;
}

.comments-area .comment-list li .single-comment-wrap .thumb img {
  margin-bottom: 10px;
  border-radius: 50%;
}

.comments-area .comment-list li .single-comment-wrap .date,
.blog-details-page-content-area .comments-area .comment-list li .single-comment-wrap .time {
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.comments-area .comment-list li .single-comment-wrap .content {
  flex: 1;
  position: relative;
}

.comments-area .comment-list .has-children {
  padding-left: 80px;
}

.comments-area .comment-list li .single-comment-wrap .content .title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: var(--heading-font);
}

.comments-area .comment-list li .single-comment-wrap .content .comment-content {
  max-width: 620px;
}

.comments-area .comment-list li .single-comment-wrap .content p {
  font-size: 14px;
  line-height: 24px;
  word-break: break-word;
}

.comments-area .comment-list li .single-comment-wrap .content p:last-child {
  margin-bottom: 0;
}

.comments-area .comment-list li .single-comment-wrap .content .reply {
  color: var(--secondary-color);
}

.comments-area .comment-list li .single-comment-wrap .content .reply:hover {
  color: var(--main-color-one);
}

.comments-area .reply-title,
.comments-area .comment-reply-title {
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
}

.comments-area .form-submit {
  margin-bottom: 0;
}

.comment-form .form-group.textarea .form-control {
  min-height: 160px;
  resize: none;
  line-height: 26px;
}

.comment-form .form-group.textarea .form-control:focus {
  outline: none;
  box-shadow: none;
}

.comment-form .form-group .form-control {
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  border: 1px solid #f2f2f3;
  background-color: #f2f2f3;
}

.comment-form .submit-btn {
  height: 50px;
  width: auto;
  border-radius: 5px;
  font-weight: 400;
  background-color: var(--main-color-one);
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in;
  color: #fff;
}

.comment-form .submit-btn:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.comment-respond {
  /*margin-top: 20px;*/
}

.comments-area .comment-list .children li:last-child {
  margin-bottom: 30px;
}

.comments-area .comment-list li ul.children {
  margin-top: 30px;
}

.comment-form-cookies-consent input[type=checkbox] {
  margin-right: 5px;
}

/*--------------------------------------------------------------
	## WIdgets
--------------------------------------------------------------*/
.widget {
  margin-bottom: 30px;
}

.widget select {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
}

.widget .widget-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 25px;
  text-transform: capitalize;
  position: relative;
  font-family: var(--heading-font);
}
.widget .widget-title.style-01 {
  font-size: 18px;
}

.widget.widget_calendar {
  padding-right: 24px;
  padding-left: 24px;
}

.widget:last-child {
  margin-bottom: 0px;
}

.widget.widget_nav_menu ul li.menu-item-has-children:after {
  display: none;
}

.widget.footer-widget.widget_subscribe {
  display: flex;
}
.widget.footer-widget.widget_subscribe .title {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

.widget.footer-widget .subscribe-form {
  display: flex;
  margin-left: 50px;
}
.widget.footer-widget .subscribe-form .form-group {
  display: flex;
  margin-bottom: 0;
}
.widget.footer-widget .subscribe-form .form-group .form-control {
  width: 100%;
  height: 55px;
  border-radius: 50px;
  background-color: #fff;
  color: #5f5f5f;
}
.widget.footer-widget .subscribe-form .form-group .form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  color: rgba(95, 95, 95, 0.6);
}
.widget.footer-widget .subscribe-form .form-group .form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(95, 95, 95, 0.6);
}
.widget.footer-widget .subscribe-form .form-group .form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(95, 95, 95, 0.6);
}
.widget.footer-widget .subscribe-form .form-group .form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(95, 95, 95, 0.6);
}
.widget.footer-widget .subscribe-form .submit-btn {
  border: none;
  border-radius: 50px;
  background-color: var(--secondary-color);
  width: 85px;
  height: 55px;
  font-size: 25px;
  color: #fff;
  transition: all 0.5s;
  margin-left: 15px;
  cursor: pointer;
}
.widget.footer-widget .subscribe-form .submit-btn:hover {
  background-color: #fff;
  color: var(--main-color-one);
}
.widget.footer-widget .subscribe-form .submit-btn.style-01 {
  background-color: #007bcf;
}

.widget.footer-widget {
  background-color: transparent;
  padding: 0;
  margin-bottom: 30px;
}

.widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget ul li ul {
  margin-left: 20px;
}

.widget ul li {
  display: block;
  margin: 8px 0;
  -ms-word-wrap: break-word;
  word-break: break-word;
}

.widget ul li:first-child {
  margin-top: 0px;
}

.widget ul li:last-child {
  margin-bottom: 0px;
}

.widget ul li a {
  color: inherit;
  transition: all 0.3s ease-in;
}

.widget ul li a:hover {
  color: var(--main-color-one);
}

.widget_search .search-form {
  position: relative;
}

.widget_search .search-form .form-group {
  margin-bottom: 0;
}

.widget_search .search-form .form-group .form-control {
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 70px 0 15px;
}

.widget_search .search-form .submit-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 50px;
  line-height: 50px;
  padding: 0;
  font-size: 18px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--secondary-color);
  color: #fff;
  transition: 0.3s ease-in;
  border: none;
  cursor: pointer;
}

.widget_search .search-form .submit-btn:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.widget_author_meta {
  text-align: center;
}

.widget_author_meta .thumb {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.widget_author_meta .thumb img {
  border-radius: 50%;
}

.widget_author_meta .content .name {
  font-size: 21px;
  font-weight: 700;
}

.widget_author_meta .content p {
  font-size: 16px;
  line-height: 26px;
}

.widget_author_meta .content ul {
  margin-top: 25px;
}

.widget_author_meta .content ul li {
  display: inline-block;
  margin: 0 5px;
}

.widget_author_meta .content ul li a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transition: all 0.3s ease-in;
}

.widget_author_meta .content ul li a:hover {
  background-color: var(--main-color-one);
  color: #fff;
}

.widget_tag_cloud .tagcloud a {
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 5px 5px 5px 0;
  color: inherit;
  transition: all 0.3s ease-in;
  border-radius: 5px;
  font-size: 14px !important;
}

.widget_tag_cloud .tagcloud a:hover {
  background-color: var(--main-color-one);
  color: #fff;
}

.widget ul li ul.sub-menu {
  position: initial;
}

.widget ul li ul.sub-menu {
  visibility: visible;
  opacity: 1;
  background-color: transparent;
  border-top: none;
  box-shadow: none;
  margin-top: 10px;
}

.widget ul li ul.sub-menu li a {
  padding: 0 0 0px 10px;
}

.widget ul li ul.sub-menu li a:hover {
  background-color: transparent;
}

.widget.widget_nav_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget_nav_menu .footer-pera {
  color: #fff;
}

.widget.widget_nav_menu ul li {
  margin: 10px 0;
}

.widget.widget_archive ul li:first-child {
  padding-top: 0;
}

.widget.widget_archive ul li {
  padding: 10px 0;
  border-bottom: 1px solid #dedee2;
}

.widget.widget_archive ul li:last-child {
  border-bottom: none;
}

.widget.widget_archive ul li a span {
  float: right;
}

.widget.widget_nav_menu ul li:first-child {
  margin-top: 0;
}

.widget.widget_nav_menu ul li:last-child {
  margin-bottom: 0;
}

.widget.widget_nav_menu ul li a {
  border: none;
}

.widget.widget_nav_menu ul li > ul {
  margin-left: 15px;
}

.widget.footer-widget.widget_calendar table {
  margin-bottom: 0;
}

.widget.footer-widget.widget_calendar caption {
  padding-bottom: 0;
}

.widget.footer-widget .widget-title {
  color: rgba(255, 255, 255, 0.9);
}

.widget.footer-widget p,
.footer-widget.widget_tag_cloud .tagcloud a,
.widget.footer-widget.widget_calendar caption,
.widget.footer-widget.widget_calendar th,
.widget.footer-widget.widget_calendar td {
  color: #9191aa;
  font-size: 14px;
}

.widget.footer-widget ul li a,
.widget.footer-widget ul li {
  color: #9191aa;
  font-size: 14px;
}

.widget.footer-widget.style-02 ul li a {
  color: #a9b2e2;
}

.widget.footer-widget.style-03 ul li a {
  color: #727777;
}

.widget.footer-widget ul li a:hover {
  color: var(--secondary-color);
}

.widget.footer-widget.style-01 ul li a:hover {
  color: var(--secondary-color);
}

.widget.footer-widget.style-02 ul li a:hover {
  color: rgba(169, 178, 226, 0.8);
}

.widget.footer-widget.style-03 ul li a:hover {
  color: #500ade;
}

.widget.widget_subscribe {
  background-color: #131627;
  padding: 45px 40px;
  border-radius: 20px;
}

.widget .social_share {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget .social_share.style-01 li {
  color: #7d7d93;
}
.widget .social_share.style-01 li i {
  font-size: 15px;
}
.widget .social_share.style-01 li a:hover {
  color: var(--secondary-color);
}
.widget .social_share.style-02 li {
  color: #a9b2e2;
}
.widget .social_share.style-03 li {
  color: #7d7d93;
}
.widget .social_share.style-03 li i {
  font-size: 15px;
}
.widget .social_share.style-03 li a:hover {
  color: #500ade;
}
.widget .social_share li {
  margin: 0;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  color: #fff;
}
.widget .social_share li + li {
  margin-left: 10px;
}
.widget .social_share li i {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
}
.widget .social_share li a:hover {
  color: #fff;
}

.widget .recent_post_item {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget .recent_post_item li {
  display: block;
}
.widget .recent_post_item li + li {
  margin-top: 20px;
}
.widget .recent_post_item li.single-recent-post-item {
  display: flex;
  align-self: flex-start;
}
.widget .recent_post_item li.single-recent-post-item .thumb {
  width: 110px;
  height: 70px;
  border-radius: 10px;
  margin-right: 15px;
}
.widget .recent_post_item li.single-recent-post-item .thumb img {
  border-radius: 10px;
}
.widget .recent_post_item li.single-recent-post-item .content {
  flex: 1;
}
.widget .recent_post_item li.single-recent-post-item .content .title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  max-width: 170px;
}
.widget .recent_post_item li.single-recent-post-item .content .time {
  font-size: 12px;
  font-weight: 500;
}
.widget .recent_post_item li.single-recent-post-item .content .time i {
  color: var(--secondary-color);
  padding-right: 5px;
}

.contact_info_list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.contact_info_list li {
  display: block;
}
.contact_info_list li.single-info-item {
  position: relative;
}
.contact_info_list li.single-info-item .icon {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--main-color-one);
}
.contact_info_list li.single-info-item .details {
  margin-left: 30px;
}

.about_us_widget .footer-logo {
  margin-bottom: 20px;
  display: block;
}

.copyright-area {
  padding: 20px 0;
  text-align: center;
  color: #898ba7;
  font-size: 14px;
}

.widget-testimonial .icon {
  font-size: 70px;
  line-height: 60px;
  color: var(--main-color-one);
  margin-bottom: 16px;
}
.widget-testimonial p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}
.widget-testimonial .author-meta {
  display: flex;
  align-self: flex-start;
  margin-top: 24px;
}
.widget-testimonial .author-meta .thumb {
  margin-right: 20px;
}
.widget-testimonial .author-meta .thumb img {
  max-width: 80px;
  height: 80px;
  border-radius: 50%;
}
.widget-testimonial .author-meta .content {
  flex: 1;
  margin-top: 15px;
}
.widget-testimonial .author-meta .content .name {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}
.widget-testimonial .author-meta .content .designation {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.practice-sidebar .widget.widget_categories,
.case-sidebar .widget.widget_categories {
  padding: 0px;
  background-color: transparent;
}
.practice-sidebar .widget.widget_categories .widget-title,
.case-sidebar .widget.widget_categories .widget-title {
  background-color: #e1e1e1;
  padding: 15px 30px;
  margin-bottom: 0px;
}
.practice-sidebar .widget.widget_categories ul li,
.case-sidebar .widget.widget_categories ul li {
  position: relative;
  z-index: 0;
  background-color: #f5f5f5;
  padding: 13px 30px;
  margin-bottom: 0;
}
.practice-sidebar .widget.widget_categories ul li + li,
.case-sidebar .widget.widget_categories ul li + li {
  margin-top: 2px;
}
.practice-sidebar .widget.widget_categories ul li:after,
.case-sidebar .widget.widget_categories ul li:after {
  position: absolute;
  left: 15px;
  top: 12px;
  content: "";
  font-family: "fontawesome";
  font-weight: 900;
}

/*----------------------------------------------
    # Nav bar 
----------------------------------------------*/
.navbar.navbar-area.white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.50)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar.navbar-area.nav-style-01 .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.50)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar.navbar-area .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.50)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.mobile-logo {
  display: none;
}

@media only screen and (max-width: 991px) {

 
  .mobile-logo {
    display: block;
  }

  .desktop-logo {
    display: none !important;
  }
}
.navbar-area {
  padding: 0;
}
.navbar-area.nav-absolute {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0px;
  z-index: 1;
}
.navbar-area.nav-style-02 {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100px;
  z-index: 2;
}
.navbar-area.nav-style-02.has-topbar {
  top: 50px;
}
.navbar-area.nav-style-02.has-topbar-02 {
  top: 122px;
}
.navbar-area.nav-style-02.has-topbar-03 {
  top: 70px;
}
.navbar-area.nav-style-02 .nav-container {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 0 15px;
}
.navbar-area.nav-style-02 .nav-container.social-nav .navbar-collapse .navbar-nav {
  text-align: center;
}
.navbar-area.nav-style-02 .nav-container.social-nav .navbar-collapse .navbar-nav li.current-menu-item a {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.social-nav .navbar-collapse .navbar-nav li.current-menu-item:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.social-nav .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-02 .nav-container.social-nav .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.social-nav .navbar-collapse .navbar-nav li.menu-item-has-children.style-01:before {
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.social-nav .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.social-nav .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.social-nav .navbar-collapse .navbar-nav li:hover a {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.audio-nav .navbar-collapse .navbar-nav {
  text-align: right;
}
.navbar-area.nav-style-02 .nav-container.audio-nav .navbar-collapse .navbar-nav li.current-menu-item a {
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.audio-nav .navbar-collapse .navbar-nav li.current-menu-item:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.audio-nav .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-02 .nav-container.audio-nav .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.audio-nav .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.audio-nav .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-area.nav-style-02 .nav-container.audio-nav .navbar-collapse .navbar-nav li:hover a {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-area.nav-style-02 .nav-container.medical-nav .navbar-collapse .navbar-nav {
  text-align: center;
}
.navbar-area.nav-style-02 .nav-container.medical-nav .navbar-collapse .navbar-nav li.current-menu-item a {
  color: #352d66;
}
.navbar-area.nav-style-02 .nav-container.medical-nav .navbar-collapse .navbar-nav li.current-menu-item:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.medical-nav .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-02 .nav-container.medical-nav .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: #352d66;
}
.navbar-area.nav-style-02 .nav-container.medical-nav .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: #352d66;
}
.navbar-area.nav-style-02 .nav-container.medical-nav .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.medical-nav .navbar-collapse .navbar-nav li:hover a {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.gym-nav .navbar-collapse .navbar-nav {
  text-align: center;
}
.navbar-area.nav-style-02 .nav-container.gym-nav .navbar-collapse .navbar-nav li.current-menu-item a {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.gym-nav .navbar-collapse .navbar-nav li.current-menu-item:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.gym-nav .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-02 .nav-container.gym-nav .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.gym-nav .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.gym-nav .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.gym-nav .navbar-collapse .navbar-nav li:hover a {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.startup-nav .navbar-collapse .navbar-nav {
  text-align: center;
}
.navbar-area.nav-style-02 .nav-container.startup-nav .navbar-collapse .navbar-nav li.current-menu-item a {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.startup-nav .navbar-collapse .navbar-nav li.current-menu-item:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.startup-nav .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-02 .nav-container.startup-nav .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.startup-nav .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.startup-nav .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.startup-nav .navbar-collapse .navbar-nav li:hover a {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.startup-nav .nav-right-content  .boxed-btn {
  border-radius: 5px;
  margin-left: 15px;
}
.navbar-area.nav-style-02 .nav-container.utility-nav .navbar-collapse .navbar-nav {
  text-align: center;
}
.navbar-area.nav-style-02 .nav-container.utility-nav .navbar-collapse .navbar-nav li.current-menu-item a {
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.utility-nav .navbar-collapse .navbar-nav li.current-menu-item:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.utility-nav .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-02 .nav-container.utility-nav .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.utility-nav .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.utility-nav .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.utility-nav .navbar-collapse .navbar-nav li:hover a {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.utility-nav .nav-right-content .boxed-btn {
  border-radius: 5px;
  margin-left: 15px;
}
.navbar-area.nav-style-02 .nav-container.sass-nav .navbar-collapse .navbar-nav {
  text-align: right;
}
.navbar-area.nav-style-02 .nav-container.sass-nav .navbar-collapse .navbar-nav li.current-menu-item a {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.sass-nav .navbar-collapse .navbar-nav li.current-menu-item:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.sass-nav .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-02 .nav-container.sass-nav .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.sass-nav .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: #283377;
}
.navbar-area.nav-style-02 .nav-container.sass-nav .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.sass-nav .navbar-collapse .navbar-nav li:hover a {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.learn-nav .navbar-collapse .navbar-nav {
  text-align: right;
}
.navbar-area.nav-style-02 .nav-container.learn-nav .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-02 .nav-container.learn-nav .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.learn-nav .navbar-collapse .navbar-nav li.current-menu-item a {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.learn-nav .navbar-collapse .navbar-nav li.current-menu-item:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.learn-nav .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.learn-nav .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.learn-nav .navbar-collapse .navbar-nav li:hover a {
  color: var(--secondary-color);
}
.navbar-area.nav-style-02 .nav-container.inner-nav .navbar-collapse .navbar-nav {
  text-align: right;
}
.navbar-area.nav-style-02 .nav-container.inner-nav .navbar-collapse .navbar-nav li.current-menu-item a {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.inner-nav .navbar-collapse .navbar-nav li.current-menu-item:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.inner-nav .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-02 .nav-container.inner-nav .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.inner-nav .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container.inner-nav .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container.inner-nav .navbar-collapse .navbar-nav li:hover a {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container .logo-wrapper {
  max-width: 200px;
}
.navbar-area.nav-style-02 .nav-container .nav-right-content {
  display: flex;
  align-items: start;
}
.navbar-area.nav-style-02 .nav-container .nav-right-content ul li {
  color: var(--heading-color);
}
.navbar-area.nav-style-02 .nav-container .nav-right-content ul li + li {
  margin-left: 5px;
}
.navbar-area.nav-style-02 .nav-container .nav-right-content ul li a {
  color: var(--heading-color);
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav {
  text-align: right;
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li.current-menu-item a {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li.current-menu-item:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: var(--heading-color);
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
  background-color: #fff;
  color: var(--paragraph-color);
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu li:hover:before {
  color: #fff;
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: var(--heading-color);
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li:hover a {
  color: var(--main-color-one);
}
.navbar-area.nav-style-01 {
  background-color: var(--main-color-two);
}
.navbar-area.nav-style-01 .nav-container {
  background-color: var(--main-color-two);
  box-shadow: none;
  border-radius: 0;
  padding: 0 15px;
}
.navbar-area.nav-style-01 .nav-container .nav-right-content ul li {
  color: var(--heading-color);
}
.navbar-area.nav-style-01 .nav-container .nav-right-content ul li a {
  color: var(--heading-color);
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav {
  text-align: right;
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li {
  line-height: 110px;
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.current-menu-item a {
  color: var(--main-color-one);
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.current-menu-item.menu-item-has-children:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: initial;
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  color: var(--heading-color);
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li + li {
  border-top: 1px solid #e2e2e2;
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
  background-color: #fff;
  color: var(--paragraph-color);
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu li:hover:before {
  color: #fff;
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
  color: var(--heading-color);
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
  color: var(--main-color-one);
}
.navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li:hover a {
  color: var(--main-color-one);
}
.navbar-area .nav-container {
  background-color: #fff;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.2);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 30px;
}
.navbar-area .nav-container .nav-right-content .btn-wrapper {
  
}
.navbar-area .nav-container .nav-right-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 15px;
}
.navbar-area .nav-container .nav-right-content ul li {
  display: inline-block;
  margin: 0 15px;
  color: #6a7795;
}
.navbar-area .nav-container .nav-right-content ul li:last-child {
  margin-right: 0;
}
.navbar-area .nav-container .nav-right-content ul li a {
  color: #6a7795;
  transition: all 0.3s ease-in;
}
.navbar-area .nav-container .nav-right-content ul li a:hover {
  color: var(--secondary-color);
}
.navbar-area .nav-container .nav-right-content ul li.search {
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.navbar-area .nav-container .nav-right-content ul li.search:hover {
  color: var(--main-color-one);
}
.navbar-area .nav-container .nav-right-content ul li.nav-btn .btn-boxed {
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 5px;
  background-color: var(--main-color-one);
}
.navbar-area .nav-container .nav-right-content ul li.nav-btn .btn-boxed:hover {
  background-color: var(--secondary-color);
}
.navbar-area .nav-container .navbar-brand .site-title {
  font-weight: 700;
  font-size: 30px;
  font-family: var(--heading-font);
  line-height: 90px;
  color: var(--heading-color);
}
.navbar-area .nav-container .navbar-collapse .navbar-nav {
  display: block;
  width: 100%;
  text-align: right;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li {
  display: inline-block;
  font-size: 16px;
  font-family: var(--body-font);
  text-transform: capitalize;
  font-weight: 600;
  line-height: 70px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
  margin-left: 40px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.current-menu-item a {
  color: var(--main-color-one);
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children {
  position: relative;
  z-index: 0;
  padding-right: 15px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  position: absolute;
  right: 0;
  top: 50%;
  content: "";
  font-weight: 700;
  font-family: "fontawesome";
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
  transition: all 0.3s ease-in;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
  position: absolute;
  text-align: left;
  min-width: 220px;
  margin: 0;
  padding: 0;
  list-style: none;
  left: 0;
  top: 100%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 9;
  border-bottom: 4px solid var(--main-color-one);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
  display: block;
  margin-left: 0;
  line-height: 24px;
  font-size: 14px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li + li {
  border-top: 1px solid #e2e2e2;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
  display: block;
  padding: 12px 30px;
  background-color: #fff;
  white-space: nowrap;
  color: var(--paragraph-color);
  transition: all 0.3s ease-in;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children {
  position: relative;
  z-index: 0;
  padding-right: 0px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children > .sub-menu {
  left: 100%;
  top: 0px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children > .sub-menu .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu li:hover:before {
  color: #fff;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li a {
  transition: all 0.3s ease-in;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li a:hover {
  color: var(--main-color-one);
}

@media only screen and (max-width: 991px) {
  .navbar-area.nav-style-02 .nav-container {
    padding: 37px 0px;
  }
  .navbar-area.nav-style-02 .nav-container .navbar-toggler {
    background-color: #fff;
  }
  .navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav {
    background-color: var(--main-color-two);
    padding: 0 20px;
  }
  .navbar-area.nav-style-02 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8);
  }
  .navbar-area.nav-style-01 .nav-container {
    padding: 20px 0px;
  }
  .navbar-area.nav-style-01 .nav-container .responsive-mobile-menu .navbar-toggler {
    background-image: none;
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav {
    text-align: left;
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li {
    line-height: 30px;
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.current-menu-item a {
    color: var(--main-color-one);
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.current-menu-item.menu-item-has-children:before {
    color: var(--main-color-one);
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children {
    z-index: initial;
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
    color: rgba(255, 255, 255, 0.8);
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li + li {
    border-top: 1px solid #e2e2e2;
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
    background-color: transparent;
    color: var(--paragraph-color);
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover {
    background-color: var(--main-color-one);
    color: #fff;
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu li:hover:before {
    color: #656565;
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li a {
    transition: all 0.3s ease-in;
    color: rgba(255, 255, 255, 0.8);
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before {
    color: var(--main-color-one);
  }
  .navbar-area.nav-style-01 .nav-container .navbar-collapse .navbar-nav li:hover a {
    color: var(--main-color-one);
  }
  .navbar-area .nav-container {
    position: relative;
    z-index: 0;
    min-height: 80px;
  }
  .navbar-area .nav-container .responsive-mobile-menu {
    display: block;
    position: relative;
    width: 100%;
  }
  .navbar-area .nav-container .responsive-mobile-menu .navbar-toggler {
    position: absolute;
    right: 0;
    top: 50%;
    /* IE 9 */
    /* Chrome, Safari, Opera */
    transform: translateY(-50%);
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
  .navbar-area .nav-container .navbar-brand {
    display: block;
  }
  .navbar-area .nav-container .navbar-brand .navbar-toggler {
    position: absolute;
    right: 10px;
    border: 1px solid #e2e2e2;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    display: block;
    text-align: left;
    line-height: 30px;
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li:last-child {
    border-bottom: none;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
    margin-left: 0;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
    top: 25px;
    right: 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover {
    padding-bottom: 0;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover > .sub-menu {
    visibility: visible;
    height: auto;
    opacity: 1;
    background-color: transparent;
    border-bottom: none;
    padding-top: 10px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    position: initial;
    display: block;
    width: 100%;
    border-top: none;
    box-shadow: none;
    margin-left: 0;
    padding-bottom: 0;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    max-height: 250px;
    overflow-y: scroll;
    transition: height 500ms;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .sub-menu .menu-item-has-children:before {
    content: "";
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
    padding: 0;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li.menu-item-has-children:hover:before {
    color: #fff;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li + li {
    border-top: none;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
}
@media only screen and (max-width: 575px) {
  .navbar-area .nav-container {
    margin: 0 15px;
  }
}
/*----------------------------
    Info Bar
----------------------------*/
.info-bar-bottom {
  background-color: transparent;
}
.info-bar-bottom.style-01 {
  position: absolute;
  width: 100%;
  z-index: 2;
  padding: 25px 0;
}

.info-bar-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.info-items li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #fff;
  transition: all 0.3s ease-in;
}
.info-items li + li {
  margin-left: 20px;
}
.info-items li:hover {
  color: var(--secondary-color);
}
.info-items li i {
  margin-right: 3px;
  color: var(--secondary-color);
}

.info-items-02 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.info-items-02 li {
  display: flex;
  align-items: center;
}
.info-items-02 li .title {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  margin-top: 15px;
  margin-right: 15px;
}
.info-items-02 li .number {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.info-items-02 li .number span {
  color: var(--secondary-color);
}

.info-items-icon {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.info-items-icon li {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  transition: all 0.3s ease-in;
}
.info-items-icon li + li {
  margin-left: 20px;
}
.info-items-icon li:hover {
  color: var(--secondary-color);
}

/*----------------------------
    Top Bar
----------------------------*/
.topbar-area {
  background-color: var(--main-color-two);
}

.topbar-inner {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.topbar-inner div .boxed-btn {
  padding: 15px 30px;
  background-color: var(--main-color-one);
  display: inline-block;
  color: #fff;
  transition: all 0.3s ease-in;
}
.topbar-inner div .boxed-btn:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

/*--------------------------------------------------------------
	## Header
--------------------------------------------------------------*/
.header-bottom-img {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 665px;
}
.header-bottom-img.m-top {
  margin-top: -350px;
  position: relative;
  z-index: 0;
}

.header-style-04.has-top-bar .navbar-area {
  top: 75px;
}
.header-style-04.has-top-bar .header-area {
  padding-top: 352px;
  padding-bottom: 300px;
}

.header-style-03 .header-area {
  padding-top: 295px;
}

.header-area-wrapper,
.header-style-02,
.header-style-03 {
  position: relative;
  z-index: 0;
}

.header-style-02 .header-area {
  padding-top: 295px;
  padding-bottom: 290px;
}

.header-slider-one .owl-item.active .title {
  animation: slideInLeft 2s 0s both;
}
.header-slider-one .owl-item.active p {
  animation: slideInLeft 2s 1s both;
}
.header-slider-one .owl-item.active span {
  animation: slideInRight 2s 1s both;
}
.header-slider-one .owl-item.active .author-sign {
  animation: flipInX 2s 1s both;
}
.header-slider-one .owl-item.active .btn-wrapper {
  animation: flipInX 2s 2s both;
}
.header-slider-one:hover .owl-nav div {
  visibility: visible;
  opacity: 1;
}
.header-slider-one .owl-nav div {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease-in;
  visibility: hidden;
  opacity: 0;
}
.header-slider-one .owl-nav div:hover {
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.header-slider-one .owl-nav div.owl-next {
  left: auto;
  right: 20px;
}

.header-area {
  padding: 290px 0 225px;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.header-area.style-01 {
  padding: 205px 0 255px;
}
.header-area.style-02 {
  padding: 205px 0 255px;
}
.header-area.style-03 {
  padding: 205px 0 290px;
}
.header-area.header-social {
  padding: 290px 0 445px;
}
.header-area.header-social .header-inner .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 500;
  color: #fff;
}
.header-area.header-social .header-inner .title.style-01 {
  max-width: 450px;
}
.header-area.header-social .social-bg-img {
  height: 50px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 20%;
  top: 15%;
  z-index: -1;
  animation: popUp 2s;
}
@keyframes popUp {
  0% {
    right: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(50%, 50%);
  }
  100% {
    opacity: 1;
    right: 20%;
    top: 15%;
  }
}
.header-area.header-social .social-bg-img-02 {
  height: 50px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 30%;
  top: 10%;
  z-index: -1;
  animation: popUpTwo 2s;
}
@keyframes popUpTwo {
  0% {
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(50%, 50%);
  }
  100% {
    opacity: 1;
    left: 30%;
    top: 10%;
  }
}
.header-area.header-social .social-bg-img-03 {
  height: 50px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 55%;
  z-index: 0;
  animation: popUpThree 2s;
}
@keyframes popUpThree {
  0% {
    right: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(50%, 50%);
  }
  100% {
    opacity: 1;
    right: 10%;
    top: 55%;
  }
}
.header-area.header-social .social-bg-img-04 {
  height: 50px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 10%;
  top: 56%;
  z-index: 0;
  animation: popUpFour 2s;
}
@keyframes popUpFour {
  0% {
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(50%, 50%);
  }
  100% {
    opacity: 1;
    left: 10%;
    top: 56%;
  }
}
.header-area.audio-header {
  background-color: #100718;
  padding: 290px 0 290px;
}
.header-area.audio-header .sass-bg-img {
  height: 730px;
  width: 830px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 7%;
  top: 22%;
  z-index: 0;
}
.header-area.header-utility {
  background-repeat: no-repeat;
  padding: 290px 0 515px;
}
.header-area.header-utility .utility-bg-img {
  height: 730px;
  width: 880px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 6%;
  top: 20%;
}
.header-area.header-utility .header-inner .title {
  font-size: 60px;
  color: #fff;
  line-height: 65px;
}
.header-area.header-utility .header-inner .title.style-01 {
  line-height: 70px;
  color: #16215c;
  font-weight: 600;
}
.header-area.header-utility .header-inner .learn-para {
  color: #727777;
  margin-bottom: 30px;
}
.header-area.header-utility .header-inner .apps-download {
  display: flex;
  margin-top: 40px;
}
.header-area.header-utility .header-inner .apps-download .download-img {
  margin-right: 15px;
}
.header-area.header-learning {
  padding: 290px 0px 0px;
  overflow: initial;
}
.header-area.header-learning .utility-bg-img {
  height: 730px;
  width: 830px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0%;
  top: 15%;
}
.header-area.header-learning .header-inner .title {
  font-size: 60px;
  color: #fff;
  line-height: 65px;
}
.header-area.header-learning .header-inner .title.style-01 {
  line-height: 70px;
  color: #16215c;
  font-weight: 600;
  max-width: 470px;
}
.header-area.header-learning .header-inner .learn-para {
  color: #727777;
  margin-bottom: 30px;
  max-width: 470px;
}
.header-area.header-learning .header-inner .client-area {
  display: flex;
}
.header-area.header-learning .header-inner .client-area .client-item:first-child {
  margin-right: 75px;
}
.header-area.header-startup {
  padding: 290px 0 225px;
}
.header-area.header-startup .header-inner span {
  color: #fff;
  font-family: var(--heading-font);
  font-size: 48px;
  font-weight: 400;
  line-height: 72px;
  text-transform: initial;
}
.header-area.header-startup .header-inner .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #fff;
}
.header-area.header-startup .header-inner .header-form-area {
  margin-top: 30px;
  display: flex;
  margin-bottom: 150px;
}
.header-area.header-startup .header-inner .header-form-area .form-group {
  width: 100%;
  margin-bottom: 0;
  margin-right: 20px;
}
.header-area.header-startup .header-inner .header-form-area .form-group .form-control {
  border-radius: 5px;
  border: none;
  padding: 15px 30px 15px 15px;
  color: var(--heading-color);
  background-color: #fff;
}
.header-area.header-startup .header-inner .header-form-area .form-group .form-control::placeholder {
  color: #9a98b7;
  font-size: 14px;
  font-weight: 500;
}
.header-area.header-startup .header-inner .header-form-area .submit-btn {
  border-radius: 5px;
  background-color: var(--secondary-color);
  padding: 15px 15px;
  font-weight: 600;
  color: #fff;
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--secondary-color);
}
.header-area.header-startup .header-inner .header-form-area .submit-btn:hover {
  background-color: var(--main-color-two);
  color: #fff;
  border: 1px solid var(--main-color-one);
}
.header-area.header-startup .header-inner .vdo-btn .video-play {
  color: #fff;
}
.header-area.header-startup .header-inner .vdo-btn .video-play i {
  width: 80px;
  height: 80px;
  text-align: center;
  border: 1px solid #e2e2e2;
  line-height: 80px;
  border-radius: 50%;
  transition: all 0.3s;
  display: inline-block;
  margin-right: 20px;
}
.header-area.header-startup .header-inner .vdo-btn .video-play:hover i {
  background-color: #fff;
  color: var(--main-color-one);
}
.header-area.header-startup .startup-bg-img {
  height: 870px;
  width: 745px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 15%;
  z-index: -1;
}
.header-area.header-startup .shape {
  position: absolute;
  left: 5%;
  top: 12%;
  transform: rotate(12deg);
  width: 220px;
  height: 250px;
  border-radius: 50px;
  background-image: linear-gradient(43deg, rgba(81, 185, 245, 0.38) 0%, rgba(82, 185, 245, 0.01) 100%);
  z-index: -1;
  animation: rotate10deg 5s infinite;
}
.header-area.header-startup .shape-02 {
  position: absolute;
  right: 5%;
  top: 12%;
  transform: rotate(12deg);
  width: 730px;
  height: 745px;
  border-radius: 50px;
  background-image: linear-gradient(180deg, rgba(81, 185, 245, 0.38) 0%, rgba(82, 185, 245, 0.01) 100%);
  z-index: -2;
  animation: rotate10deg 15s infinite;
}
@keyframes rotate10deg {
  0% {
    transform: rotate(-12deg);
  }
  50% {
    transform: rotate(12deg);
  }
  100% {
    transform: rotate(-12deg);
  }
}
.header-area.header-sass {
  position: relative;
  z-index: 0;
  padding: 205px 0 255px;
}
.header-area.header-sass::after {
  content: "";
  left: 50%;
  top: 125px;
  border-radius: 20px;
  transform: translateX(-50%);
  position: absolute;
  width: 95%;
  height: 80%;
  background-color: #2e2a59;
  z-index: -1;
}
.header-area.header-sass .sass-bg-img-02 {
  position: absolute;
  height: 730px;
  width: 830px;
  left: 60%;
  bottom: -35%;
  z-index: 0;
  animation: pulse-border 3s infinite;
}
.header-area.header-sass .shape {
  position: absolute;
  right: -50%;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffd5a8;
  z-index: -1;
  animation: size-increase-anmi 10s infinite;
}
.header-area.header-sass .shape-02 {
  position: absolute;
  left: -10%;
  top: -10%;
  width: 330px;
  height: 330px;
  border-radius: 50%;
  background-color: #ffd5a8;
  z-index: -1;
  animation: size-increase-anmi 5s infinite;
}
@keyframes size-increase-anmi {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
.header-area.header-sass .sass-bg-img {
  height: 730px;
  width: 830px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 15%;
  z-index: 0;
}
.header-area.header-sass .header-inner .title {
  color: #fff;
}
.header-area.header-sass .header-inner p {
  color: #b2a9f4;
  font-size: 18px;
  max-width: 500px;
}
.header-area.header-brand {
  background-color: #f5e1c6;
  position: relative;
  z-index: 0;
  padding: 205px 0 255px;
}
.header-area.header-brand .sass-bg-img-02 {
  position: absolute;
  height: 730px;
  width: 830px;
  left: 60%;
  bottom: -35%;
  z-index: 0;
  animation: pulse-border 3s infinite;
}
.header-area.header-brand .shape {
  position: absolute;
  left: -25%;
  top: -25%;
  transform: translate(-50%, -50%);
  width: 650px;
  height: 650px;
  border-radius: 50%;
  border: 2px solid #fff;
  z-index: -1;
  animation: size-increase 15s linear infinite;
}
.header-area.header-brand .shape::before {
  content: "";
  position: absolute;
  left: 23%;
  top: 0%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  z-index: -1;
}
@keyframes size-increase {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header-area.header-brand .sass-bg-img {
  height: 730px;
  width: 830px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 15%;
  z-index: 0;
}
.header-area.header-brand .header-inner .title {
  color: #000629;
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
}
.header-area.header-brand .header-inner p {
  color: #b2a9f4;
  font-size: 18px;
  max-width: 500px;
}
.header-area.header-lifestyle {
  position: relative;
  z-index: 0;
  padding: 205px 0 540px;
}
.header-area.header-lifestyle .sass-bg-img {
  height: 730px;
  width: 830px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 15%;
  z-index: 0;
}
.header-area.header-lifestyle .shape {
  position: absolute;
  left: 0;
  bottom: 0;
}
.header-area.header-lifestyle .shape-03 {
  position: absolute;
  left: -25%;
  top: -25%;
  transform: translate(-50%, -50%);
  width: 650px;
  height: 650px;
  border-radius: 50%;
  border: 1px solid #fff;
  z-index: -1;
  animation: size-increase 15s linear infinite;
}
.header-area.header-lifestyle .shape-03::before {
  content: "";
  position: absolute;
  left: 23%;
  top: 0%;
  width: 60px;
  height: 60px;
  opacity: 0.6;
  border-radius: 50%;
  background-color: #fff;
  z-index: -1;
}
.header-area.header-lifestyle .header-inner .title {
  color: #fff;
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
}
.header-area.header-lifestyle .header-inner p {
  color: #fff;
  font-size: 18px;
  max-width: 500px;
}
.header-area.header-restaurant {
  background-color: #f7f0e8;
  padding: 290px 0 345px;
}
.header-area.header-restaurant .header-inner .title {
  font-size: 100px;
  line-height: 110px;
  font-weight: 700;
  color: #a30f10;
}
.header-area.header-restaurant .header-inner p {
  max-width: 560px;
}
.header-area.header-restaurant .header-inner .apps-download {
  margin-top: 40px;
  display: flex;
}
.header-area.header-restaurant .header-inner .apps-download .download-img {
  margin-right: 20px;
}
.header-area.header-restaurant .restaurant-bg-img {
  height: 360px;
  width: 530px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 20%;
  z-index: -1;
}
.header-area.header-restaurant .restaurant-bg-img-02 {
  height: 74px;
  width: 62px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 50%;
  bottom: 20%;
  z-index: -1;
}
.header-area.header-restaurant .restaurant-bg-img-03 {
  width: 45px;
  height: 133px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 55%;
  top: 36%;
  z-index: 0;
}
.header-area.header-restaurant .restaurant-bg-img-04 {
  height: 80px;
  width: 90px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 5%;
  top: 20%;
  z-index: 0;
}
.header-area.header-gym {
  background-color: transparent;
  padding: 290px 0 445px;
}
.header-area.header-gym .header-inner .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #fff;
}
.header-area.header-gym .header-inner p {
  max-width: 560px;
}
.header-area.header-gym .shape {
  position: absolute;
  left: -10%;
  top: -13%;
  transform: rotate(-12deg);
  width: 560px;
  height: 560px;
  border-radius: 50px;
  background-image: linear-gradient(-360deg, rgba(255, 0, 170, 0.5) 0%, rgba(255, 1, 170, 0) 100%);
  z-index: -1;
  animation: rotate10deg 5s infinite;
}
.header-area.header-gym .shape-02 {
  position: absolute;
  left: -15%;
  top: -20%;
  transform: rotate(-12deg);
  width: 560px;
  height: 560px;
  border-radius: 50px;
  background-image: linear-gradient(-360deg, rgba(255, 0, 170, 0.5) 0%, rgba(255, 1, 170, 0) 100%);
  z-index: -1;
  animation: rotate10deg 5s infinite;
}
.header-area.header-gym .gym-bg-img {
  height: 850px;
  width: 625px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 15%;
  z-index: -1;
}
.header-area.header-finance {
  background-color: transparent;
  padding: 290px 0 600px;
}
.header-area.header-finance .header-inner .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #fff;
}
.header-area.header-finance .header-inner p {
  max-width: 560px;
}
.header-area.header-finance .finance-bg-img {
  height: 750px;
  width: 420px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 15%;
  z-index: -1;
}
.header-area.header-finance .finance-bg-img-02 {
  height: 730px;
  width: 510px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 15%;
  z-index: -2;
}
.header-area.header-btech {
  background-color: transparent;
  padding: 240px 0 400px;
  position: relative;
  z-index: 1;
}
.header-area.header-btech .header-inner .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #fff;
}
.header-area.header-btech .header-inner p {
  max-width: 560px;
  padding-bottom: 10px;
}
.header-area.header-btech .header-inner .inner-top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-area.header-btech .header-inner .inner-top .text-btn {
  background: #F5861F;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
  color: #fff;
  margin-right: 15px;
}
.header-area.header-btech .header-inner .inner-top .in-title {
  color: #fff;
  margin: 0;
}
.header-area.header-business {
  background-color: transparent;
  padding: 290px 0 255px;
}
.header-area.header-business .header-inner .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #000629;
}
.header-area.header-business .header-inner p {
  color: #000629;
  max-width: 560px;
}
.header-area.header-business .business-bg-img {
  height: 750px;
  width: 420px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 15%;
  z-index: -1;
}
.header-area.header-business .business-bg-img-02 {
  height: 930px;
  width: 800px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -2;
}
.header-area.header-business .shape-02 {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #f6ece4;
  z-index: -1;
}
.header-area.header-medical {
  background-color: transparent;
  padding: 200px 0 490px;
}
.header-area.header-medical .header-inner .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #352d66;
}
.header-area.header-medical .header-inner p {
  color: #352d66;
  font-size: 18px;
}
.header-area.header-medical .business-bg-img {
  min-height: 554px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  position: absolute;
  right: 0;
  top: 45%;
  z-index: -1;
}
.header-area.header-medical .medi-shape-01 {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 336px;
  position: absolute;
  bottom: 15%;
  left: 90%;
}
.header-area.header-medical .medi-shape-02 {

  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 650px;
  position: absolute;
  bottom: -8%;
  left: 0;
}
.header-area.header-customer {
  background-color: transparent;
  padding: 270px 0 0px;
  position: relative;
  z-index: 0;
  overflow: unset;
}
.header-area.header-customer .header-inner .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #16215c;
}
.header-area.header-customer .business-bg-img {
  height: 750px;
  width: 700px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 6%;
  top: 30%;
  z-index: -1;
}
.header-area.header-customer .business-bg-img-02 {
  height: 930px;
  width: 800px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -2;
}
.header-area.header-entertainment {
  background-color: transparent;
  padding: 290px 0 445px;
}
.header-area.header-entertainment .header-inner .title {
  font-size: 120px;
  line-height: 130px;
  font-weight: 700;
  color: #f8f601;
}
.header-area.header-entertainment .header-inner p {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}
.header-area.header-entertainment .entertainment-bg-img {
  height: 585px;
  width: 730px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 15%;
  z-index: -1;
}
.header-area .subtitle {
  position: relative;
  z-index: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 70px;
  letter-spacing: 1px;
}
.header-area .subtitle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 60px;
  height: 2px;
  background-color: var(--main-color-one);
}
.header-area.header-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.header-area.header-bg-02 {
  background-color: #000;
  background-position: center;
  background-size: cover;
  position: relative;
}
.header-area.header-bg-02::after {
  content: "";
  position: absolute;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 50, 122, 0.3);
  z-index: -1;
}
.header-area.header-bg-03 {
  background-color: #000;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 0;
}
.header-area.header-bg-03::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(22, 50, 122, 0.6);
  z-index: -1;
}
.header-area.header-bg-04 {
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
}
.header-area.header-bg-05 {
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.header-area .bg-img {
  height: 250px;
  width: 380px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 50px;
  z-index: -1;
  animation: bikerun 10s infinite;
  transition: all 0.5s;
}
@keyframes bikerun {
  0% {
    right: -27%;
  }
  100% {
    right: 100%;
  }
}
.header-area .bg-img-02 {
  height: 130px;
  width: 110px;
  background-repeat: no-repeat;
  position: absolute;
  right: 20%;
  top: 10%;
  z-index: -1;
  animation: balloonfly 15s infinite;
  transition: all 0.5s;
}
@keyframes balloonfly {
  0% {
    top: 40%;
    opacity: 0;
  }
  30% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    top: 10%;
    opacity: 0;
  }
}
.header-area .bg-img-03 {
  height: 30px;
  width: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 27%;
  top: 10%;
  z-index: -1;
  animation: balloonfly-02 10s infinite;
  transition: all 0.5s;
}
@keyframes balloonfly-02 {
  0% {
    top: 40%;
    opacity: 0;
  }
  30% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    top: 10%;
    opacity: 0;
  }
}
.header-area .bg-img-04 {
  height: 50px;
  width: 65px;
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  right: 12%;
  top: 1%;
  z-index: -1;
  animation: balloonfly-03 12s infinite;
  transition: all 0.5s;
}
@keyframes balloonfly-03 {
  0% {
    top: 40%;
    opacity: 0;
  }
  30% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    top: 15%;
    opacity: 0;
  }
}
.header-area .bg-img-05 {
  height: 165px;
  width: 60px;
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  left: 5%;
  bottom: 60px;
  z-index: -2;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
}
.header-area .bg-img-06 {
  height: 135px;
  width: 35px;
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  left: 9%;
  bottom: 60px;
  z-index: -2;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
}
.header-area .bg-img-07 {
  height: 115px;
  width: 50px;
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  left: 31%;
  bottom: 60px;
  z-index: -2;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
}
.header-area .bg-img-08 {
  height: 300px;
  width: 70px;
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  right: 19%;
  bottom: 60px;
  z-index: -2;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
}
.header-area .bg-img-09 {
  height: 450px;
  width: 165px;
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  right: 8%;
  bottom: 60px;
  z-index: -2;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
}
.header-area .bg-img-10 {
  height: 275px;
  width: 70px;
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  right: 5%;
  bottom: 60px;
  z-index: -2;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
}
.header-area .bg-img-11 {
  height: 620px;
  width: 510px;
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  right: 2%;
  bottom: 0;
  z-index: -2;
}
.header-area .header-inner.style-01 {
  display: flex;
  align-items: center;
  height: 100%;
}
.header-area .title {
  font-size: 72px;
  line-height: 84px;
  color: var(--heading-color);
  font-weight: 700;
  margin-bottom: 30px;
}
.header-area p {
  font-size: 18px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.8);
}
.header-area span {
  color: var(--secondary-color);
  font-size: 18px;
  text-transform: uppercase;
  display: block;
}
.header-area .btn-wrapper .boxed-btn {
  border: 1px solid transparent;
}
.header-area .btn-wrapper .boxed-btn:hover {
  border: 1px solid #fff;
  background-color: transparent;
}
.header-area .boxed-btn + .boxed-btn {
  margin-left: 20px;
}

/*----------------------------
    Header Bottom Area
-----------------------------*/
.header-bottom-wrapper .right-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-bottom-area {
  position: relative;
  z-index: 0;
}
.header-bottom-area .half-cricle {
  position: absolute;
  left: 0;
  top: 0;
  width: 210px;
  height: 110px;
  background-color: transparent;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  border: 50px solid #feba71;
  transform: rotate(140deg);
  border-bottom: 0;
  transform-origin: bottom;
  animation: rotate 12s both;
}
@keyframes rotate {
  0% {
    border-color: #feba71;
    transform: rotate(0deg);
  }
  25% {
    border-color: #feae71;
  }
  50% {
    border-color: #fe9b71;
  }
  75% {
    border-color: #fe8b71;
  }
  100% {
    border-color: #fe8171;
    transform: rotate(140deg);
  }
}
.header-bottom-area .triangle {
  position: absolute;
  right: 10%;
  bottom: 0;
  width: 0;
  height: 0;
  transform: skewX(10deg);
  border-top: 30px solid transparent;
  border-left: 30px solid transparent;
  border-right: 30px solid #bd25fd;
  border-bottom: 30px solid #bd25fd;
}
.header-bottom-area.bg-image {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}
.header-bottom-area.bg-image::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 90px;
  background-color: #fff;
  z-index: -1;
}
.header-bottom-area.m-bottom {
  margin-bottom: -90px;
  position: relative;
  z-index: 1;
}
.header-bottom-area.m-top {
  margin-top: -137px;
  position: relative;
  z-index: 1;
}
.header-bottom-area.audio-bottom-area {
  background-color: #100718;
}

.header-bottom-wrap {
  background-color: var(--secondary-color);
  padding: 30px 0 40px 0;
}

.request-form-wrapper {
  padding: 60px 70px 47px;
}
.request-form-wrapper.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}
.request-form-wrapper .contact-page-form .btn-wrapper .boxed-btn {
  width: 100%;
  border-radius: 0;
  padding: 24.5px 40px;
}
.request-form-wrapper .contact-page-form .btn-wrapper .boxed-btn.brrtb {
  border-radius: 0px 5px 5px 0px;
}

/*-------------------------
    Footer Area
-------------------------*/
.footer-top.style-01 {
  border-bottom: 1px solid rgba(114, 108, 148, 0.2);
  background-color: #e9eff0;
}
.footer-top.style-01 .widget .widget-title {
  color: var(--main-color-one);
}
.footer-top.style-02 {
  border-bottom: 1px solid rgba(114, 108, 148, 0.2);
  background-color: #fff;
}
.footer-top.style-02 .widget .widget-title {
  color: var(--main-color-one);
}
.footer-top.style-03 {
  border-bottom: 1px solid rgba(114, 108, 148, 0.2);
  background-color: #2e2a59;
}
.footer-top.style-03 .widget .widget-title {
  color: var(--main-color-one);
}
.footer-top.style-04 {
  border-bottom: 1px solid rgba(114, 108, 148, 0.2);
  background-color: #fafafa;
}
.footer-top.style-04 .widget .widget-title {
  color: var(--main-color-one);
}
.footer-top .bg-img {
  position: absolute;
  top: 40%;
  left: 10%;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  z-index: -1;
}
.footer-top .bg-img-02 {
  position: absolute;
  top: 10%;
  right: 10%;
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  z-index: -1;
}
.footer-top .bg-img-03 {
  position: absolute;
  top: 0;
  left: 30%;
  width: 120px;
  height: 95px;
  background-repeat: no-repeat;
  z-index: -1;
}

.copyright-area {
  background-color: #e9eff0;
}
.copyright-area.style-01 {
  background-color: #fff;
}
.copyright-area.style-02 {
  background-color: #292554;
}
.copyright-area.style-03 {
  background-color: #fafafa;
}
.copyright-area.style-04 {
  background-color: #04000f;
}
.copyright-area .copyright-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright-area-inner {
  color: #7d7d93;
}
.copyright-area-inner.style-01 {
  color: #a9b2e2;
}

.food-section .title {
  font-weight: 700;
  font-size: 80px;
  line-height: 90px;
  color: #fff;
  margin-bottom: 20px;
}
.food-section .apps-download {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.food-section .apps-download .download-img {
  margin: 0 10px;
}

.food-widget {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}
.food-widget ul li {
  display: inline-block;
}
.food-widget ul li + li {
  margin-left: 30px;
}

/*-----------------------
    Error 404 Page
-----------------------*/
.error-404 {
  text-align: center;
}
.error-404 .title {
  font-size: 200px;
  line-height: 160px;
  font-weight: 700;
  margin-bottom: 45px;
  color: var(--main-color-one);
}
.error-404 .subtitle {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 20px;
}
.error-404 .search-form {
  position: relative;
  z-index: 0;
  margin-top: 36px;
}
.error-404 .search-form .form-control {
  height: 54px;
  padding: 0 20px;
}
.error-404 .search-form .submit-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  text-align: center;
  padding: 0;
  height: 55px;
}

/*-----------------------------
    Call To Action Area
-----------------------------*/
.call-to-action-area.style-01 {
  background-color: #9738f6;
  position: relative;
  z-index: 0;
}
.call-to-action-area.style-01::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 70%;
  -webkit-clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
          clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
  background-color: #fd37e2;
  z-index: -1;
}

.call-to-action-area-01 {
  padding-bottom: 330px;
}
.call-to-action-area-01.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 255px;
}

.call-to-action-inner {
  background-color: #16215c;
  padding: 55px 0 60px;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  text-align: center;
  overflow: hidden;
}
.call-to-action-inner .line-area {
  position: absolute;
  bottom: 0;
  left: 0;
}
.call-to-action-inner .line-area .line-one {
  position: absolute;
  left: 30px;
  bottom: -2px;
  transform: rotate(35deg);
  width: 2px;
  height: 50px;
  background-color: #43406a;
  z-index: -1;
}
.call-to-action-inner .line-area .line-two {
  position: absolute;
  left: 45px;
  bottom: -5px;
  transform: rotate(35deg);
  width: 2px;
  height: 70px;
  background-color: #43406a;
  z-index: -1;
}
.call-to-action-inner .line-area .line-three {
  position: absolute;
  left: 50px;
  bottom: -5px;
  transform: rotate(35deg);
  width: 2px;
  height: 50px;
  background-color: #43406a;
  z-index: -1;
}
.call-to-action-inner .bubble {
  position: absolute;
  bottom: 0;
  right: 30%;
  width: 65px;
  height: 65px;
  background-color: transparent;
  border: 1px solid #43406a;
  border-radius: 50%;
  z-index: -1;
  animation: pulse-border 3s infinite;
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
}
.call-to-action-inner .action-bg-img {
  position: absolute;
  width: 135px;
  height: 140px;
  background-size: contain;
  top: 0;
  left: 50%;
  background-repeat: no-repeat;
  animation: pulse-border 2s infinite;
}
.call-to-action-inner .action-bg-img-02 {
  position: absolute;
  width: 300px;
  height: 300px;
  background-size: contain;
  top: -15px;
  right: -280px;
  background-repeat: no-repeat;
  animation: pulse-border 5s infinite;
}
@keyframes increase {
  0% {
    height: 50px;
    transform: scale(2);
  }
  50% {
    height: 70px;
  }
  100% {
    height: 50px;
    transform: scale(2);
  }
}
.call-to-action-inner.style-01 {
  padding: 75px 95px 80px;
  text-align: left;
  background-color: #2416ac;
}
.call-to-action-inner.style-01 .title {
  font-weight: 400;
  max-width: 530px;
  margin-bottom: 40px;
}
.call-to-action-inner.style-01.bg-image {
  background-size: initial;
  background-position: right;
  background-repeat: no-repeat;
}
.call-to-action-inner.style-02 {
  display: flex;
  padding: 75px 95px 80px;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  background-color: #2e2a59;
}
.call-to-action-inner.style-02 .btn-wrapper .boxed-btn {
}
.call-to-action-inner.style-03 {
  background-color: #f9f3ff;
  text-align: left;
  padding: 70px 90px 65px;
  border-radius: 5px;
  position: relative;
  z-index: 0;
}
.call-to-action-inner.style-03 .shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  background-color: #ffef80;
  z-index: -1;
  left: -20px;
  top: -20px;
  border-radius: 50%;
  animation: size-increase-anmi 5s infinite;
}
.call-to-action-inner.style-03 .shape-02 {
  position: absolute;
  right: 30px;
  bottom: 80px;
  width: 60px;
  height: 60px;
  background-color: #fa555a;
  z-index: -1;
  border-radius: 50%;
  animation: size-increase-anmi 5s infinite;
}
.call-to-action-inner.style-03 .shape-03 {
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 10px solid #455b79;
  z-index: -1;
  border-radius: 50%;
}
.call-to-action-inner.style-03 .shape-04 {
  position: absolute;
  right: 20%;
  bottom: 80px;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: pulse-border 10s infinite;
}
.call-to-action-inner.style-03 .shape-05 {
  position: absolute;
  right: 10%;
  bottom: 0;
  width: 210px;
  height: 110px;
  background-size: contain;
  background-repeat: no-repeat;
}
.call-to-action-inner.style-03 .content .title {
  color: #000629;
  font-size: 42px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 20px;
}
.call-to-action-inner.style-04 {
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 55px 80px 55px 90px;
  background-color: #e94243;
  align-items: center;
  position: relative;
  top: -150px;
}
.call-to-action-inner.style-04 .title {
  font-size: 42px;
  line-height: 52px;
}
.call-to-action-inner.style-05 {
  margin-bottom: -200px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 30px 80px 0px 0;
  background: linear-gradient(90deg, #4785ec 0%, #3366c1 100%);
  align-items: center;
}
.call-to-action-inner.style-05 .call-to-action-content {
  padding: 80px 0;
}
.call-to-action-inner.style-05 .title {
  font-size: 42px;
  line-height: 52px;
}
.call-to-action-inner.style-05 .apps-download {
  display: flex;
  margin-top: 30px;
}
.call-to-action-inner.style-05 .apps-download .download-img.style-01 {
  margin-left: 30px;
}
.call-to-action-inner.style-06 {
  padding: 90px 0 80px;
  background-image: linear-gradient(360deg, #ff6661 0%, #f54278 100%);
}
.call-to-action-inner.style-06 .title {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 30px;
}
.call-to-action-inner.style-06 .subscribe-form {
  display: flex;
}
.call-to-action-inner.style-06 .subscribe-form .form-group {
  margin-bottom: 0;
  flex: 1;
}
.call-to-action-inner.style-06 .subscribe-form .form-group .form-control {
  width: 100%;
  height: 55px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  color: #fff;
}
.call-to-action-inner.style-06 .subscribe-form .form-group .form-control::placeholder {
  color: #fff;
}
.call-to-action-inner.style-06 .subscribe-form .submit-btn {
  margin-left: 30px;
  background-color: transparent;
  color: #fff;
  display: inline-block;
  padding: 12px 50px;
  border-radius: 5px;
  border: 1px solid #fff;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-weight: 600;
}
.call-to-action-inner.style-06 .subscribe-form .submit-btn:hover {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
}
.call-to-action-inner.style-07 {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 55px 80px 55px 90px;
  background-color: transparent;
  position: relative;
  z-index: 0;
}
.call-to-action-inner.style-07 .bg-img {
  position: absolute;
  right: 20%;
  min-height: 200px;
  bottom: 0;
  width: 450px;
  height: 200px;
  background-repeat: no-repeat;
  z-index: -1;
}
.call-to-action-inner.style-07 .title {
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
  color: #fff;
}
.call-to-action-inner .bg-image {
  position: absolute;
  right: 50px;
  min-height: 200px;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  height: 235px;
  background-repeat: no-repeat;
}
.call-to-action-inner .icon {
  font-size: 120px;
  color: var(--secondary-color);
}
.call-to-action-inner .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  color: #fff;
}
.call-to-action-inner .subtitle {
  color: #fff;
}
.containerhmg {
  width: 95%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
}
.call-to-action-inner.style-08 {
  width: 90%;
  background-color: #fff;
  box-shadow: 0px 0px 103px 0px #32A8A2;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 0px 100px 0px;
}
.call-to-action-inner.style-08 .title {
  font-size: 42px;
  color: #000629;
  line-height: 52px;
  font-weight: 700;
}

/*-------------------------
    Testimonial Area
--------------------------*/
.testimonial-area {
  position: relative;
  z-index: 0;
}
.testimonial-area .medical-image {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 494px;
  position: absolute;
  bottom: 5%;
  left: 50%;
}
.testimonial-area.style-01::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(-32deg, #432375 0%, #ac28a0 100%);
  z-index: -1;
}
.testimonial-area.medical {
  background-image: linear-gradient(-32deg, #bb73cd 0%, #8a45c4 100%);
}
.testimonial-area.medical .section-title .title {
  color: #fff;
}
.section-title h1 span{
color: #32A8A2;
}
.testimonial-area.blue {
  background-color: #0329b5;
}
.testimonial-area.blue .section-title .title {
  font-size: 42px;
  line-height: 52px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 25px;
}
.testimonial-area.blue .section-title p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.testimonial-area.bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.testimonial-area.bg-image-02 {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: -480px;
}
.testimonial-area .bg-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}
.testimonial-area .shape {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 105px;
  height: 55px;
  background-color: transparent;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  border: 5px solid #feba71;
  transform: rotate(140deg);
  border-bottom: 0;
  transform-origin: bottom;
  animation: rotate 12s both;
}
.testimonial-area .shape-02 {
  position: absolute;
  top: 57%;
  right: 1%;
  transform: translateY(-50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #75e8f0;
}
.testimonial-area .social-testimonial {
  background-image: linear-gradient(-90deg, #49d9f4 0%, #8a54fb 99%);
  padding: 70px 70px 65px;
  border-radius: 5px;
  position: relative;
  z-index: 0;
}
.testimonial-area .social-testimonial .icon {
  position: absolute;
  left: 70px;
  top: 70px;
  font-size: 120px;
  line-height: 130px;
  color: rgba(255, 255, 255, 0.1);
}
.testimonial-area .social-testimonial .section-title .title {
  font-size: 48px;
  line-height: 58px;
  font-weight: 500;
}
.testimonial-area .sass-testimonial {
  background-image: linear-gradient(180deg, rgba(237, 247, 255, 0) 0%, #edf7ff 100%);
  padding: 0 100px 68px;
}
.testimonial-area .sass-testimonial .section-title .title {
  font-size: 30px;
  line-height: 40px;
  margin-right: 80px;
}
.testimonial-area .lifestyle-testimonial .section-title {
  display: flex;
  align-items: center;
}
.testimonial-area .lifestyle-testimonial .section-title .title {
  margin-right: 50px;
}
.testimonial-area .restaurant-testimonial {
  position: relative;
  z-index: 2;
}
.testimonial-area .restaurant-testimonial .section-title {
  text-align: left;
  position: absolute;
  top: -50px;
  left: 0;
  z-index: 1;
}
.testimonial-area .medical .title {
  font-size: 48px;
  line-height: 50px;
  color: #16215c;
}
.testimonial-area .learning .title {
  font-size: 48px;
  line-height: 58px;
  color: #16215c;
  font-weight: 600;
  margin-bottom: 20px;
}
.testimonial-area .learning p {
  font-size: 16px;
  line-height: 26px;
}

.contact-area.bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}

/*---------------------------
    Request Form Quote
---------------------------*/
.request-page-form-wrap {
  background-color: rgba(231, 15, 71, 0.85);
  padding: 50px 45px 50px;
  position: relative;
  z-index: 2;
}
.request-page-form-wrap.style-01 {
  padding: 52px 50px 44px;
  margin-right: 98px;
}
.request-page-form-wrap.style-02 {
  padding: 52px 50px 44px;
}
.request-page-form-wrap.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
}
.request-page-form-wrap.bg-image::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(231, 15, 71, 0.85);
  z-index: -1;
}
.request-page-form-wrap .section-title .title {
  font-size: 24px;
  font-weight: 800;
  line-height: 34px;
}

.request-page-form .form-group textarea {
  max-height: 60px;
  width: 100%;
  resize: none;
}
.request-page-form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 15px 30px 15px 0px;
  color: #fff;
  background-color: transparent;
}
.request-page-form .form-group .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
}
.request-page-form .submit-btn {
  background-color: var(--secondary-color);
  width: 100%;
  padding: 19px 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--secondary-color);
}
.request-page-form .submit-btn:hover {
  background-color: var(--main-color-two);
  color: #fff;
  border: 1px solid var(--main-color-one);
}

.question-form-area {
  background-color: #e5e5ec;
  padding: 50px;
}

.question-form {
  display: flex;
}
.question-form .form-group {
  width: 100%;
  margin-bottom: 0;
  margin-right: 20px;
}
.question-form .form-group .form-control {
  border-radius: 5px;
  border: none;
  padding: 15px 30px 15px 15px;
  color: var(--heading-color);
  background-color: #fff;
}
.question-form .form-group .form-control::placeholder {
  color: #9a98b7;
  font-size: 14px;
  font-weight: 500;
}
.question-form .submit-btn {
  width: 250px;
  border-radius: 5px;
  background-color: var(--secondary-color);
  padding: 15px 15px;
  font-weight: 600;
  color: #fff;
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--secondary-color);
}
.question-form .submit-btn:hover {
  background-color: var(--main-color-two);
  color: #fff;
  border: 1px solid var(--main-color-one);
}

.price-plan-area {
  position: relative;
  z-index: 0;
}
.price-plan-area.price-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  padding-top: 200px;
}
.price-plan-area .bg-img {
  position: absolute;
  left: 0;
  top: 220px;
  width: 500px;
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: topBottom 5s infinite;
}
.price-plan-area .bg-img-02 {
  position: absolute;
  right: 0;
  top: 220px;
  width: 500px;
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: leftRight 10s infinite;
}
@keyframes topBottom {
  0% {
    top: 0%;
  }
  50% {
    top: 10%;
  }
  100% {
    bottom: 0;
  }
}
@keyframes leftRight {
  0% {
    right: 0%;
  }
  50% {
    right: 15%;
  }
  100% {
    right: 0%;
  }
}

/*--------------------------
    Counterup Area
--------------------------*/
.counterup-area {
  background-color: #f1f4fe;
}
.counterup-area.style-01 {
  position: relative;
  z-index: 0;
  background-color: #0f1182;
}
.counterup-area.style-01 .bg-img {
  position: absolute;
  top: 0;
  left: 10%;
  width: 180px;
  height: 300px;
  background-size: cover;
  z-index: -1;
}
.counterup-area.style-01 .bg-img-02 {
  position: absolute;
  bottom: 0;
  right: 5%;
  width: 450px;
  height: 200px;
  background-size: cover;
  z-index: -1;
}
.counterup-area.style-01 .icon {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  font-size: 40px;
  line-height: 130px;
  text-align: center;
  margin: auto;
  color: #fff;
}
.counterup-area.style-01 .content .count-pera {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 25px;
}
.counterup-area.style-01 .content span {
  color: rgba(255, 255, 255, 0.8);
}
.counterup-area.style-01 .content strong {
  color: #fff;
}
.counterup-area.utility-bg {
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-area.bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.choose-us-area {
  position: relative;
  z-index: 1;
}
.choose-us-area.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.choose-us-area .bg-img {
  position: absolute;
  left: 0;
  bottom: 375px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 485px;
  animation: topbottom 10s infinite;
  transition: all 0.5s;
}
@keyframes topbottom {
  0% {
    bottom: 255px;
  }
  50% {
    bottom: 275px;
  }
  100% {
    bottom: 255px;
  }
}
.choose-us-area .bg-img-02 {
  position: absolute;
  right: 50px;
  bottom: 285px;
  height: 165px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
  z-index: -1;
}
.choose-us-area .bg-img-03 {
  position: absolute;
  right: 0;
  bottom: 285px;
  height: 165px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
  z-index: -1;
}

/*-----------------------------
Client Area
-----------------------------*/
.client-area .single-brand a img {
  filter: opacity(0.6) grayscale(1);
  transition: all 0.3s ease;
}
.client-area .single-brand a:hover img {
  filter: opacity(1) grayscale(0);
}
.client-area .section-title .title {
  font-size: 42px;
  color: #000629;
}
.client-area .section-title .title.style-01 {
  font-size: 30px;
  color: #16215c;
}

.section-wrapper.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: -1;
}

.offer-area.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.offer-area.padding-bottom-400 {
  padding-bottom: 360px;
}
.offer-area .section-title.customer .title {
  font-size: 48px;
  line-height: 58px;
  color: #fff;
}

.offer-item-wrap.m-bottom {
  margin-bottom: -350px;
}
.offer-item-wrap.m-top {
  margin-top: -320px;
}
.offer-item-wrap.learning-app {
  margin-top: -520px;
  background: #fff;
  padding: 130px 100px 137px 100px;
  border-radius: 15px;
  box-shadow: 0px 0px 100px -17px rgba(110, 107, 138, 0.2);
}
.offer-item-wrap.learning-app .section-title .title {
  font-size: 48px;
  line-height: 58px;
  color: #16215c;
  margin-bottom: 25px;
}

.build-area .shape {
  position: relative;
  z-index: 0;
}
.build-area .shape:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 530px;
  height: 530px;
  border-radius: 50%;
  background-color: #edf7ff;
  z-index: -1;
}
.build-area .build-img.bg-image {
  padding-top: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 450px;
  width: 100%;
}
.build-area .build-img.bg-image-02 {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 450px;
  width: 100%;
}

.bulid-content-area {
  display: flex;
  align-items: center;
  height: 100%;
}
.bulid-content-area .heading-title .title {
  font-size: 12px;
  font-weight: 600;
  color: #9496a0;
  line-height: 22px;
}
.bulid-content-area .heading-title span {
  display: inline-block;
  width: 50px;
  height: 2px;
  background-image: linear-gradient(150deg, #45C0D0 0%, #0894E0 100%);
  position: relative;
  top: -3px;
  margin-right: 20px;
}
.bulid-content-area .section-title span {
  color: #f92760;
  font-weight: 500;
  padding: 8px 30px;
  background-color: rgba(249, 39, 96, 0.14);
  border-radius: 30px;
  margin-bottom: 30px;
  display: inline-block;
}
.bulid-content-area .section-title span.style-01 {
  font-weight: 500;
  background-color: #e9f0fe;
}
.bulid-content-area .section-title .title {
  max-width: 330px;
}
.bulid-content-area.style-01 {
  display: block;
  padding-top: 30px;
}
.bulid-content-area.style-01 .section-title .title {
  font-size: 48px;
  line-height: 58px;
  max-width: 100%;
  margin-bottom: 30px;
}
.bulid-content-area.style-01 .section-title p {
  line-height: 30px;
}

.build-area-02 {
  background-color: #100718;
}
.build-area-02 .stream-app-area {
  background-color: #1e1528;
  padding: 60px 50px;
}
.build-area-02 .audio-triangle-area {
  position: relative;
  z-index: 1;
}
.build-area-02 .audio-triangle-area .audio-bg {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 500px;
  text-align: center;
  margin: 60px 0;
}
.build-area-02 .audio-triangle-area .content.style-01 {
  text-align: right;
}
.build-area-02 .audio-triangle-area .content .title {
  color: #fff;
  font-size: 24px;
  margin-bottom: 15px;
}
.build-area-02 .audio-triangle-area .content .audio-pera {
  font-size: 16px;
  margin: 0 0 0 35px;
  color: #c6c6e0;
  line-height: 28px;
}
.build-area-02 .audio-triangle-area .content .style-01 {
  font-size: 16px;
  color: #c6c6e0;
  line-height: 28px;
}
.build-area-03 .section-title .title {
  font-size: 48px;
  line-height: 58px;
  font-weight: 600;
  color: #16215C;
  max-width: 350px;
  margin: auto;
  margin-bottom: 20px;
}
.build-area-03 .section-title p {
  font-size: 16px;
  padding: 0 20px;
  line-height: 26px;
}

.learning-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
  padding: 0 0 600px 0;
  position: relative;
  z-index: -1;
}

.join-apps-area.style-01 {
  background-color: #f9f3ff;
}
.join-apps-area .section-title .apps-download {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.join-apps-area .section-title .apps-download .download-img {
  padding: 0 15px;
}
.join-apps-area .section-title .apps-download .download-link {
  margin-right: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #f74e86;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px;
  border-color: #f74e86;
  border-radius: 30px;
  padding: 15px 40px;
  transition: all 500ms ease-in-out;
}
.join-apps-area .section-title .apps-download .download-link i {
  margin-right: 10px;
}
.join-apps-area .section-title .apps-download .download-link:hover {
  background-color: #f74e86;
  color: #fff;
}
.join-apps-area .section-title .apps-download .download-link.style-01 {
  color: #7965cc;
  border-color: #7965cc;
}
.join-apps-area .section-title .apps-download .download-link.style-01:hover {
  background-color: #7965cc;
  color: #fff;
}

.food-order-area {
  background-color: #f7f0e8;
  position: relative;
  z-index: 0;
}
.food-order-area::after {
  content: "";
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  height: 10%;
  background-color: #f7f0e8;
  z-index: -1;
}
.food-order-area .bg-img {
  position: absolute;
  top: 50%;
  left: 0;
  width: 190px;
  height: 226px;
  background-repeat: no-repeat;
  z-index: -1;
}
.food-order-area .bg-img-02 {
  position: absolute;
  top: 20%;
  right: 10%;
  width: 180px;
  height: 200px;
  background-repeat: no-repeat;
}
.food-order-area .bg-img-03 {
  position: absolute;
  bottom: 10%;
  right: 5%;
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
}
.food-order-area .food-img {
  min-height: 550px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.destination-area .destination-img.bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  min-height: 450px;
  width: 100%;
}

.create-content-area .create-audio-content {
  background-color: #04000f;
  padding: 50px 0;
  border-radius: 10px;
}
.create-content-area.style-01 {
  background-color: #100718;
}
.create-content-area .section-title {
  max-width: 360px;
}
.create-content-area .section-title.audio-title {
  max-width: 370px;
}
.create-content-area .section-title .audio-title {
  color: #fff;
  font-size: 36px;
  line-height: 45px;
}
.create-content-area .section-title .audio-pera {
  font-size: 16px;
  line-height: 25px;
  color: #c6c6e0;
}
.create-content-area .content {
  margin: 0;
  padding: 0;
  list-style: none;
}
.create-content-area .content .audio-list {
  color: rgba(255, 255, 255, 0.9);
}
.create-content-area .content .audio-list strong {
  color: #fff;
}
.create-content-area .content li + li {
  margin-top: 20px;
}
.create-content-area .content li i {
  margin-right: 10px;
  color: #feba71;
}
.create-content-area .content li i.style-02 {
  color: #da3654;
}
.create-content-area .trip-img.bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 450px;
  width: 100%;
}

.create-content-wrap {
  display: flex;
  align-items: center;
  height: 100%;
}

.create-content-area-02 {
  position: relative;
  overflow: hidden;
  padding-bottom: 95px;
}
.create-content-area-02 .bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 336px;
  position: absolute;
  bottom: -18%;
  left: 47%;
}
.create-content-area-02 .section-title .title {
  font-size: 48px;
  line-height: 58px;
  color: #16215c;
}
.create-content-area-02 .icon-list-style {
  padding: 0;
  margin: 0;
  list-style: none;
}
.create-content-area-02 .icon-list-style li {
  display: flex;
  align-self: flex-start;
}
.create-content-area-02 .icon-list-style li + li {
  margin-top: 20px;
}
.create-content-area-02 .icon-list-style li .icon {
  font-size: 14px;
  line-height: 24px;
  color: #2b86ff;
  margin-right: 20px;
}
.create-content-area-02 .content .title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #16215c;
}
.create-content-area-02 .content p {
  font-size: 14px;
  line-height: 24px;
  color: #727777;
}
.create-content-area-02 .trip-img {
  max-width: 960px;
  height: auto;
  position: absolute;
  bottom: 95px;
  left: -203px;
}

.create-content-area-03 .section-title .title {
  font-size: 48px;
  line-height: 58px;
  color: #352D66;
  margin-bottom: 20px;
}
.create-content-area-03 .content {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 60px;
}
.create-content-area-03 .content .audio-list {
  color: rgba(255, 255, 255, 0.9);
}
.create-content-area-03 .content .audio-list strong {
  color: #4a4451;
}
.create-content-area-03 .content li + li {
  margin-top: 20px;
}
.create-content-area-03 .content li i {
  margin-right: 10px;
  color: #2b86ff;
}
.create-content-area-03 .trip-img.bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 550px;
  width: 600px;
  margin-bottom: 60px;
}

.unique-project-area .shape {
  position: relative;
  z-index: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.unique-project-area .shape .shape-02 {
  position: absolute;
  width: 130px;
  height: 150px;
  top: 30%;
  transform: translateY(-50%);
  border-radius: 57% 24% 71% 16%/55% 15% 73% 27%;
  background-color: #ffe477;
  z-index: -1;
  animation: shape-anmi 10s infinite;
}
.unique-project-area .shape .shape-02.style-01 {
  position: absolute;
  right: 0;
  top: 30%;
  transform: translateY(-50%);
  background-color: #151cc5;
}
@keyframes shape-anmi {
  0% {
    border-radius: 57% 24% 71% 16%/55% 15% 73% 27%;
  }
  25% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
  }
  50% {
    border-radius: 69% 31% 49% 51%/60% 36% 64% 40%;
  }
  75% {
    border-radius: 69% 31% 49% 51%/60% 36% 64% 40%;
  }
  100% {
    border-radius: 57% 24% 71% 16%/55% 15% 73% 27%;
  }
}
.unique-project-area .build-img.bg-image {
  padding-top: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 450px;
  width: 100%;
}
.unique-project-area .build-img.bg-image-02 {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 450px;
  width: 100%;
}

.unique-content-area {
  display: flex;
  align-items: center;
  height: 100%;
}
.unique-content-area .section-title .content {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 40px;
}
.unique-content-area .section-title .content li i {
  margin-right: 20px;
  color: #151cc5;
}
.unique-content-area .section-title .content li strong {
  color: #000629;
}
.unique-content-area .section-title .content li + li {
  margin-top: 15px;
}

.how-it-work-area {
  position: relative;
  z-index: 0;
}
.how-it-work-area .bg-shape-02 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background-color: #f7f0e8;
  z-index: -1;
}
.how-it-work-area.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.how-it-work-area .bg-shape {
  position: absolute;
  right: 10%;
  top: 10%;
  width: 320px;
  height: 105px;
}
.how-it-work-area .section-title .title {
  font-size: 42px;
  color: #b55700;
}

.quality-works-area {
  background-color: #f1f4fe;
}

.food-img.bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  min-height: 450px;
  width: 100%;
}

.delight-customers-area .section-title .title,
.online-order-area .section-title .title {
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
}
.delight-customers-area .section-title p,
.online-order-area .section-title p {
  font-size: 20px;
  line-height: 30px;
  font-family: var(--heading-font-02);
}
.delight-customers-area .content,
.online-order-area .content {
  padding: 0;
  margin: 0;
  list-style: none;
}
.delight-customers-area .content li,
.online-order-area .content li {
  font-size: 20px;
  font-family: var(--heading-font-02);
}
.delight-customers-area .content li + li,
.online-order-area .content li + li {
  margin-top: 10px;
}
.delight-customers-area .content li i,
.online-order-area .content li i {
  margin-right: 10px;
  color: #fbaf3f;
}

.fitness-app-area .fitness-app-img {
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 450px;
}
.fitness-app-area .content-wrap {
  display: flex;
  justify-content: space-between;
}
.fitness-app-area .content-wrap .content {
  margin: 0;
  padding: 0;
  list-style: none;
}
.fitness-app-area .content-wrap .content li {
  color: #111736;
  font-size: 18px;
}
.fitness-app-area .content-wrap .content li + li {
  margin-top: 10px;
}
.fitness-app-area .content-wrap .content li i {
  margin-right: 20px;
  color: #ae39ff;
}
.fitness-app-area .apps-download {
  display: flex;
  margin-top: 30px;
}
.fitness-app-area .apps-download .download-img.style-01 {
  margin-left: 30px;
}

.cover-your-wrap {
  position: relative;
  z-index: 0;
}
.cover-your-wrap .shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  border-radius: 20px;
  background-color: #f3f7ff;
}
.cover-your-wrap .shape.style-01 {
  right: 0;
  top: 0;
  left: initial;
}
.cover-your-wrap .shape-02 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  background-color: #ffd900;
  z-index: -1;
}
.cover-your-wrap .shape-02.style-01 {
  right: initial;
  left: 0;
}
@keyframes shape-anmi {
  0% {
    border-radius: 57% 24% 71% 16%/55% 15% 73% 27%;
  }
  25% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
  }
  50% {
    border-radius: 69% 31% 49% 51%/60% 36% 64% 40%;
  }
  75% {
    border-radius: 69% 31% 49% 51%/60% 36% 64% 40%;
  }
  100% {
    border-radius: 57% 24% 71% 16%/55% 15% 73% 27%;
  }
}
.cover-your-wrap .cover-img.bg-image {
  padding-top: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 450px;
  width: 100%;
}
.cover-your-wrap .cover-img.bg-image.style-01 {
  min-height: 330px;
}
.cover-your-wrap .cover-img.bg-image-02 {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 450px;
  width: 100%;
}
.cover-your-wrap .cover-img.bg-image-02.style-01 {
  min-height: 330px;
}

.supports-your-area {
  background-color: #f4f7fe;
}

.vivid-content-area {
  background-color: #9738f6;
  border-radius: 10px;
  padding: 80px 60px;
}
.vivid-content-area.style-01 {
  background-color: #fd37e2;
}
.vivid-content-area .content {
  padding: 0;
  margin: 0;
  margin-top: 30px;
  list-style: none;
}
.vivid-content-area .content li {
  color: #fff;
  font-size: 20px;
  font-family: var(--heading-font-02);
}
.vivid-content-area .content li i {
  margin-right: 15px;
}
.vivid-content-area .content li + li {
  margin-top: 15px;
}

.supports-img.bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 450px;
  width: 100%;
}

.get-started-area.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: -1;
}
.get-started-area.style-01 {
  background-color: #f4f7fe;
  -webkit-clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
}
.get-started-area.customer {
  padding: 100px 0 100px;
}
.get-started-area .section-title .content-area .ratings {
  color: #fff;
  margin-right: 5px;
}
.get-started-area .section-title .content-area .customer-para {
  font-size: 16px;
}
.get-started-area .section-title .author-meta .title {
  font-size: 18px;
}
.get-started-area .section-heading {
  padding: 200px 0 50px 0;
  text-align: center;
}
.get-started-area .section-heading .title {
  font-size: 48px;
  color: #fff;
}

.get-started-area-01 .section-title .title {
  font-size: 48px;
  color: #16215c;
}
.get-started-area-01 .ios-single-item {
  position: relative;
  z-index: 0;
}
.get-started-area-01 .ios-single-item .icon {
  font-size: 72px;
  color: #007bcf;
  text-align: center;
  padding-bottom: 40px;
}
.get-started-area-01 .ios-single-item .icon.style-01 {
  color: #20d2c4;
}
.get-started-area-01 .ios-single-item .color {
  width: 535px;
  height: 396px;
  background-color: #007bcf;
  border-radius: 20px;
  margin-top: 60px;
}
.get-started-area-01 .ios-single-item .color.style-01 {
  background-color: #20d2c4;
}
.get-started-area-01 .ios-single-item .bg-img {
  height: 474px;
  width: 408px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.financial-benifits-area.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.tracks-content-area .content {
  padding: 0;
  margin: 0;
  margin-top: 30px;
  list-style: none;
}
.tracks-content-area .content li {
  font-size: 20px;
  color: #111736;
  font-family: var(--heading-font-02);
}
.tracks-content-area .content li i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: #ebebeb;
  color: #ff6661;
  border-radius: 50%;
  font-size: 10px;
  margin-right: 15px;
}
.tracks-content-area .content li + li {
  margin-top: 15px;
}
.tracks-content-area .section-title .title {
  font-size: 42px;
  line-height: 55px;
  color: #000629;
}
.tracks-content-area .section-title .tracks-pera {
  font-size: 16px;
  font-weight: 400;
  padding-right: 48px;
  line-height: 25px;
}

.supports-img.bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 450px;
  width: 100%;
}
.supports-img.style-01 {
  min-height: 559px;
}

.tracks-content-area-01 .section-title .title {
  font-size: 48px;
  line-height: 58px;
  color: #16215c;
}
.tracks-content-area-01 .section-title .tracks-pera {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

.trip-area .content .sub-title {
  color: var(--heading-color);
  font-size: 14px;
  font-weight: 700;
  font-family: var(--body-font);
}
.trip-area .trip-img.bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 450px;
  width: 100%;
}

.map-section {
  background: #1e1528;
}
.map-section.style-01 {
  padding-bottom: 100px;
}
.map-section .bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 500px;
}
.map-section .section-title {
  max-width: 390px;
  margin: auto;
}
.map-section .section-title .audio-pera {
  font-size: 16px;
  color: #fff;
  line-height: 25px;
}
.map-section .section-title span {
  color: rgba(255, 255, 255, 0.8);
}
.map-section .section-title strong {
  color: #fff;
}
.map-section .content {
  margin-top: -385px;
}
.map-section .content .icon {
  font-size: 40px;
  text-align: center;
  color: #da3654;
}

.audio-map-section {
  background-color: #1e1528;
}
.audio-map-section .title {
  color: #fff;
}

.brand-img-area.bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 460px;
}

.get-in-touch-area {
  background-color: #7a5bff;
  position: relative;
  z-index: 0;
}
.get-in-touch-area .bg-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 550px;
  height: 100%;
  background-size: cover;
}
.get-in-touch-area .bg-image-02 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-size: cover;
}
.get-in-touch-area .bg-image-03 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.portfolio-header-section {
  background-color: #7a5bff;
  padding-top: 115px;
  padding-bottom: 100px;
  position: relative;
  z-index: 0;
}
.portfolio-header-section .section-title .title {
  font-size: 42px;
  line-height: 60px;
}
.portfolio-header-section .containerhmg .shape {
  width: 160px;
  height: 210px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 65px;
  top: 30%;
  z-index: -1;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
}
.portfolio-header-section .containerhmg .shape-02 {
  width: 160px;
  height: 140px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 20%;
  z-index: -1;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
}
.portfolio-header-section .containerhmg .shape-02::after {
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #e9a73a;
  position: absolute;
  right: 50px;
  bottom: 0;
  z-index: -2;
}

.organizations-header-section {
  background-image: linear-gradient(50deg, #5e2ced 0%, #9749f8 100%);
}
.organizations-header-section .organizations-header-wrap {
  padding-top: 115px;
  padding-bottom: 120px;
}
.organizations-header-section .organizations-header-wrap.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
}

.creative-team {
  background-color: #f1f4fe;
}

.video-player-inner {
  position: relative;
  z-index: 0;
}
.video-player-inner .play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #000;
}
.video-player-inner .play-icon i {
  width: 60px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  background-color: #111;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.video-play-area {
  position: relative;
  z-index: 0;
}
.video-play-area .containerhmg .shape {
  width: 160px;
  height: 210px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 65px;
  top: 40%;
  z-index: -1;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
}
.video-play-area .containerhmg .shape-02 {
  width: 160px;
  height: 140px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 20%;
  z-index: -1;
  transform-origin: bottom center;
  animation: swing 12s infinite both;
}
.video-play-area .containerhmg .shape-02::after {
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #e9a73a;
  position: absolute;
  right: 50px;
  bottom: 0;
  z-index: -2;
}

.enterprice-ready-area .enterprice-image {
  background-size: contain;
  background-repeat: no-repeat;
  height: 414px;
  border-radius: 10px;
}
.enterprice-ready-area .section-title .title {
  font-size: 42px;
  line-height: 52px;
  font-weight: 600;
  margin-bottom: 25px;
}
.enterprice-ready-area .section-title p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}

/*---------------------------------------
    ## Back Top
---------------------------------------*/

/*-------------------------
    ## Preloader Css
---------------------------*/
.pre-wrap {
  position: fixed;
  content: "";
  transform: translate(-100%, -240%);
  font-size: 62px;
}

.preloader-inner {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preloader-inner div {
  width: 120px;
  height: 120px;
  background: linear-gradient(-45deg, #fb3c7f, #ffc05e);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: rotation 4s ease-in-out infinite;
}
@keyframes rotation {
  0% {
    border-radius: 5% 5% 5% 5%;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  12.5% {
    border-radius: 50% 5% 5% 5%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  25% {
    border-radius: 50% 50% 5% 5%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
  37.5% {
    border-radius: 50% 50% 50% 5%;
    transform: translate(-50%, -50%) rotate(135deg);
  }
  50% {
    border-radius: 50% 50% 50% 50%;
    transform: translate(-50%, -50%) rotate(180deg);
  }
  62.5% {
    border-radius: 5% 50% 50% 50%;
    transform: translate(-50%, -50%) rotate(225deg);
  }
  75% {
    border-radius: 5% 5% 50% 50%;
    transform: translate(-50%, -50%) rotate(270deg);
  }
  87.5% {
    border-radius: 5% 5% 5% 50%;
    transform: translate(-50%, -50%) rotate(315deg);
  }
  100% {
    border-radius: 5% 5% 5% 5%;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.preloader-inner .cancel-preloader {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
.preloader-inner .cancel-preloader a {
  background-color: var(--main-color-one);
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
  width: 200px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 30px;
  display: block;
  transition: all 0.3s ease-in;
}
.preloader-inner .cancel-preloader a:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

/*---------------------------------------
    ## Button
---------------------------------------*/
.btn-wrapper {
  display: block;
}
.btn-wrapper .boxed-btn {
  background-color: var(--secondary-color);
  color: #fff;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-weight: 600;
}
.btn-wrapper .boxed-btn.white:hover {
  background-color: #fff;
  color: var(--main-color-one);
}
.btn-wrapper .boxed-btn.outer-btn {
  border: 1px solid transparent;
}
.btn-wrapper .boxed-btn.outer-btn:hover {
  background-color: transparent;
  color: var(--main-color-one);
  border: 1px solid var(--main-color-one);
}
.btn-wrapper .boxed-btn.blank {
  background-color: transparent;
  border: 1px solid #fff;
}
.btn-wrapper .boxed-btn.blank.reverse-color {
  background-color: transparent;
  border: 2px solid var(--main-color-one);
  color: var(--main-color-one);
}
.btn-wrapper .boxed-btn.blank.reverse-color:hover {
  background-color: var(--main-color-one);
  color: #fff;
  border-color: var(--main-color-one);
}
.btn-wrapper .boxed-btn.blank:hover {
  background-color: var(--secondary-color);
  color: #fff;
  border-color: var(--secondary-color);
}
.btn-wrapper .boxed-btn.audio-btn {
  background-color: #da3654;
}
.btn-wrapper .boxed-btn.audio-btn.style-02:hover {
  border: 1px solid #da3654;
}
.btn-wrapper .boxed-btn.medical-btn {
  background-color: #2b86ff;
  border: none;
}
.btn-wrapper .boxed-btn.medical-btn:hover {
  background-color: #352D66;
  border: none;
}
.btn-wrapper .boxed-btn.learn-btn {
  border-radius: 7px;
  background-image: linear-gradient(140deg, #ED6962 0%, #F9962F 100%);
}
.btn-wrapper .boxed-btn.learn-btn.style-01 {
  border: none;
  padding: 20px 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: transparent;
  background-image: linear-gradient(130deg, #ED6962 0%, #F89630 100%);
}
.btn-wrapper .boxed-btn.learn-btn-02 {
  padding: 18px 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  border-radius: 5px;
  color: #16215C;
  background-color: rgba(237, 105, 98, 0);
  border: 2px solid #D0D3DE;
}
.btn-wrapper .boxed-btn.learn-btn-02:hover {
  color: #FFFFFF;
  background-color: #16215C;
  border: 2px solid #16215C;
}
.btn-wrapper .boxed-btn.btn-gradient {
  background-color: transparent;
  background-image: linear-gradient(270deg, #f73aee 0%, #fd5886 100%);
}
.btn-wrapper .boxed-btn.btn-startup {
  border-radius: 5px;
  background-color: #f8319b;
}
.btn-wrapper .boxed-btn.btn-startup:hover {
  background-color: #0196ff;
}
.btn-wrapper .boxed-btn.btn-startup.reverse-color {
  background-color: #0196ff;
}
.btn-wrapper .boxed-btn.btn-startup.reverse-color:hover {
  background-color: #f8319b;
}
.btn-wrapper .boxed-btn.btn-startup.blank {
  background-color: transparent;
  border: 1px solid #fff;
}
.btn-wrapper .boxed-btn.btn-startup.blank:hover {
  border: 1px solid #f8319b;
  background-color: #f8319b;
}
.btn-wrapper .boxed-btn.btn-startup.style-01 {
  background-color: #7831f3;
}
.btn-wrapper .boxed-btn.btn-startup.style-01:hover {
  background-color: #f7618b;
}
.btn-wrapper .boxed-btn.btn-startup.style-02 {
  background-color: #007bcf;
}
.btn-wrapper .boxed-btn.btn-startup.style-02:hover {
  background-color: #20d2c4;
}
.btn-wrapper .boxed-btn.utility-btn {
  border-radius: 5px;
  background-color: #7931f5;
}
.btn-wrapper .boxed-btn.utility-btn.style-01 {
  background-color: #fff;
  color: #000629;
}
.btn-wrapper .boxed-btn.utility-btn.style-01:hover {
  background-color: #7931f5;
  color: #fff;
}
.btn-wrapper .boxed-btn.btn-saas {
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-saas:hover {
  background-color: #fd6975;
}
.btn-wrapper .boxed-btn.btn-saas.reverse-color {
  background-color: #fd6975;
}
.btn-wrapper .boxed-btn.btn-saas.reverse-color:hover {
  border: 1px solid #930ed8;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-brand {
  background-color: #6327eb;
  border-radius: 0;
}
.btn-wrapper .boxed-btn.btn-brand:hover {
  background-color: #fd6975;
}
.btn-wrapper .boxed-btn.btn-brand.reverse-color {
  background-color: #fd6975;
}
.btn-wrapper .boxed-btn.btn-brand.reverse-color:hover {
  border: 1px solid #930ed8;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-brand.white {
  background-color: #fff;
  color: #6327eb;
}
.btn-wrapper .boxed-btn.btn-brand.white:hover {
  color: #fff;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-food {
  padding: 12px 30px;
  background-color: #fbaf3f;
  border-radius: 10px;
}
.btn-wrapper .boxed-btn.btn-food:hover {
  background-color: #fd6975;
}
.btn-wrapper .boxed-btn.btn-food.reverse-color {
  background-color: #fd6975;
}
.btn-wrapper .boxed-btn.btn-food.reverse-color:hover {
  border: 1px solid #930ed8;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-food.white {
  background-color: #fff;
  color: #6327eb;
}
.btn-wrapper .boxed-btn.btn-food.white:hover {
  color: #fff;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-gym {
  padding: 12px 30px;
  background-color: #ae39ff;
  border-radius: 0;
}
.btn-wrapper .boxed-btn.btn-gym:hover {
  background-color: #fd2b6b;
}
.btn-wrapper .boxed-btn.btn-gym.reverse-color {
  background-color: #fd2b6b;
}
.btn-wrapper .boxed-btn.btn-gym.reverse-color:hover {
  border: 1px solid #930ed8;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-gym.white {
  background-color: #fff;
  color: #6327eb;
}
.btn-wrapper .boxed-btn.btn-gym.white:hover {
  color: #fff;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-finance {
  padding: 14px 20px;
  font-size: 14px;
  background-color: #5fecfe;
  border-radius: 5px;
  color: #0c0765;
}
.btn-wrapper .boxed-btn.btn-finance:hover {
  background-color: #fd2b6b;
}
.btn-wrapper .boxed-btn.btn-finance.reverse-color {
  background-color: #fd6975;
}
.btn-wrapper .boxed-btn.btn-finance.reverse-color:hover {
  border: 1px solid #930ed8;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-finance.white {
  background-color: #fff;
  color: #6327eb;
}
.btn-wrapper .boxed-btn.btn-finance.white:hover {
  color: #fff;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-finance.style-01 {
  background-color: #f5861f;
  border: 2px solid #f5861f;
  color: #fff;
}
.btn-wrapper .boxed-btn.btn-finance.style-01:hover {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}
.btn-wrapper .boxed-btn.btn-business {
  padding: 12px 40px;
  background-color: #ff6661;
  border-radius: 5px;
  color: #fff;
}
.btn-wrapper .boxed-btn.btn-business:hover {
  background-color: #fd2b6b;
}
.btn-wrapper .boxed-btn.btn-business.reverse-color {
  background-color: #fd6975;
}
.btn-wrapper .boxed-btn.btn-business.reverse-color:hover {
  border: 1px solid #930ed8;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-business.white {
  background-color: #fff;
  color: #6327eb;
}
.btn-wrapper .boxed-btn.btn-business.white:hover {
  color: #fff;
  background-color: #930ed8;
}
.btn-wrapper .boxed-btn.btn-business.blank {
  background-color: transparent;
  border: 1px solid #fff;
}
.btn-wrapper .boxed-btn.btn-business.blank:hover {
  border: 1px solid #fd2b6b;
  background-color: #fd2b6b;
}
.btn-wrapper .boxed-btn.btn-business.style-01 {
  background-color: #007bcf;
  padding: 12px 25px;
}
.btn-wrapper .boxed-btn.btn-business.style-01:hover {
  background-color: #7142bf;
}
.btn-wrapper .boxed-btn.btn-business.style-02 {
  position: absolute;
  bottom: -24px;
  background-color: #20d2c4;
  border: none;
  padding: 15px 40px;
}
.btn-wrapper .boxed-btn.btn-business.style-02:hover {
  background-color: #007bcf;
  border: none;
}
.btn-wrapper .boxed-btn.btn-work {
  padding: 15px 40px;
  background-color: #32A8A2;
  border-radius: 10px;
  color: #fff;
}
.btn-wrapper .boxed-btn.btn-work:hover {
  background-color: black;
}
.btn-wrapper .boxed-btn.btn-entertainment {
  background-color: #0f1182;
}
.btn-wrapper .boxed-btn.btn-entertainment:hover {
  color: #0f1182;
  background-color: #ffffff;
}
.btn-wrapper .boxed-btn.btn-entertainment.reverse-color {
  color: #0f1182;
  background-color: #ffffff;
}
.btn-wrapper .boxed-btn.btn-entertainment.reverse-color:hover {
  color: #fff;
  border: 1px solid #0f1182;
  background-color: #0f1182;
}
.btn-wrapper .boxed-btn:hover {
  background-color: var(--secondary-color);
}
.btn-wrapper .boxed-btn.btn-rounded {
  border-radius: 30px;
}
.btn-wrapper .boxed-btn.reverse-color {
  background-color: var(--main-color-two);
}
.btn-wrapper .boxed-btn.reverse-color:hover {
  background-color: var(--main-color-one);
}
.btn-wrapper .read-btn {
  color: var(--main-color-one);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.FirstDiv p{
width: 85%;
padding-left: 10px;
}
.btn-wrapper .login-btn:hover {
  border: 1px solid #fff;
}
.btn-wrapper .login-btn.style-01:hover {
  background-color: #f5861f;
  border: 2px solid #f5861f;
  color: #fff;
}

.close-btn {
  position: absolute;
  right: -15px;
  top: -20px;
  width: 30px;
  height: 30px;
  color: #fff;
  border-color: var(--main-color-one);
  background-color: var(--main-color-one);
  transition: all 0.3s ease-in;
  padding: 0;
  text-align: center;
}

.desktop-center {
  text-align: center;
}

.desktop-left {
  text-align: left;
}

.desktop-right {
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .tablet-center {
    text-align: center;
  }

  .tablet-left {
    text-align: left;
  }

  .tablet-right {
    text-align: right;
  }
  .ThirdDiv{
    margin-left: 0px;
    }
}
@media only screen and (max-width: 414px) {
  .mobile-center {
    text-align: center;
  }

  .mobile-left {
    text-align: left;
  }

  .mobile-right {
    text-align: right;
  }
}
/*-----------------------
    Blog Grid Item
-----------------------*/
.blog-img-thumb {
  display: flex;
}
.blog-img-thumb .thumb-02 {
  margin-left: 30px;
}

.blog-classic-item-01 {
  position: relative;
}
.blog-classic-item-01 .content-wrapper {
  position: absolute;
  left: 25px;
  bottom: 25px;
  width: 315px;
  z-index: 1;
}
.blog-classic-item-01 .content .post-meta {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-classic-item-01 .content .post-meta li {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
}
.blog-classic-item-01 .content .post-meta li:hover a {
  color: var(--main-color-one);
}
.blog-classic-item-01 .content .post-meta li + li {
  margin-left: 20px;
}
.blog-classic-item-01 .content .post-meta li a {
  transition: all 500ms;
}
.blog-classic-item-01 .content .post-meta li a i {
  color: var(--main-color-one);
  transition: all 500ms;
}
.blog-classic-item-01 .content .post-meta .date {
  color: var(--secondary-color);
}
.blog-classic-item-01 .content .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  transition: all 500ms;
}
.blog-classic-item-01 .content .title:hover {
  color: var(--main-color-one);
}
.blog-classic-item-01 .content p {
  line-height: 26px;
}
.blog-classic-item-01 .blog-bottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  background-color: #f7f7f7;
  color: #adadad;
}
.blog-classic-item-01 .blog-bottom .msg {
  transition: all 0.3s ease-in;
}
.blog-classic-item-01 .blog-bottom .msg span {
  color: var(--secondary-color);
  margin-right: 5px;
}
.blog-classic-item-01 .blog-bottom .msg:hover {
  color: var(--secondary-color);
}
.blog-classic-item-01 .blog-bottom .read-btn {
  margin-right: 40px;
  transition: all 0.3s ease-in;
}
.blog-classic-item-01 .blog-bottom .read-btn span {
  position: absolute;
  color: #fff;
  padding-left: 20px;
  bottom: 0;
  right: 0;
  width: 50px;
  line-height: 50px;
  height: 100%;
  display: inline-block;
  background-color: var(--secondary-color);
  -webkit-clip-path: polygon(100% 0%, 100% 74%, 100% 100%, 0 100%, 30% 0, 25% 0%);
          clip-path: polygon(100% 0%, 100% 74%, 100% 100%, 0 100%, 30% 0, 25% 0%);
}
.blog-classic-item-01 .blog-bottom .read-btn:hover {
  color: var(--secondary-color);
}
.blog-classic-item-01 .thumbnail {
  margin-bottom: 25px;
  display: block;
}
.blog-classic-item-01.is-sticky {
  position: relative;
  z-index: 0;
}
.blog-classic-item-01.is-sticky:after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  font-family: "fontawesome";
  font-size: 30px;
  line-height: 30px;
  color: var(--main-color-one);
}
.blog-classic-item-01.format-video .thumbnail {
  position: relative;
  z-index: 0;
}
.blog-classic-item-01.format-video .thumbnail .hover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blog-classic-item-01.format-quote .quote-post-type {
  background-color: #f5f5f5;
  padding: 25px 30px 25px 80px;
  border-radius: 2px;
  position: relative;
  z-index: 0;
}
.blog-classic-item-01.format-quote .quote-post-type:after {
  position: absolute;
  left: 30px;
  top: 20px;
  content: "";
  font-family: "fontawesome";
  font-size: 30px;
  color: var(--main-color-one);
}
.blog-classic-item-01.format-quote .quote-post-type p {
  line-height: 26px;
}
.blog-classic-item-01.format-quote .quote-post-type .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0px;
}

.blog-details-item .blog-details-content {
  background-color: #f3f3f3;
  margin: 0 30px;
  padding: 35px 50px;
  margin-top: -90px;
  position: relative;
  z-index: 0;
}
.blog-details-item .blog-details-item-header {
  display: flex;
}
.blog-details-item .blog-details-item-header .social-link {
  margin-left: 50px;
}
.blog-details-item .title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}
.blog-details-item p {
  line-height: 24px;
}
.blog-details-item blockquote:not([class]) {
  margin: 40px 0;
  padding: 35px 30px 37px 100px;
  border-radius: 3px;
  position: relative;
  background-color: #ebebeb;
  z-index: 0;
}
.blog-details-item blockquote:not([class]) p {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #7e7e7e;
}
.blog-details-item blockquote:not([class]) .icon {
  position: absolute;
  transform: rotateY(180deg);
  left: 35px;
  top: 25px;
  font-size: 35px;
  color: var(--secondary-color);
}
.blog-details-item .post-meta {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 15px;
}
.blog-details-item .post-meta li {
  display: inline-block;
  font-size: 14px;
}
.blog-details-item .post-meta li i {
  color: var(--secondary-color);
}
.blog-details-item .post-meta li + li {
  margin-left: 10px;
}
.blog-details-item .post-meta li a {
  transition: all 0.3s ease-in;
}
.blog-details-item .post-meta li a:hover {
  color: var(--main-color-one);
}
.blog-details-item .post-meta .date a {
  color: var(--secondary-color);
}

.blog-details-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  border-top: 1px solid #f5f5f5;
  padding-top: 25px;
}
.blog-details-footer .title {
  font-size: 16px;
  font-weight: 600;
  color: var(--heading-color);
}
.blog-details-footer .left .tags {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-details-footer .left .tags li {
  display: inline-block;
}
.blog-details-footer .left .tags li i {
  padding-right: 5px;
  color: var(--secondary-color);
}
.blog-details-footer .left .tags li a {
  display: inline-block;
  margin: 5px;
  color: inherit;
  transition: all 0.3s ease-in;
  border-radius: 5px;
  font-size: 14px !important;
  transition: all 0.3s ease-in;
}
.blog-details-footer .left .tags li a:hover {
  color: var(--secondary-color);
}
.blog-details-footer .right .social-share {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-details-footer .right .social-share li {
  display: inline-block;
  transition: all 0.3s ease-in;
}
.blog-details-footer .right .social-share li + li {
  margin-left: 10px;
}
.blog-details-footer .right .social-share li:hover {
  color: var(--main-color-one);
}

.blog-area.style-01 {
  background-color: #100718;
}
.blog-area .section-title .title {
  font-size: 42px;
  color: #fff;
}

/*-----------------------------
    Accordion Item
-----------------------------*/
.accordion-wrapper .carddd {
  border: none;
  background-color: #f4f4f4;
}
.accordion-wrapper .carddd + .carddd {
  margin-top: 20px;
}
.accordion-wrapper .carddd .carddd-header {
  background-color: #f4f4f4;
  padding: 0;
  border: none;
}
.accordion-wrapper .carddd .carddd-header a {
  display: block;
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  background-color: #f4f4f4;
  padding: 14px 30px;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}
.accordion-wrapper .carddd .carddd-header a:after {
  position: absolute;
  left: -10px;
  top: 15px;
  content: "";
  font-family: "fontawesome";
}
.accordion-wrapper .carddd .carddd-header a.white {
  background-color: #f4f4f4;
  font-size: 18px;
  color: #000629;
  padding-right: 25px;
}
.accordion-wrapper .carddd .carddd-header a.white.style-01:after {
  position: absolute;
  left: 0px;
  top: 15px;
  content: "";
  font-family: "fontawesome";
  color: #930ED8;
  font-size: 14px;
}
.accordion-wrapper .carddd .carddd-header a.white.style-01[aria-expanded=false]:after {
  content: "";
}
.accordion-wrapper .carddd .carddd-header a[aria-expanded=false]:after {
  content: "";
}
.accordion-wrapper .carddd .carddd-body {
  background-color: #fff;
  padding: 20px 30px;
}
.accordion-wrapper .carddd .carddd-body.style-01 {
  padding: 40px 40px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
}
.accordion-wrapper .carddd .carddd-body.style-02 {
  background-color: #fff;
  padding: 0px 0 20px;
  line-height: 25px;
}

.accoridions .section-title .title {
  font-size: 42px;
  font-weight: 700;
  color: #000629;
  line-height: 50px;
}

/*-------------------------
    Contact Info Item
-------------------------*/
.contact-area {
  position: relative;
  z-index: 0;
}
.contact-area .bg-image {
  position: absolute;
  left: 0;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 500px;
}
.contact-area .bg-image-02 {
  position: absolute;
  right: 0;
  top: 160px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 340px;
  height: 340px;
  min-height: 150px;
}

.single-contact-item {
  display: flex;
  align-self: flex-start;
  background-color: var(--bg-color-two);
  padding: 15px 0px;
}
.single-contact-item .icon {
  line-height: 60px;
  text-align: center;
  margin-right: 20px;
  font-size: 45px;
  color: var(--secondary-color);
  transition: all 0.3s ease-in;
}
.single-contact-item .content {
  flex: 1;
}
.single-contact-item .content .title {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 10px;
  display: block;
  color: #5e2ced;
}
.single-contact-item .content .details {
  font-size: 14px;
  font-weight: 500;
}

.single-contact-item-02 {
  display: flex;
  align-self: flex-start;
  background-color: var(--bg-color-two);
  padding: 15px 0px;
}
.single-contact-item-02 .icon {
  line-height: 40px;
  text-align: center;
  margin-right: 20px;
  font-size: 30px;
  color: #5e2ced;
  transition: all 0.3s ease-in;
}
.single-contact-item-02 .content {
  flex: 1;
}
.single-contact-item-02 .content .title {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 10px;
  display: block;
  color: #5e2ced;
}
.single-contact-item-02 .content .details {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  color: #000629;
}

.contact-info-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.contact-info-list .single-info-item {
  display: flex;
  align-self: flex-start;
}
.contact-info-list .single-info-item:hover .icon {
  background-color: var(--secondary-color);
}
.contact-info-list .single-info-item + .single-info-item {
  margin-top: 20px;
}
.contact-info-list .single-info-item .icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  margin-right: 20px;
  background-color: var(--main-color-one);
  font-size: 30px;
  color: #fff;
  transition: all 0.3s ease-in;
}
.contact-info-list .single-info-item .content {
  flex: 1;
}
.contact-info-list .single-info-item .content .details {
  display: block;
}

.contact_map {
  height: 100%;
  min-height: 500px;
}

.map {
  min-height: 500px;
  height: 100%;
  background-color: #ddd;
}

.contact-page-form .form-group label {
  color: #000629;
  font-size: 14px;
  font-weight: 600;
}
.contact-page-form .form-group label span {
  color: #ff6661;
}
.contact-page-form .form-group textarea {
  max-height: 160px;
  resize: none;
  width: 100%;
  border-radius: 0;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border: none;
  border-bottom: 1px solid #ebebeb;
  padding: 7px 0px;
}
.contact-page-form .form-group textarea::placeholder {
  color: #b4b4b4;
}
.contact-page-form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ebebeb;
  color: var(--heading-color);
  background-clip: border-box;
  background-color: #fff;
  padding: 7px 0px;
  margin-bottom: 25px;
}
.contact-page-form .form-group .form-control::placeholder {
  color: #b3b3b3;
  font-size: 14px;
}
.contact-page-form .form-group .submit-btn {
  background-image: linear-gradient(50deg, #5e2ced 0%, #9749f8 100%);
  color: #fff;
  display: inline-block;
  border: none;
  padding: 12px 40px;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-weight: 600;
}
.contact-page-form .form-group .submit-btn:hover {
  background-image: linear-gradient(50deg, #9749f8 0%, #5e2ced 100%);
}
.contact-page-form .form-group .submit-btn.style-01 {
  background-image: initial;
  border-radius: 5px;
  background-color: #ff6661;
}
.contact-page-form .form-group .submit-btn.style-01:hover {
  background-color: #fd2b6b;
}
.contact-page-form .btn-wrapper .boxed-btn {
  border: none;
}

/*----------------------------
    Quote Block With Sign
----------------------------*/
.quote-block-with-sign {
  position: relative;
  z-index: 0;
  background-color: var(--secondary-color);
  padding: 50px 40px 60px 40px;
}
.quote-block-with-sign.radius-right {
  border-bottom-right-radius: 60px;
}
.quote-block-with-sign:after {
  position: absolute;
  left: 20px;
  top: 30px;
  font-size: 100px;
  line-height: 100px;
  color: #706d5f;
  font-weight: 900;
  font-family: "fontawesome";
  content: "";
  z-index: -1;
}
.quote-block-with-sign p {
  font-size: 28px;
  line-height: 43px;
  font-style: italic;
  font-family: var(--heading-font);
  color: #fff;
}
.quote-block-with-sign .sign {
  margin-top: 26px;
}

/*----------------------------
    Counterup Item
----------------------------*/
.counter-item-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.counter-item-list li {
  width: calc(100% / 3);
}
.counter-item-list li + li {
  border-left: 1px solid #a8aab1;
}

.single-counterup-block-with-img {
  display: inline-block;
}
.single-counterup-block-with-img .thumb {
  position: relative;
  z-index: 0;
}
.single-counterup-block-with-img .thumb img {
  border-bottom-right-radius: 50px;
}
.single-counterup-block-with-img .thumb .hover {
  position: absolute;
  left: 5%;
  top: 5%;
  width: 90%;
  background-color: #fff;
  height: 90%;
  border-bottom-right-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.single-counterup-block-with-img .thumb .hover .title {
  font-size: 18px;
  line-height: 28px;
  font-family: var(--body-font);
}
.single-counterup-block-with-img .thumb .hover .count-num {
  font-size: 120px;
  line-height: 130px;
  color: var(--main-color-one);
  font-family: var(--heading-font);
  font-weight: 700;
}

.single-counterup-01 {
  text-align: center;
}
.single-counterup-01.white .content .title {
  color: #000629;
  margin-top: 15px;
}
.single-counterup-01.white .content .count-wrap {
  color: #f5861f;
}
.single-counterup-01 .content .title {
  font-size: 25px;
  line-height: 28px;
  color: #000629;
  font-family: var(--body-font);
}
.single-counterup-01 .content .count-wrap {
  font-size: 30px;
  line-height: 58px;
  font-weight: 700;
  color: #000629;
  font-family: var(--heading-font);
  margin-bottom: 0;
}

/*-------------------------
    Video With Image
-------------------------*/
.video-with-image .img-wrapper {
  position: relative;
  z-index: 0;
  display: inline-block;
}
.video-with-image .img-wrapper img {
  width: 100%;
}
.video-with-image .img-wrapper .hover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*--------------------------
    Video Play Button
--------------------------*/
.video-play-btn {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 16px;
  background-color: #fff;
  border-radius: 50%;
  color: var(--main-color-one);
}
.video-play-btn:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  z-index: -1;
}
.video-play-btn.style-01 {
  background-color: var(--main-color-one);
  color: #fff;
}
.video-play-btn.style-01::before {
  background-color: var(--main-color-one);
}
.video-play-btn.style-01:focus, .video-play-btn.style-01:hover {
  color: #fff;
}

.video-play-btn:hover,
.video-play-btn:focus {
  color: var(--main-color-one);
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0;
  }
}
/*-------------------------
    Section Title
-------------------------*/
.section-title-inner.white .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.section-title-inner.white .title {
  color: #fff;
}
.section-title-inner.white p {
  color: rgba(255, 255, 255, 0.7);
}
.section-title-inner .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--heading-font);
  margin-bottom: 18px;
}
.section-title-inner .title span {
  color: var(--main-color-one);
}
.section-title-inner .subtitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  z-index: 0;
  padding-left: 80px;
  margin-bottom: 8px;
}
.section-title-inner .subtitle:after {
  position: absolute;
  left: 0;
  top: 10px;
  height: 2px;
  width: 60px;
  background-color: var(--main-color-one);
  content: "";
}
.section-title.white .title {
  color: #fff;
}
.section-title.white p {
  color: rgba(255, 255, 255, 0.7);
}
.section-title.white .section-para {
  color: rgba(255, 255, 255, 0.7);
}
.section-title.white span {
  color: #fff;
}
.section-title.sass span {
  color: #6600f2;
  font-weight: 700;
}
.section-title.sass .title {
  font-size: 48px;
  line-height: 58px;
  font-weight: 500;
  color: #16215c;
}
.section-title.startup .title {
  font-size: 42px;
  line-height: 52px;
  font-weight: 500;
  color: #16215c;
}
.section-title.startup.white .title {
  color: #fff;
}
.section-title.brand .title {
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
  color: #16215c;
}
.section-title.brand span {
  display: inline-block;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 40px;
  color: #fff;
  background-color: #6327eb;
  transition: all 500ms ease-in;
}
.section-title.brand span:hover {
  color: #fff;
  background-color: var(--secondary-color);
}
.section-title.brand p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  margin-top: 20px;
}
.section-title.brand.white .title {
  color: #fff;
}
.section-title.brand.white span {
  color: #333;
  background-color: #fff;
}
.section-title.brand.white span:hover {
  color: #fff;
  background-color: var(--secondary-color);
}
.section-title.brand.white p {
  color: #fff;
}
.section-title.restaurant .title {
  font-size: 80px;
  line-height: 90px;
  font-weight: 700;
  color: #fbaf3f;
}
.section-title.gym .title {
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
  color: #000629;
}
.section-title.gym p {
  font-size: 18px;
}
.section-title.gym.white .title {
  color: #fff;
}
.section-title.gym.white span {
  color: #333;
  background-color: #fff;
}
.section-title.gym.white span:hover {
  color: #fff;
  background-color: var(--secondary-color);
}
.section-title .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 14px;
}
.section-title .title.social-title {
  font-size: 36px;
  line-height: 46px;
}
.section-title .title span {
  position: relative;
  z-index: 0;
}
.section-title .title span::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 8px;
  left: 0;
  background-color: var(--main-color-two);
  z-index: -1;
}
.section-title .social-span {
  display: inline-block;
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  background-image: linear-gradient(150deg, #8858fb 0%, #4ad7f4 100%);
}
.section-title .sub-title {
  font-size: 14px;
  font-weight: 600;
  font-family: var(--heading-font);
  color: var(--secondary-color);
}
.section-title p {
  font-size: 14px;
  font-weight: 500;
}
.section-title .section-para {
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
}

.bg-litered {
  background-color: var(--main-color-one);
}

.bg-white {
  background-color: #fff;
}

.bg-blue {
  background-color: var(--main-color-two);
}

/*-------------------------
    Icon Box Item
-------------------------*/
.single-icon-box-01 {
  background-color: #fff;
  text-align: center;
  padding: 30px;
  position: relative;
  z-index: 0;
  transition: all 0.3s;
  overflow: hidden;
}
.single-icon-box-01:hover .msg-icon, .single-icon-box-01.active .msg-icon {
  opacity: 1;
  animation: zoomInUp 0.3s;
}
.single-icon-box-01:hover .icon, .single-icon-box-01.active .icon {
  transform: rotate(360deg);
  background-image: linear-gradient(300deg, #8a54fb 0%, #49d9f4 99%);
}
.single-icon-box-01 .icon {
  width: 90px;
  height: 90px;
  text-align: center;
  margin: auto;
  border-radius: 50%;
  font-size: 40px;
  line-height: 90px;
  transition: all 0.3s;
  color: #fff;
  background-image: linear-gradient(300deg, #49d9f4 0%, #8a54fb 99%);
  margin-bottom: 18px;
}
.single-icon-box-01 .msg-icon {
  transition: all 0.3s;
  opacity: 0;
  position: absolute;
  width: 42px;
  height: 35px;
  left: 46%;
  top: 0;
  transform: translateX(-50%);
  line-height: 35px;
  text-align: center;
  color: #fff;
  border-radius: 5px 5px 0 5px;
  background-image: linear-gradient(300deg, #f738f5 0%, #fd5980 99%);
  z-index: 1;
}
.single-icon-box-01 .msg-icon:after {
  content: "";
  position: absolute;
  right: 0;
  top: 35px;
  border-top: 10px solid #f738f5;
  border-left: 10px solid transparent;
}
.single-icon-box-01 .msg-icon span {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #fff;
  animation: dotBlink 1.4s infinite both;
}
.single-icon-box-01 .msg-icon span:nth-child(2) {
  animation-delay: 0.2s;
}
.single-icon-box-01 .msg-icon span:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes dotBlink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.single-icon-box-01 .content .title {
  font-size: 22px;
  line-height: 34px;
  font-weight: 700;
}
.single-icon-box-01 .content p {
  transition: all 0.5s;
}

.single-icon-box-02 {
  display: flex;
  align-self: flex-start;
  background-color: #fff;
  padding: 20px 20px 20px 20px;
}
.single-icon-box-02:hover .icon i {
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: rotateY(360deg);
}
.single-icon-box-02:hover .content .title {
  color: var(--main-color-one);
}
.single-icon-box-02 .icon {
  margin-right: 20px;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  background-color: var(--main-color-one);
  color: #fff;
  font-size: 40px;
  transition: all 500ms;
}
.single-icon-box-02 .icon i {
  transition: all 500ms;
  display: inline-block;
}
.single-icon-box-02 .content {
  flex: 1;
  padding-top: 5px;
}
.single-icon-box-02 .content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  transition: all 500ms;
}
.single-icon-box-03 {
  background-color: #fff;
  text-align: center;
  position: relative;
  z-index: 0;
  padding: 0 30px;
  transition: all 0.3s;
  overflow: hidden;
  margin-bottom: 30px;
}
.single-icon-box-03:hover .icon {
  line-height: 80px;
  font-size: 40px;
  text-align: center;
  color: #fff;
  transform: rotate(0);
  margin-bottom: 30px;
}
.single-icon-box-03:hover .icon::after {
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.single-icon-box-03 .icon {
  text-align: center;
  margin: auto;
  font-size: 70px;
  line-height: 80px;
  transition: all 0.3s;
  transform: rotate(-30deg);
  color: #000000;
  display: inline-block;
  margin-bottom: 30px;
  position: relative;
  z-index: 0;
}
.single-icon-box-03 .icon:after {
  content: "";
  position: absolute;
  left: 15px;
  bottom: -25px;
  width: 55px;
  height: 55px;
  transition: all 0.3s;
  background-color: #ffe477;
  border-radius: 50%;
  z-index: -1;
}
.single-icon-box-03 .icon.style-01:after {
  background-color: #0068fe;
}
.single-icon-box-03 .icon.style-02:after {
  background-color: #0068fe;
}
.single-icon-box-03 .content .title {
  font-size: 22px;
  line-height: 34px;
  font-weight: 700;
}
.single-icon-box-03 .content p {
  transition: all 0.5s;
}

.single-icon-box-04 {
  background-color: #fff;
  text-align: center;
  position: relative;
  z-index: 0;
  padding: 30px 30px 25px;
  transition: all 0.3s;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 0px 0px 103px 0px rgba(93, 136, 250, 0.5);
  border-radius: 10px;
}
.single-icon-box-04:hover {
  box-shadow: 0px 0px 13px 0px rgba(93, 136, 250, 0.5);
}
.single-icon-box-04:hover .icon {
  line-height: 80px;
  font-size: 40px;
  text-align: center;
  transform: rotate(0);
}
.single-icon-box-04 .icon {
  text-align: center;
  margin: auto;
  font-size: 60px;
  line-height: 80px;
  transition: all 0.3s;
  transform: rotate(-30deg);
  color: #f7618b;
  display: inline-block;
  position: relative;
  z-index: 0;
}
.single-icon-box-04 .icon.style-01 {
  color: #5edcd8;
}
.single-icon-box-04 .icon.style-02 {
  color: #7048c0;
}
.single-icon-box-04 .content .title {
  font-size: 22px;
  line-height: 34px;
  font-weight: 700;
}
.single-icon-box-04 .content p {
  transition: all 0.5s;
}

.single-icon-box-05 {
  background-color: #fff;
  text-align: center;
  position: relative;
  z-index: 0;
  padding: 70px 30px 65px;
  transition: all 0.3s;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 0px 0px 103px 0px rgba(93, 136, 250, 0.5);
  border-radius: 10px;
}
.single-icon-box-05:hover {
  box-shadow: 0px 0px 13px 0px rgba(93, 136, 250, 0.5);
}
.single-icon-box-05:hover .icon {
  line-height: 60px;
  font-size: 40px;
  text-align: center;
}
.single-icon-box-05 .icon {
  text-align: center;
  margin: auto;
  font-size: 30px;
  line-height: 40px;
  transition: all 0.3s;
  color: #7638f2;
  display: inline-block;
  position: relative;
  z-index: 0;
}
.single-icon-box-05 .content .title {
  font-size: 22px;
  line-height: 34px;
  font-weight: 700;
}
.single-icon-box-05 .content p {
  transition: all 0.5s;
}

.single-icon-box-06 {
  text-align: center;
}
.single-icon-box-06 .icon {
  font-size: 70px;
  color: #bdc1c9;
}
.single-icon-box-06 .title {
  margin: auto;
  font-size: 18px;
  line-height: 28px;
  max-width: 200px;
}

.single-icon-box-07 {
  text-align: center;
}
.single-icon-box-07 .icon {
  font-size: 70px;
  color: #fff;
}
.single-icon-box-07 .icon .music-img {
  margin-top: -25px;
}
.single-icon-box-07 .title {
  margin: auto;
  font-size: 18px;
  line-height: 28px;
  max-width: 140px;
  color: #fff;
  font-weight: normal;
}

.single-icon-box-08 {
  background-color: transparent;
  text-align: center;
  padding: 30px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: all 0.3s;
}
.single-icon-box-08:hover .icon::after {
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 70% 30% 36% 64%/69% 45% 55% 31%;
  z-index: -999;
}
.single-icon-box-08 .icon {
  background-color: #120428;
  height: 99px;
  width: 100px;
  text-align: center;
  margin: 0 auto;
  border-radius: 70% 30% 36% 64%/69% 45% 55% 31%;
  font-size: 45px;
  line-height: 100px;
  color: #da3654;
  margin-bottom: 28px;
  position: relative;
  z-index: -1;
  overflow: hidden;
}
.single-icon-box-08 .icon::after {
  content: "";
  position: absolute;
  width: 38px;
  height: 38px;
  background-color: #4f00ad;
  border-radius: 50%;
  top: 73px;
  left: 60px;
  z-index: 0;
  transition: all 0.3s;
}
.single-icon-box-08 .icon .shape {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: -15px;
}
.single-icon-box-08 .content .title {
  font-size: 22px;
  color: #fff;
  margin-bottom: 15px;
}
.single-icon-box-08 .content .audio-pera {
  font-size: 16px;
  padding: 0 18px;
  color: #c6c6e0;
}

.single-icon-box-09 {
  display: flex;
  background-color: #fff;
  position: relative;
  z-index: 0;
  padding: 30px 30px 25px;
  transition: all 0.3s;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 0 0 103px 0 rgba(93, 136, 250, 0.5);
  border-radius: 10px;
}
.single-icon-box-09:hover {
  box-shadow: 0px 0px 13px 0px rgba(93, 136, 250, 0.5);
}
.single-icon-box-09:hover .icon {
  line-height: 80px;
  font-size: 40px;
  text-align: center;
  transform: rotate(0);
}
.single-icon-box-09 .icon {
  text-align: center;
  margin: auto;
  font-size: 60px;
  line-height: 80px;
  transition: all 0.3s;
  color: #f5aa67;
  display: inline-block;
  position: relative;
  margin-left: 20px;
  z-index: 0;
}
.single-icon-box-09 .content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}
.single-icon-box-09 .content p {
  transition: all 0.5s;
  font-size: 16px;
}

.social-link ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.social-link ul li {
  color: #fff;
  display: inline-block;
  font-size: 15px;
  transition: all 0.3s ease-in;
}
.social-link ul li + li {
  margin-left: 10px;
}
.social-link ul li:hover {
  color: var(--secondary-color);
}
.social-link.style-01 ul li {
  display: block;
}
.social-link.style-01 ul li + li {
  margin-left: 0;
}
.social-link.style-01 ul li:hover {
  color: var(--main-color-one);
}
.social-link.style-02 ul li {
  font-size: 15px;
  display: inline-block;
  color: #000000;
}
.social-link.style-02 ul li + li {
  margin-left: 5px;
}

/*----------------------------------------
  ## Search Popup
----------------------------------------*/
.search-popup.active .search-form {
  visibility: visible;
  opacity: 1;
}

.search-popup .search-form {
  min-width: 415px;
  /* position: absolute; */
  position: fixed;
  top: 40px;
  right: calc(8% + -6px);
  transition: 0.5s ease;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}

.search-popup .search-form .form-group .form-control {
  border: none;
  border-radius: 0px;
  height: 45px;
  padding: 0 100px 0 30px;
}

.search-popup .search-form .submit-btn {
  position: absolute;
  right: -15px;
  top: -20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-color: var(--hover-color);
  background-color: var(--hover-color);
  transition: all 0.3s ease-in;
  padding: 0;
  text-align: center;
}
.search-popup .search-form .submit-btn span {
  color: #fff;
  font-size: 20px;
}

.body-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(42, 40, 29, 0.54);
  z-index: 9;
  content: "";
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
}

.body-overlay.active {
  visibility: visible;
  opacity: 0.8;
}

.click-mobile-search {
  display: none;
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 5;
  width: 100%;
  padding: 20px;
  background: #fff;
}

/*--------------------------------
    Case Study 
--------------------------------*/
.recent-case-study-filter-nav {
  text-align: center;
}
.recent-case-study-filter-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 28px;
}
.recent-case-study-filter-nav ul li {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.recent-case-study-filter-nav ul li.active {
  color: var(--main-color-one);
}
.recent-case-study-filter-nav ul li + li {
  margin-left: 20px;
}

.single-recent-case-study-01:hover .img-wrapper .hover .title {
  color: var(--main-color-one);
}
.single-recent-case-study-01 .img-wrapper {
  position: relative;
  z-index: 0;
  margin-bottom: 60px;
}
.single-recent-case-study-01 .img-wrapper img {
  width: 100%;
}
.single-recent-case-study-01 .img-wrapper .hover {
  background-color: #fff;
  text-align: center;
  position: absolute;
  left: 5%;
  bottom: -30px;
  width: 90%;
  padding: 25px 30px 28px 30px;
  box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.single-recent-case-study-01 .img-wrapper .hover .title {
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;
  transition: all 500ms;
}
.single-recent-case-study-01 .img-wrapper .hover .read-more {
  font-size: 14px;
  font-weight: 600;
  transition: all 500ms;
}
.single-recent-case-study-01 .img-wrapper .hover .read-more:hover {
  color: var(--main-color-one);
}
.single-recent-case-study-01 .img-wrapper .hover .read-more:hover i {
  visibility: visible;
  opacity: 1;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: translateX(0px);
}
.single-recent-case-study-01 .img-wrapper .hover .read-more i {
  visibility: hidden;
  opacity: 0;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: translateX(-50px);
  transition: all 500ms;
}

.recent-case-filter-03 {
  margin-bottom: 25px;
}

.recent-case-filter-02 .recent-case-filter-menu,
.recent-case-filter-03 .recent-case-filter-menu {
  text-align: center;
}
.recent-case-filter-02 .recent-case-filter-menu ul,
.recent-case-filter-03 .recent-case-filter-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 28px;
}
.recent-case-filter-02 .recent-case-filter-menu ul li,
.recent-case-filter-03 .recent-case-filter-menu ul li {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.recent-case-filter-02 .recent-case-filter-menu ul li.active,
.recent-case-filter-03 .recent-case-filter-menu ul li.active {
  color: var(--main-color-one);
}
.recent-case-filter-02 .recent-case-filter-menu ul li + li,
.recent-case-filter-03 .recent-case-filter-menu ul li + li {
  margin-left: 20px;
}

.single-case-item-02 {
  display: block;
}
.single-case-item-02:hover .thumb .hover {
  visibility: visible;
  opacity: 1;
}
.single-case-item-02 .thumb {
  position: relative;
  z-index: 0;
}
.single-case-item-02 .thumb img {
  width: 100%;
}
.single-case-item-02 .thumb .hover {
  position: absolute;
  left: 5%;
  top: 5%;
  width: 90%;
  background-color: #fff;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all 500ms;
}
.single-case-item-02 .thumb .hover .title {
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;
  transition: all 500ms;
}
.single-case-item-02 .thumb .hover .title:hover {
  color: var(--main-color-one);
}
.single-case-item-02 .thumb .hover .cats a {
  transition: all 500ms;
}
.single-case-item-02 .thumb .hover .cats a:hover {
  color: var(--main-color-one);
}

.case-masonry {
  width: 20%;
}

.case-masonry-03 {
  margin-bottom: 30px;
}

/*---------------------------
    Price Plan
---------------------------*/
.single-price-plan-01 {
  padding: 40px 0 45px 0;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #ece3fb;
  transition: all 0.3s ease-in;
}
.single-price-plan-01:hover, .single-price-plan-01.active {
  border: 5px solid var(--secondary-color);
  box-shadow: 0px 14px 103px 0px rgba(0, 0, 0, 0.1);
}
.single-price-plan-01 .price-header {
  text-align: center;
}
.single-price-plan-01 .price-header .title {
  margin-bottom: 30px;
  font-size: 24px;
  color: var(--heading-color);
  line-height: 34px;
  font-weight: 700;
}
.single-price-plan-01 .price-header .info-text {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 24px;
}
.single-price-plan-01 .price-body {
  margin-top: 5px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}
.single-price-plan-01 .price-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.single-price-plan-01 .price-body ul li + li {
  margin-top: 15px;
}
.single-price-plan-01 .price-body ul li i {
  color: #51d55f;
}
.single-price-plan-01 .price-wrap {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 13px;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: var(--heading-color);
}
.single-price-plan-01 .price-wrap .month {
  color: #30323d;
  font-size: 14px;
  font-weight: 300;
}
.single-price-plan-01 .price-footer {
  text-align: center;
}
.single-price-plan-01 .price-footer .btn-wrapper .boxed-btn {
  background-image: linear-gradient(-90deg, #f738f5 0%, #fd5980 100%);
  padding: 12px 40px;
}
.single-price-plan-01 .price-footer .btn-wrapper .boxed-btn:hover {
  background-image: linear-gradient(90deg, #f738f5 0%, #fd5980 100%);
}

.single-price-plan-02 {
  padding: 40px 0 45px 0;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #ece3fb;
  transition: all 0.3s ease-in;
  text-align: center;
}
.single-price-plan-02:hover, .single-price-plan-02.active {
  box-shadow: 0px 0px 136px 0px rgba(112, 72, 192, 0.47);
}
.single-price-plan-02 .price-header {
  text-align: center;
}
.single-price-plan-02 .price-header .title {
  font-size: 24px;
  color: var(--heading-color);
  line-height: 34px;
  font-weight: 500;
}
.single-price-plan-02 .price-header .info-text {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 24px;
}
.single-price-plan-02 .price-body {
  margin-top: 5px;
  margin-bottom: 25px;
}
.single-price-plan-02 .price-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.single-price-plan-02 .price-body ul li + li {
  margin-top: 15px;
}
.single-price-plan-02 .price-wrap {
  margin-bottom: 13px;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #f7618b;
}
.single-price-plan-02 .price-wrap .month {
  color: #141f4e;
  font-size: 18px;
  font-weight: 700;
}
.single-price-plan-02 .price-footer {
  text-align: center;
}
.single-price-plan-02 .price-footer .btn-wrapper .boxed-btn {
  background-color: #7831f3;
  padding: 12px 60px;
}
.single-price-plan-02 .price-footer .btn-wrapper .boxed-btn:hover {
  background-color: #7831f3;
}

/*------------------------------
    Testimonial Item
-----------------------------*/
.single-testimonial-item {
  display: flex;
  align-self: flex-start;
  position: relative;
}
.single-testimonial-item .thumb.bg-image {
  min-width: 60px;
  border-radius: 50%;
  border: 3px solid #fff;
  height: 60px;
  margin-right: 15px;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}
.single-testimonial-item .content {
  padding: 35px 35px;
  background-color: #fff;
  border-radius: 0 10px 10px 10px;
}
.single-testimonial-item .content .description {
  font-size: 15px;
  line-height: 25px;
  color: #403949;
  font-weight: 400;
}
.single-testimonial-item .content .author-details {
  display: flex;
  align-self: flex-start;
}
.single-testimonial-item .content .author-details .author-meta {
  flex: 1;
  margin-top: 15px;
}
.single-testimonial-item .content .author-details .author-meta .title {
  font-size: 12px;
  text-transform: uppercase;
  font-family: var(--body-font);
  color: var(--heading-color);
  font-weight: 700;
  margin-bottom: 0;
}

.single-testimonial-item-02 .content {
  flex: 1;
  position: relative;
}
.single-testimonial-item-02 .content .thumb {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: auto;
  margin-top: 30px;
  border: 5px solid #fff;
}
.single-testimonial-item-02 .content .thumb img {
  border-radius: 50%;
}
.single-testimonial-item-02 .content .description {
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  margin: auto;
}
.single-testimonial-item-02 .content .author-details {
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
}
.single-testimonial-item-02 .content .author-details .author-meta {
  flex: 1;
  margin-top: 15px;
  text-align: center;
}
.single-testimonial-item-02 .content .author-details .author-meta .title {
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--body-font);
  color: #fff;
}
.single-testimonial-item-02 .content .author-details .author-meta .designation {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.single-testimonial-item-03 {
  display: flex;
}
.single-testimonial-item-03 .content {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}
.single-testimonial-item-03 .content .icon {
  font-size: 60px;
  color: #4b3be1;
}
.single-testimonial-item-03 .content .description {
  font-size: 24px;
  line-height: 34px;
  max-width: 450px;
  color: #403949;
}
.single-testimonial-item-03 .content .author-details {
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
}
.single-testimonial-item-03 .content .author-details .author-meta {
  flex: 1;
  margin-top: 15px;
}
.single-testimonial-item-03 .content .author-details .author-meta .title {
  display: inline-block;
  color: #f8319b;
  background-color: #feeaf5;
  border-radius: 5px;
  padding: 15px 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
}
.single-testimonial-item-03 .thumb {
  position: relative;
  padding: 0 50px;
  z-index: 0;
}
.single-testimonial-item-03 .thumb:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 60%;
  background-color: #fee7d0;
  z-index: -1;
  border-radius: 50px;
}

.single-testimonial-item-04 .content {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}
.single-testimonial-item-04 .content .icon {
  font-size: 60px;
  color: #4b3be1;
}
.single-testimonial-item-04 .content .description {
  font-size: 18px;
  line-height: 28px;
  max-width: 450px;
  color: #727777;
}
.single-testimonial-item-04 .content .author-details {
  display: flex;
  align-self: flex-start;
  margin-top: 20px;
}
.single-testimonial-item-04 .content .author-details .author-meta {
  flex: 1;
  margin-top: 10px;
  margin-left: 20px;
}
.single-testimonial-item-04 .content .author-details .author-meta .title {
  display: inline-block;
  color: #16215c;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.single-testimonial-item-04 .content .author-details .author-meta .designation {
  color: #727777;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.single-testimonial-item-04 .thumb {
  position: relative;
  z-index: 0;
  width: 60px;
}
.single-testimonial-item-05 {
  margin-top: 50px;
  padding: 50px 60px 50px;
  position: relative;
  background-color: #fff;
  border: 1px solid #ece3fb;
}
.single-testimonial-item-05 .icon {
  position: absolute;
  font-size: 40px;
  color: #930ed8;
  top: -30px;
  right: 50px;
  transform: rotate(180deg);
}
.single-testimonial-item-05 .icon.style-01 {
  color: #23A8D9;
}
.single-testimonial-item-05 .thumb {
  position: absolute;
  top: -30px;
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.15);
  width: 60px;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-bottom: 20px;
}
.single-testimonial-item-05 .content {
  border-radius: 0 10px 10px 10px;
}
.single-testimonial-item-05 .content .description {
  font-size: 18px;
  line-height: 28px;
  color: #403949;
  font-weight: 400;
}
.single-testimonial-item-05 .content .author-details {
  display: flex;
  align-self: flex-start;
  margin-bottom: 20px;
}
.single-testimonial-item-05 .content .author-details .author-meta {
  flex: 1;
  margin-top: 15px;
}
.single-testimonial-item-05 .content .author-details .author-meta .title {
  font-size: 12px;
  text-transform: uppercase;
  font-family: var(--body-font);
  color: var(--heading-color);
  font-weight: 700;
  margin-bottom: 0;
}

.single-testimonial-item-06 {
  display: flex;
}
.single-testimonial-item-06 .content {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}
.single-testimonial-item-06 .content .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: -1;
}
.single-testimonial-item-06 .content .icon {
  font-size: 60px;
  line-height: 60px;
  color: #ffe477;
  transform: rotate(180deg);
  position: absolute;
  top: 0;
  left: 180px;
}
.single-testimonial-item-06 .content .description {
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  padding: 85px 180px;
}
.single-testimonial-item-06 .content .author-details {
  margin-top: 10px;
}
.single-testimonial-item-06 .content .author-details .author-meta {
  position: absolute;
  left: -50%;
  transform: translateX(50%);
  bottom: 30px;
  display: flex;
  align-self: flex-start;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0px 0px 84px 0px rgba(42, 40, 46, 0.75);
  margin-top: 15px;
}
.single-testimonial-item-06 .content .author-details .author-meta .title {
  margin-bottom: 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
}
.single-testimonial-item-07 {
  padding: 30px 40px 30px;
  position: relative;
  background-color: #fff;
}
.single-testimonial-item-07.style-01 {
  border: 1px solid #ff7364;
}
.single-testimonial-item-07 .icon {
  font-size: 40px;
  color: #ff7364;
  margin-bottom: 20px;
}
.single-testimonial-item-07 .icon.style-01 {
  color: #6ceefe;
}
.single-testimonial-item-07 .content {
  border-radius: 0 10px 10px 10px;
}
.single-testimonial-item-07 .content .description {
  font-size: 24px;
  line-height: 34px;
  color: #000629;
  font-weight: 400;
}
.single-testimonial-item-07 .content .author-details {
  margin-top: 20px;
  margin-bottom: 20px;
}
.single-testimonial-item-07 .content .author-details .author-meta {
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  flex: 1;
}
.single-testimonial-item-07 .content .author-details .author-meta .title {
  font-size: 16px;
  line-height: 24px;
  font-family: var(--body-font);
  color: var(--heading-color);
  font-weight: 700;
  margin-bottom: 0;
}

.single-testimonial-item-08 {
  display: flex;
}
.single-testimonial-item-08 .content {
  flex: 1;
  display: flex;
  align-items: center;
  background-color: #f4f7fe;
  border-radius: 5px;
  padding: 60px 80px 55px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.single-testimonial-item-08 .content .icon {
  font-size: 40px;
  color: #9738f6;
}
.single-testimonial-item-08 .content .description {
  font-size: 24px;
  line-height: 34px;
  max-width: 350px;
  color: #403949;
}
.single-testimonial-item-08 .content .author-details {
  margin-top: 10px;
}
.single-testimonial-item-08 .content .author-details .author-meta {
  display: flex;
  margin-top: 15px;
}
.single-testimonial-item-08 .content .author-details .author-meta .title {
  color: #000629;
  margin-bottom: 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
}
.single-testimonial-item-09 {
  display: flex;
}
.single-testimonial-item-09 .content-wrap {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 30px;
}
.single-testimonial-item-09 .content .icon {
  font-size: 60px;
  line-height: 60px;
  color: #e94243;
  margin-bottom: 20px;
}
.single-testimonial-item-09 .content .description {
  font-size: 24px;
  line-height: 34px;
  color: #000629;
}
.single-testimonial-item-09 .content .author-details {
  margin-top: 10px;
}
.single-testimonial-item-09 .content .author-details .author-meta {
  display: flex;
  align-self: flex-start;
  margin-top: 15px;
}
.single-testimonial-item-09 .content .author-details .author-meta .title {
  margin-bottom: 0;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #000629;
}
.single-testimonial-item-09 .content .author-details .author-meta .designation {
  margin-left: 10px;
}
.single-testimonial-item-10 {
  display: flex;
}
.single-testimonial-item-10 .content-wrap {
  display: flex;
  align-items: center;
  flex: 1;
}
.single-testimonial-item-10 .content-wrap .icon {
  font-size: 160px;
  color: #f5861f;
  margin-left: -40px;
  margin-right: 40px;
}
.single-testimonial-item-10 .content .description {
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  padding-right: 16px;
  font-weight: 400;
}
.single-testimonial-item-10 .content .author-details {
  margin-top: 10px;
}
.single-testimonial-item-10 .content .author-details .author-meta {
  display: flex;
  align-self: flex-start;
  margin-top: 15px;
}
.single-testimonial-item-10 .content .author-details .author-meta .title {
  margin-bottom: 0;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
}
.single-testimonial-item-10 .content .author-details .author-meta .designation {
  font-size: 12px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.9);
}
.single-testimonial-item-10 .thumb img {
  width: 100%;
  max-height: 330px;
  border-radius: 10px;
}

.single-testimonial-item-11 {
  background-color: #2b86ff;
  padding: 50px 40px 50px 40px;
  position: relative;
  margin-top: 40px;
}
.single-testimonial-item-11 .icon {
  position: absolute;
  top: -25px;
  font-size: 50px;
  line-height: 50px;
  left: 40px;
  color: #f1f1f1;
}
.single-testimonial-item-11 .content .description {
  font-size: 24px;
  line-height: 40px;
  max-width: 450px;
  color: #fff;
}
.single-testimonial-item-11 .content .author-details .author-meta .title {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}
.single-testimonial-item-11 .thumb {
  position: relative;
  padding: 0 50px;
  z-index: 0;
}
.single-testimonial-item-11 .thumb:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 60%;
  background-color: #fee7d0;
  z-index: -1;
  border-radius: 50px;
}

.single-testimonial-12 {
  display: flex;
  align-items: center;
}
.single-testimonial-12 .thumb {
  margin-right: 20px;
}
.single-testimonial-12 .thumb img {
  max-width: 270px;
  height: auto;
}
.single-testimonial-12 .content {
  text-align: center;
  flex: 1;
}
.single-testimonial-12 .content .description {
  font-size: 24px;
  color: #16215c;
  line-height: 34px;
  font-weight: 600;
}
.single-testimonial-12 .content .author-meta .title {
  font-size: 14px;
  color: #727777;
}

/*----------------------------
    Blog Grid Item
-----------------------------*/
.blog-grid-carousel .owl-nav div {
  position: absolute;
  left: -60px;
  top: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: var(--secondary-color);
  color: #fff;
  transition: all 0.3s ease-in;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
}
.blog-grid-carousel .owl-nav div:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.blog-grid-carousel .owl-nav div.owl-next {
  left: auto;
  right: -60px;
}

.single-blog-grid-01 {
  position: relative;
  z-index: 0;
}
.single-blog-grid-01 .thumb {
  position: relative;
  z-index: 0;
  border-radius: 10px;
}
.single-blog-grid-01 .thumb img {
  border-radius: 10px;
  width: 100%;
}
.single-blog-grid-01 .thumb .news-date {
  padding: 8px 20px;
  border-radius: 20px;
  background-color: #ffe477;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
}
.single-blog-grid-01 .thumb .news-date.style-01 {
  background-color: #ae39ff;
}
.single-blog-grid-01 .thumb .news-date.style-01 .title {
  color: #fff;
}
.single-blog-grid-01 .thumb .news-date .title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.single-blog-grid-01 .thumb .news-date.style-02 {
  background-color: #da3654;
}
.single-blog-grid-01 .thumb .news-date.style-02 .title {
  color: #fff;
}
.single-blog-grid-01 .thumb .news-date.style-03 {
  background-color: #7048c0;
}
.single-blog-grid-01 .thumb .news-date.style-03 .title {
  color: #fff;
}
.single-blog-grid-01 .content {
  padding: 50px 0 30px;
}
.single-blog-grid-01 .content .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  transition: all 500ms;
  color: #000629;
}
.single-blog-grid-01 .content .title.style-01 {
  color: #fff;
}
.single-blog-grid-01 .content .title:hover {
  color: rgba(255, 255, 255, 0.8);
}
.single-blog-grid-01 .content .title.style-03:hover {
  color: #001175;
}
.single-blog-grid-01 .content .style-02 {
  font-size: 14px;
  color: #bdc1c9;
  padding-right: 68px;
}

/*------------------------------
    Case Category Item
------------------------------*/
.case-category-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.case-category-list.white .single-case-item + .single-case-item {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.case-category-list.white .single-case-item .content .title {
  color: #fff;
}
.case-category-list .single-case-item {
  width: calc(100% / 5);
  padding: 115px 0 115px 0;
  text-align: center;
}
.case-category-list .single-case-item + .single-case-item {
  border-left: 1px solid #e2e2e2;
}
.case-category-list .single-case-item .icon {
  font-size: 60px;
  line-height: 70px;
  color: var(--main-color-one);
  margin-bottom: 18px;
}
.case-category-list .single-case-item .content .title {
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 0px;
}

.single-catagory-item {
  padding: 72px 40px;
  background-color: var(--main-color-one);
  text-align: center;
}
.single-catagory-item.style-01 {
  background-color: #00c99f;
}
.single-catagory-item.style-02 {
  background-color: #16327a;
}
.single-catagory-item.style-03 {
  background-color: #14b3e4;
}
.single-catagory-item:hover {
  cursor: pointer;
}
.single-catagory-item:hover .icon {
  transform: rotateY(360deg);
}
.single-catagory-item .icon {
  color: #fff;
  font-size: 80px;
  line-height: 80px;
  transition: all 500ms;
  margin-bottom: 15px;
}
.single-catagory-item .title {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

/*-------------------------------
    Quote With Image
-------------------------------*/
.quote-with-image-02 {
  margin-bottom: 40px;
}
.quote-with-image-02 .img-wrapper {
  position: relative;
  z-index: 0;
}
.quote-with-image-02 .img-wrapper .hover {
  position: absolute;
  right: 0;
  bottom: -40px;
  width: 80%;
}
.quote-with-image-02 .img-wrapper .hover .quote-block-with-sign {
  padding: 50px 60px 60px 60px;
}
.quote-with-image-02 .img-wrapper .hover .quote-block-with-sign:after {
  left: 40px;
  top: 30px;
}

/*---------------------------
Single package item
---------------------------*/
.single-package-item {
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
}
.single-package-item.bg-image {
  width: 100%;
  border-radius: 50px;
  min-height: 225px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 0;
}
.single-package-item.bg-image:after {
  content: "";
  position: absolute;
  left: 0;
  border-radius: 50px;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(247, 29, 75, 0.1);
}
.single-package-item.bg-image canvas {
  border-radius: 50px;
}
.single-package-item .content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 30px;
  z-index: 1;
}
.single-package-item .content .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
}
.single-package-item .content .btn-wrapper .boxed-btn {
  font-size: 16px;
  font-weight: 700;
  color: var(--secondary-color);
  padding: 6px 20px;
  border-radius: 30px;
  background-color: #fff;
  margin-bottom: 10px;
}
.single-package-item .content .btn-wrapper .boxed-btn:hover {
  color: #fff;
  background-color: var(--main-color-one);
}

.frequently-area .section-title .title {
  font-size: 58px;
}
.frequently-area .section-title .title.style-01 {
  font-size: 48px;
}
.frequently-area .submit-btn {
  background-color: #007bcf;
  border: none;
  cursor: pointer;
}
.frequently-area .submit-btn:hover {
  background-color: #007bcf;
  border: none;
}
.frequently-area .content .title {
  font-size: 48px;
  line-height: 60px;
  color: #16215c;
  font-weight: 600;
  margin-bottom: 20px;
}
.frequently-area .content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  max-width: 450px;
}

.faq-single-item {
  margin-bottom: 40px;
}
.faq-single-item.style-01 {
  margin: 0;
}
.faq-single-item .content .title {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.faq-single-item .content .customer-para {
  font-size: 14px;
  line-height: 24px;
  max-width: none;
}

.offer-single-item {
  display: flex;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 40px 70px 35px 40px;
  margin-bottom: 30px;
}
.offer-single-item.style-01 {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}
.offer-single-item:hover .icon {
  animation: btnAnime 0.6s ease forwards;
}
.offer-single-item .icon {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 40px;
  color: #fff;
  background-color: #6600f2;
  cursor: pointer;
}
@keyframes btnAnime {
  5% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.offer-single-item .icon.style-01 {
  background-color: #fb3c7f;
}
.offer-single-item .icon.style-02 {
  background-color: #00d280;
}
.offer-single-item .icon.style-03 {
  background-color: #fbab2f;
}
.offer-single-item .content {
  flex: 1;
  margin-left: 45px;
}
.offer-single-item .content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}
.offer-single-item .content p {
  padding: 0 15px 0 0;
}
.offer-single-item .content.style-01 .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}
.offer-single-item .content.style-01 p {
  padding: 0;
}

.choose-single-item:hover .icon {
  animation: btnAnime 0.6s ease forwards;
}
.choose-single-item .icon {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 25px;
  color: #fff;
  background-color: #6600f2;
  margin-bottom: 20px;
  cursor: pointer;
}
@keyframes btnAnime {
  5% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.choose-single-item .icon.style-01 {
  background-color: #fb3c7f;
}
.choose-single-item .icon.style-02 {
  background-color: #00d280;
}
.choose-single-item .icon.style-03 {
  background-color: #fbab2f;
}
.choose-single-item .content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.single-everything-item {
  background-color: #fff;
  text-align: center;
  padding: 0 60px;
  position: relative;
  z-index: 0;
  transition: all 0.3s;
  overflow: hidden;
  margin-bottom: 30px;
}
.single-everything-item:hover .icon {
  line-height: 80px;
  font-size: 40px;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}
.single-everything-item:hover .icon::after {
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.single-everything-item:hover .icon.style-01::after, .single-everything-item:hover .icon.style-02::after {
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.single-everything-item .icon {
  text-align: center;
  margin: auto;
  font-size: 80px;
  line-height: 90px;
  transition: all 0.3s;
  color: #4b3be1;
  display: inline-block;
  margin-bottom: 18px;
  position: relative;
  z-index: 0;
}
.single-everything-item .icon:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 55px;
  height: 55px;
  transition: all 0.3s;
  background-color: #fbab2f;
  border-radius: 50%;
  z-index: -1;
}
.single-everything-item .icon.style-01::after {
  top: 20px;
  left: -25px;
  width: 65px;
  height: 35px;
  background-color: #3ccc8c;
  border-radius: 0;
}
.single-everything-item .icon.style-02::after {
  bottom: 0;
  left: 0;
  width: 38px;
  height: 30px;
  background-color: #57b4fd;
  border-radius: 0;
}
.single-everything-item .content .title {
  font-size: 22px;
  line-height: 34px;
  font-weight: 700;
}
.single-everything-item .content p {
  transition: all 0.5s;
}

.single-best-item {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 0;
}
.single-best-item .shape {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 13px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 100px;
}
.single-best-item .icon {
  font-size: 65px;
  line-height: 75px;
  color: #000;
  margin-bottom: 20px;
}
.single-best-item .content p {
  font-size: 24px;
  color: #000629;
}

.single-service-item {
  background-color: #fff;
  text-align: center;
  padding: 30px;
  position: relative;
  z-index: 0;
  transition: all 0.3s;
  overflow: hidden;
}
.single-service-item:hover .icon {
  border: 2px solid #4b3be1;
  background-color: transparent;
  color: #4b3be1;
  font-weight: 700;
}
.single-service-item:hover .icon.style-02 {
  border: 2px solid #fb3c7f;
  color: #fb3c7f;
  background-color: transparent;
}
.single-service-item:hover .icon.style-03 {
  border: 2px solid #00d280;
  color: #00d280;
  background-color: transparent;
}
.single-service-item .icon {
  text-align: center;
  font-size: 30px;
  line-height: 70px;
  width: 70px;
  height: 70px;
  margin: auto;
  border-radius: 50%;
  transition: all 0.3s;
  border: 2px solid transparent;
  background-color: #4b3be1;
  color: #fff;
  margin-bottom: 18px;
  transition: all 500ms ease-in-out;
}
.single-service-item .icon.style-02 {
  background-color: #fb3c7f;
}
.single-service-item .icon.style-03 {
  background-color: #00d280;
}
.single-service-item .content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}
.single-service-item .content p {
  transition: all 0.5s;
}

.single-service-item-01 {
  background-color: #fff;
  text-align: center;
  padding: 30px 34px;
  position: relative;
  z-index: 0;
  transition: all 0.3s;
  overflow: hidden;
}
.single-service-item-01 .icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 44px;
  margin: auto;
  background-color: transparent;
  background-image: linear-gradient(140deg, #0E98DE 0%, #3FBCD2 100%);
  border-radius: 20px;
  margin-bottom: 18px;
  color: #fff;
  transition: all 500ms ease-in-out;
}
.single-service-item-01 .icon.style-02 {
  background-image: linear-gradient(110deg, #57C16B 0%, #2BCDA0 100%);
}
.single-service-item-01 .icon.style-03 {
  background-image: linear-gradient(150deg, #EE6D5D 0%, #F89234 100%);
}
.single-service-item-01 .content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}
.single-service-item-01 .content p {
  transition: all 0.5s;
}

.learning-service-item {
  display: flex;
  justify-content: center;
}

.single-workout-item {
  margin-bottom: 30px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 31px 54px 0px rgba(0, 0, 0, 0.09);
  padding: 75px 50px 70px;
  display: flex;
  position: relative;
  z-index: 0;
}
.single-workout-item:hover::after {
  width: 100%;
}
.single-workout-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 0;
  border-radius: 10px 10px 0 0;
  background-color: #ae39ff;
  transition: all 500ms ease-in;
}
.single-workout-item .icon {
  font-size: 75px;
  line-height: 85px;
  color: #7753bd;
}
.single-workout-item .icon.style-01 {
  color: #fbb005;
}
.single-workout-item .icon.style-02 {
  color: #4eacf6;
}
.single-workout-item .content {
  margin-left: 30px;
}
.single-workout-item .content .title {
  color: #060c2d;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

.single-benifits-item {
  margin-bottom: 30px;
  text-align: center;
  padding: 45px 40px 40px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 92px 0px rgba(54, 57, 164, 0.2);
}
.single-benifits-item .icon {
  display: inline-block;
  color: #4784eb;
  font-size: 55px;
  line-height: 65px;
  position: relative;
  z-index: 0;
}
.single-benifits-item .icon::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
  background-color: #5fecfe;
  border-radius: 50%;
  z-index: -1;
}
.single-benifits-item .icon .shape {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #5fecfe;
}
.single-benifits-item .icon .shape-02 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 15px solid #5fecfe;
  border-bottom: 10px solid transparent;
}
.single-benifits-item .content .title {
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 120px;
  font-size: 24px;
  line-height: 34px;
  color: #000629;
  font-weight: 700;
}

.hard-single-item .thumb {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 360px;
  border-radius: 5px;
}
.hard-single-item .thumb canvas {
  position: initial;
}
.hard-single-item .content {
  margin-top: 20px;
}
.hard-single-item .content .title {
  font-size: 24px;
  line-height: 34px;
  color: #16215c;
  font-weight: 500;
  margin-bottom: 10px;
}
.hard-single-item .content .catagory {
  font-size: 14px;
  line-height: 24px;
}

.hard-single-item-02:hover .thumb::after {
  opacity: 1;
  visibility: visible;
}
.hard-single-item-02:hover .thumb .content {
  opacity: 1;
  visibility: visible;
}
.hard-single-item-02 .thumb {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 460px;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  transition: all 500ms ease-out;
}
.hard-single-item-02 .thumb canvas {
  position: initial;
}
.hard-single-item-02 .thumb:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease-out;
  background: linear-gradient(to top, rgba(21, 21, 41, 0.7) 30%, rgba(255, 0, 0, 0) 60%);
}
.hard-single-item-02 .thumb .content {
  position: absolute;
  bottom: 0;
  padding-left: 40px;
  padding-bottom: 50px;
  margin-top: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease-out;
}
.hard-single-item-02 .thumb .content .title {
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
}
.hard-single-item-02 .thumb .content .catagory {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.hard-work-area.learning-app {
  background-image: linear-gradient(120deg, #0793E0 0%, #45C0D0 100%);
  position: relative;
  z-index: -1;
  margin-top: -260px;
  padding: 400px 0 0 0;
}
.hard-work-area .section-title .title {
  color: #fff;
}
.hard-work-area .section-title p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-top: 20px;
}
.hard-work-area .apps-download {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hard-work-area .download-img.style-01 {
  margin-right: 20px;
}
.hard-work-area .learning-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  min-height: 784px;
}

.destination-single-item {
  display: flex;
}
.destination-single-item .icon {
  font-size: 25px;
  color: #6600f2;
  margin-bottom: 20px;
  transition: all 500ms ease-in-out;
}
.destination-single-item .icon.style-01 {
  color: #fb3c7f;
}
.destination-single-item .content {
  margin-left: 20px;
  max-width: 150px;
}
.destination-single-item .content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.single-app-item {
  background-color: #fff;
  text-align: center;
  position: relative;
  z-index: 0;
  padding: 0 30px;
  transition: all 0.3s;
  overflow: hidden;
  margin-bottom: 30px;
}
.single-app-item:hover .icon {
  line-height: 80px;
  font-size: 40px;
  text-align: center;
  color: #fff;
  transform: rotate(0);
}
.single-app-item:hover .icon::after {
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.single-app-item .icon {
  text-align: center;
  margin: auto;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-size: 50px;
  line-height: 80px;
  transition: all 0.3s;
  background-color: #3639a4;
  color: #fff;
  display: inline-block;
  margin-bottom: 18px;
  position: relative;
  z-index: 0;
}
.single-app-item .icon:after {
  content: "";
  position: absolute;
  left: 45px;
  bottom: 5px;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  background-color: #75e8f0;
  border-radius: 50%;
  z-index: -1;
}
.single-app-item .content .title {
  font-size: 22px;
  line-height: 34px;
  font-weight: 700;
}
.single-app-item .content p {
  transition: all 0.5s;
}

.started-single-item {
  background-color: #fff;
  box-shadow: 0px 0px 92px 0px rgba(54, 57, 164, 0.2);
  text-align: center;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  padding: 50px 30px 40px;
  transition: all 0.5s;
  overflow: hidden;
  border: 5px solid #fff;
  margin-bottom: 30px;
}
.started-single-item:hover {
  border: 5px solid #3639a4;
}
.started-single-item:hover .icon {
  border: 5px solid #3639a4;
}
.started-single-item .icon {
  text-align: center;
  margin: auto;
  font-size: 50px;
  line-height: 120px;
  width: 120px;
  height: 120px;
  border: 1px solid #3639a4;
  border-radius: 50%;
  transition: all 0.3s;
  color: #3639a4;
  display: inline-block;
  margin-bottom: 18px;
  position: relative;
  z-index: 0;
}
.started-single-item .content .title {
  font-size: 22px;
  line-height: 34px;
  font-weight: 700;
}
.started-single-item .content p {
  transition: all 0.5s;
}

/*--------------------------
  // Team Area
--------------------------*/
.team-single-item {
  position: relative;
  margin-bottom: 30px;
}
.team-single-item:hover .thumb::before {
  opacity: 1;
}
.team-single-item:hover .thumb::after {
  opacity: 1;
}
.team-single-item .thumb {
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  position: relative;
  z-index: 0;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
}
.team-single-item .thumb:before {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
  content: "";
  transform: scale(1.1);
  opacity: 0;
  transition: all 500ms ease-in-out;
}
.team-single-item .thumb::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(48, 133, 163, 0.3);
  transition: all 500ms ease-in-out;
}
.team-single-item .content {
  text-align: center;
  margin-top: 20px;
}
.team-single-item .content .title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.team-single-item .content .social-link {
  margin-top: 10px;
}

.counter-single-item {
  padding: 25px 0 0;
}
.counter-single-item .counter-item {
  display: inline-block;
  width: 80px;
  height: 80px;
  padding-top: 12px;
  border-radius: 50%;
  background-color: #5f2eca;
}
.counter-single-item .counter-item span {
  text-align: center;
  color: #fff;
  display: block;
  line-height: 35px;
  font-size: 30px;
  font-weight: 700;
}
.counter-single-item .counter-item h6 {
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.apps-month-area.apps-bg {
  background-color: #340335;
}
.apps-month-area .section-title .title {
  font-size: 42px;
  color: #fff;
  margin-bottom: 30px;
}
.apps-month-area .section-title .apps-pera {
  font-size: 17px;
  color: #fff;
  font-weight: 400;
}
.apps-month-area .apps-month-img.bg-image {
  min-height: 500px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
.apps-month-area .apps-month-single-item {
  display: flex;
}
.apps-month-area .apps-month-single-item .icon {
  color: #f7618b;
  font-size: 50px;
  line-height: 50px;
  margin-right: 35px;
}
.apps-month-area .apps-month-single-item .content .title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 15px;
}
.apps-month-area .apps-month-single-item .content .apps-pera {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  -webkit-hyphens: none;
          hyphens: none;
}

.work-single-item:hover .content .icon {
  background-color: #f5ae6f;
  color: #f2e5e0;
}
.work-single-item:hover .content .icon.style-01 {
  background-color: #45d6d2;
  color: #ceeef5;
}
.work-single-item:hover .content .icon.style-02 {
  background-color: #f7618b;
  color: #f2d7e7;
}
.work-single-item:hover .content .icon.style-03 {
  background-color: #7048c0;
  color: #d7d2f2;
}
.work-single-item:hover .content .icon.style-04 {
  color: #efd8f2;
  background-color: #b13cc0;
}
.work-single-item:hover .content .icon.style-05 {
  color: #fdeee1;
  background-color: #f6af6f;
}
.work-single-item .content .icon {
  margin-bottom: 20px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  font-size: 35px;
  color: #f5ae6f;
  background-color: #f2e5e0;
  transition: all 500ms ease-out;
}
.work-single-item .content .icon.style-01 {
  color: #45d6d2;
  background-color: #ceeef5;
}
.work-single-item .content .icon.style-02 {
  color: #f7618b;
  background-color: #f2d7e7;
}
.work-single-item .content .icon.style-03 {
  color: #7048c0;
  background-color: #d7d2f2;
}
.work-single-item .content .icon.style-04 {
  background-color: #efd8f2;
  color: #b13cc0;
}
.work-single-item .content .icon.style-05 {
  background-color: #fdeee1;
  color: #f6af6f;
}
.work-single-item .content .title {
  font-size: 24px;
  font-weight: 700;
  color: #000629;
  margin-bottom: 15px;
}
.work-single-item .content .utility-para {
  font-size: 16px;
  line-height: 26px;
  max-width: 315px;
}

.work-single-item-02 {
  margin-bottom: 30px;
  text-align: center;
  padding: 45px 40px 40px;
  border-radius: 10px;
  background-color: white;
}
.work-single-item-02.style-01 {
  background-color: rgba(0, 0, 0, 0.1);
}
.work-single-item-02.style-01 .content .title {
  color: #fff;
  font-size: 20px;
}
.work-single-item-02.style-01 .content .para {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  padding: 0 10px;
}
.work-single-item-02 .icon {
  display: inline-block;
  color: #ff6661;
  font-size: 55px;
  line-height: 65px;
  position: relative;
  z-index: 0;
}
.work-single-item-02 .icon .shape {
  position: absolute;
  bottom: 9px;
  right: -30px;
  width: 60px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}
.work-single-item-02 .icon .shape-02 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 35px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #000629;
  z-index: -1;
}
.work-single-item-02 .content .title {
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 255px;
  font-size: 24px;
  line-height: 34px;
  color: #000629;
  font-weight: 700;
}

.work-single-item-03 {
  margin-bottom: 30px;
  text-align: center;
  padding: 45px 40px 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 125px 0px rgba(32, 33, 69, 0.16);
  background-color: white;
  position: relative;
  z-index: 0;
}
.work-single-item-03:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 0;
  border-radius: 10px 10px 0 0;
  background-color: #ae39ff;
  transition: all 500ms ease-in;
  z-index: -1;
}
.work-single-item-03:hover::after {
  width: 100%;
}
.work-single-item-03.style-01::after {
  background-color: #20b3f8;
}
.work-single-item-03.style-01 .icon {
  color: #20b3f8;
}
.work-single-item-03.style-02::after {
  background-color: #fd37e2;
}
.work-single-item-03.style-02 .icon {
  color: #fd37e2;
}
.work-single-item-03 .icon {
  display: inline-block;
  color: #9738f6;
  font-size: 55px;
  line-height: 65px;
  position: relative;
  z-index: 0;
}
.work-single-item-03 .content .title {
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 200px;
  font-size: 24px;
  line-height: 34px;
  color: #000629;
  font-weight: 700;
}

.work-single-item-04 {
  margin-bottom: 30px;
  text-align: center;
}
.work-single-item-04 .icon-wrap {
  padding: 30px;
  border: 1px solid #32A8A2;
  border-radius: 5px;
}
.work-single-item-04 .icon-wrap .icon {
  width: 130px;
  height: 130px;
  background-color: #32A8A2;
  margin: auto;
  border-radius: 73% 27% 13% 87%/55% 48% 52% 45%;
  font-size: 55px;
  line-height: 130px;
  color: #fff;
}
.work-single-item-04 .content {
  padding: 0 50px;
}
.work-single-item-04 .content .title {
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 255px;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  font-weight: 500;
}

.advantege-work-area {
  position: relative;
  z-index:0;
}

.advantege-work-area .content .section-title p {
  font-size: 16px;
  line-height: 26px;
  color: #919191;
  max-width: 500px;
}
.advantege-work-area .advantege-img {
  height: 912px;
  width: 740px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0px;
  top: -164px;
}
.advantege-work-area .advantege-img-02 {
  height: 300px;
  width: 100%;
  position: absolute;
  top: 196px;
  right: -72%;
  min-height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
}

.batter-work-area.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  margin-top: 20px;
}
.batter-work-area .section-title .title {
  font-size: 42px;
  line-height: 52px;
  color: #fff;
}

.food-single-item {
  margin-bottom: 30px;
}
.food-single-item .content .title {
  font-family: var(--heading-font-02);
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #000629;
}

.how-it-single-item {
  text-align: center;
  margin-bottom: 30px;
  padding: 0 30px;
  transition: all 500ms ease-in;
}
.how-it-single-item:hover {
  padding: 30px 30px 25px;
  box-shadow: 0px 0px 103px 0px rgba(93, 136, 250, 0.5);
  border-radius: 10px;
}
.how-it-single-item .icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin: auto;
  margin-bottom: 35px;
  border-radius: 50%;
  background-color: #fca8c9;
}
.how-it-single-item .icon.style-01 {
  background-color: #a181ff;
}
.how-it-single-item .icon.style-02 {
  background-color: #fe6927;
}
.how-it-single-item .content .title {
  font-size: 24px;
  font-weight: 700;
  color: #000629;
  margin-bottom: 26px;
}

.how-it-single-item-02 {
  text-align: center;
  margin-bottom: 30px;
  padding: 0 30px;
  transition: all 500ms ease-in;
}
.how-it-single-item-02 .icon {
  font-size: 75px;
  line-height: 85px;
  color: #000;
  margin: auto;
  margin-bottom: 35px;
}
.how-it-single-item-02 .content .title {
  font-size: 24px;
  font-weight: 700;
  color: #b55700;
  margin-bottom: 26px;
}
.how-it-single-item-02 .content p {
  color: #fff;
}

.how-it-single-item-03 {
  text-align: center;
  margin-bottom: 30px;
  transition: all 500ms ease-in;
}
.how-it-single-item-03 .icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 125px;
  font-weight: 700;
  color: #352d66;
  margin: auto;
  margin-bottom: 35px;
  border-radius: 50%;
  background-color: rgba(43, 134, 255, 0.6);
}
.how-it-single-item-03 .icon.style-01 {
  background-color: #fedcb4;
}
.how-it-single-item-03 .icon.style-02 {
  background-color: #d8b5e5;
}
.how-it-single-item-03 .content .title {
  font-size: 24px;
  font-weight: 700;
  color: #000629;
  margin-bottom: 26px;
}
.how-it-single-item-03 .content .medi-para {
  max-width: 285px;
  margin: auto;
}
.advantege-work-area .youtubeDiv{
height: 100%;
width: 100%;
margin: 0px;
padding: 0px;
margin-top: 50px;
z-index: 2;
 }
 .advantege-work-area .row{
  --bs-gutter-x: 10px; 
  --bs-gutter-y: 0px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
 }
.FirstDiv{
line-height: 1.5;
}
 .FirstDiv h3{
    margin-top: 5px;
    color: rgb(0, 0, 0);
    font-size: 25px;
    color: #32A8A2;
 
 }
 .FirstDiv h1 span{
color: #0E8C84;
 }
 .card-action-btn a{
text-decoration: none;
color: white !important;
user-select: none;
 }
 .SecondDiv div{
width: 100%;
height: 100px;
background-color: #0E8C84;
padding: 15px;
margin-bottom: 25px;
text-align: center;
 }
 .SecondDiv div h2{
color: #282828;
 }
 .SecondDiv div h6{
color: white;
 }
 .learnButton{
  padding: 7px;
  width: 140px;
  background-color: #32A8A2;
  border-radius: 10px;
  color: white;
  transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
  }
  .learnButton:hover{
  background-color: black;
  color: white;
  }
  .feedback-page .customer-feedback {
    margin: 0 0 30px 1px;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Average Sans';
    font-weight: 400;
    font-size: 30px;
    line-height: 1;
    color: #004D4D;
  }
  .feedback-page .line-981 {
    background: #0E7481;
    margin: 0 0 69px 2px;
    width: 64px;
    height: 2px;
  }
  .feedback-page .ellipse-25 {
    margin-right: 10px;
    width: 42px;
    height: 41px;
  }
  .feedback-page .roberto-castillo {
    margin: 12px 0 9px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.429;
    color: #FFFFFF;
  }
  .feedback-page .containerhmg {
    margin-bottom: 21px;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: fit-content;
    box-sizing: border-box;
  }
  .feedback-page .ive-been-selling-r-5 {
    margin: 0 22.2px 61px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 15px;
    line-height: 1.833;
    color: #FFFFFF;
  }
  .feedback-page .image-35 {
    align-self: flex-end;
    width: 124px;
    height: 24px;
  }
  .feedback-page .review-1 {
    border-radius: 8px;
    background: #08313A;
    position: relative;
    margin: 1px 25px 0 0;
    display: flex;
    flex-direction: column;
    padding: 34px 16px 18px 30px;
    box-sizing: border-box;
  }
  .feedback-page .ellipse-21 {
    margin-right: 10px;
    width: 42px;
    height: 41px;
  }
  .feedback-page .roberto-castillo-2 {
    margin: 12px 0 9px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.429;
    color: #FFFFFF;
  }
  .feedback-page .container-3 {
    margin-bottom: 21px;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: fit-content;
    box-sizing: border-box;
  }
  .feedback-page .ive-been-selling-r-1 {
    margin: 0 22.2px 61px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 15px;
    line-height: 1.833;
    color: #FFFFFF;
  }
  .feedback-page .image-31 {
    
    align-self: flex-end;
    width: 124px;
    height: 24px;
  }
  .feedback-page .review-2 {
    
    border-radius: 8px;
    background: #08313A;
    position: relative;
    margin: 1px 21px 0 0;
    display: flex;
    flex-direction: column;
    padding: 34px 16px 18px 30px;
    box-sizing: border-box;
  }
  .feedback-page .ellipse-23 {
    margin-right: 10px;
    width: 42px;
    height: 41px;
  }
  .feedback-page .roberto-castillo-4 {
    margin: 12px 0 9px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.429;
    color: #FFFFFF;
  }
  .feedback-page .container-1 {
    margin-bottom: 21px;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: fit-content;
    box-sizing: border-box;
  }
  .feedback-page .ive-been-selling-r-3 {
    margin: 0 22.2px 61px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 15px;
    line-height: 1.833;
    color: #FFFFFF;
  }
  .feedback-page .image-33 {
    
    align-self: flex-end;
    width: 124px;
    height: 24px;
  }
  .feedback-page .review-3 {
    
    border-radius: 8px;
    background: #08313A;
    position: relative;
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    padding: 34px 16px 18px 30px;
    box-sizing: border-box;
  }
  .feedback-page .container-5 {
    margin-bottom: 67px;
    display: flex;
    flex-direction: row;
    width: fit-content;
    box-sizing: border-box;
  }
  .feedback-page .ellipse-2 {
    margin-right: 10px;
    width: 42px;
    height: 41px;
  }
  .feedback-page .roberto-castillo-1 {
    margin: 12px 0 9px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.429;
    color: #FFFFFF;
  }
  .feedback-page .container-7 {
    margin-bottom: 21px;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: fit-content;
    box-sizing: border-box;
  }
  .feedback-page .ive-been-selling-r {
    margin: 0 22.2px 61px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 15px;
    line-height: 1.833;
    color: #FFFFFF;
  }
  .feedback-page .image-3 {
    
    align-self: flex-end;
    width: 124px;
    height: 24px;
  }
  .feedback-page .review-4 {
    
    border-radius: 8px;
    background: #08313A;
    position: relative;
    margin: 1px 25px 0 0;
    display: flex;
    flex-direction: column;
    padding: 34px 16px 18px 30px;
    box-sizing: border-box;
  }
  .feedback-page .ellipse-22 {
    margin-right: 10px;
    width: 42px;
    height: 41px;
  }
  .feedback-page .roberto-castillo-3 {
    margin: 12px 0 9px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.429;
    color: #FFFFFF;
  }
  .feedback-page .container-4 {
    margin-bottom: 21px;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: fit-content;
    box-sizing: border-box;
  }
  .feedback-page .ive-been-selling-r-2 {
    margin: 0 22.2px 61px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 15px;
    line-height: 1.833;
    color: #FFFFFF;
  }
  .feedback-page .image-32 {
    
    align-self: flex-end;
    width: 124px;
    height: 24px;
  }
  .feedback-page .review-5 {
    
    border-radius: 8px;
    background: #08313A;
    position: relative;
    margin: 1px 21px 0 0;
    display: flex;
    flex-direction: column;
    padding: 34px 16px 18px 30px;
    box-sizing: border-box;
  }
  .feedback-page .ellipse-24 {
    margin-right: 10px;
    width: 42px;
    height: 41px;
  }
  .feedback-page .roberto-castillo-5 {
    margin: 12px 0 9px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.429;
    color: #FFFFFF;
  }
  .feedback-page .container-2 {
    margin-bottom: 21px;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: fit-content;
    box-sizing: border-box;
  }
  .feedback-page .ive-been-selling-r-4 {
    margin: 0 22.2px 61px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Metropolis', 'Roboto Condensed';
    font-weight: 400;
    font-size: 15px;
    line-height: 1.833;
    color: #FFFFFF;
  }
  .feedback-page .image-34 {
    
    align-self: flex-end;
    width: 124px;
    height: 24px;
  }
  .feedback-page .review-6 {
    
    border-radius: 8px;
    background: #08313A;
    position: relative;
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    padding: 34px 16px 18px 30px;
    box-sizing: border-box;
  }
  .feedback-page .container-6 {
    display: flex;
    flex-direction: row;
    width: fit-content;
    box-sizing: border-box;
  }
  .feedback-page {
    background: linear-gradient(180deg, #32A8A2, #FFFFFF);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 57px 35px 237px 33px;
    
    box-sizing: border-box;
  }
 

  body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: fit-content;
    font-family: Arial, sans-serif;
    overflow-x: hidden; 
}

.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: max-content;
    background: linear-gradient(135deg, #106C65, #32A8A2);
    color: white;
    padding: 80px 0px;
    z-index: 20;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2; /* Ensure content is above background */
    padding: 20px; /* Add padding to avoid content touching the edges */
}

.hero-text {
    font-size: 4rem;
    margin: 0;
    animation: fadeIn 2s ease-in-out;
}

.dynamic-text {
    color: #32A8A2;
    position: relative;
    font-size: 2rem;
    margin: 1rem 0;
    height: 2.5rem; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.dynamic-text .word {
    position: absolute;
    width: 100%;
    text-align: center; /* Center align the words */
    opacity: 0;
    animation: changeText 6s infinite;
}

.dynamic-text .w1 { animation-delay: 0s; }
.dynamic-text .w2 { animation-delay: 3s; }

@keyframes changeText {
    0% { opacity: 0; transform: translateY(-20px); }
    10% { opacity: 1; transform: translateY(0); }
    30% { opacity: 1; transform: translateY(0); }
    40% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 0; transform: translateY(20px); }
}

.hero-description {
    font-size: 1.5rem;
    margin: 1.5rem 0 0 0;
    animation: fadeIn 3s ease-in-out;
}

.cta-button {
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    color: #106C65;
    background-color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    animation: fadeIn 4s ease-in-out;
}

.cta-button:hover {
    background-color: #f0f0f0;
}

.background-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://www.transparenttextures.com/patterns/asfalt-light.png'), linear-gradient(135deg, #106C65, #32A8A2);
    background-size: cover;
    animation: moveBackground 30s linear infinite;
    z-index: 0;
}

.default-ltr-cache-1lj9d5t {
  display: inline-block;
  height: 1.5rem;
}
.default-ltr-cache-1lj9d5t > svg {
  width: 1.5rem;
  height: inherit;
  margin-left: 0.75rem;
}
.Carrying{
text-align: center;
max-width: 900px;
margin: 50px auto;
}
.Carrying2{
text-align: center;
max-width: 900px;
}
.newBouu a{
  font-size: 18px;
  width: 200px;
  padding: 15px 40px;
    background-color: #32A8A2;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in;
    font-weight: 600;
}
.newBouu a:hover{
background-color: black;
}
@keyframes moveBackground {
    0% { background-position: 0 0; }
    100% { background-position: 100% 100%; }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .hero-text {
        font-size: 3rem;
    }

    .dynamic-text {
        font-size: 1.5rem;
    }

    .hero-description {
        font-size: 1.25rem;
    }

    .cta-button {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .hero-text {
        font-size: 2.5rem;
    }

    .dynamic-text {
        font-size: 1.25rem;
    }

    .hero-description {
        font-size: 1rem;
    }

    .cta-button {
        font-size: 0.875rem;
        padding: 0.75rem 1.5rem;
    }
    .work-single-item-04 {
      margin-bottom: 30px;
      text-align: center;
      width: 80%;
      margin-left: 7%;
    }
    .work-single-item-04 .icon-wrap {
      padding: 10px;
      border: 1px solid #32A8A2;
      border-radius: 5px;
    }
    .work-single-item-04 .icon-wrap .icon {
      width: 110px;
      height: 110px;
      background-color: #32A8A2;
      margin: auto;
      border-radius: 73% 27% 13% 87%/55% 48% 52% 45%;
      font-size: 45px;
      line-height: 130px;
      color: #fff;
    }
    .footer-title {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 15px;
      text-transform: capitalize;
  }
  .footer-widget{
  margin-bottom: 35px;
  }
  .accordion-wrapper .carddd .carddd-header a:after {
    position: absolute;
    right: 5px;
    top: 15px;
    content: "";
    font-family: "fontawesome";
}
.accordion-wrapper .carddd .carddd-header a.white {
  background-color: #f4f4f4;
  font-size: 16px;
  color: #000629;
  width: 100%;
}
.padding-bottom-170 {
  padding-bottom: 0px;
}
}
@media (max-width: 991px) {
  .FirstDiv p{
    width: 100%;
    padding: 0px 10px;
    }
}
@media (max-width: 480px) {
  .single-counterup-01 .content .count-wrap {
    font-size: 20px;
  }
  .navbar {
padding: 0px;
  }
  .navbar img{
width: 140px;
  }
  .container {
    width: 100%;
    padding: 0px;
}
  .newBouu a{
    font-size: 14px;
    text-align: center;
    width: 125px;
    padding: 12px 0px;
  }
  .toggle-language {
    background-color: #32A8A2;
    color: #fff;
    border: 1px solid #106C65;
    padding: 8px 20px;
    border: 2px solid #fff;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: all 0.4s ease-in;
}

  .FirstDiv2 p{
    width: 100%;
     font-size: 0.975rem;
  }
  .FirstDiv2 h3{
    width: 100%;
     font-size: 1.55rem;
  }
  
  .Carrying p{
font-size: 0.975rem;
  }
  .Carrying h3{
font-size: 1.55rem;
  }

  .Carrying a{
    font-size: 15px;
  }
    .hero-text {
        font-size: 2rem;
    }

    .dynamic-text {
        font-size: 1rem;
    }

    .hero-description {
        font-size: 0.975rem;
    }

    .cta-button {
        font-size: 0.75rem;
        padding: 0.5rem 1rem;
    }
    .enterprice-ready-area .enterprice-image {
      background-size: contain;
      background-repeat: no-repeat;
      height: 220px;
  }
  .card-action-btn {
    margin-top: 10px;
    padding: 10px 15px;
    font-size: 12px;
  }
}
.dire{
text-decoration: none;
}
/* Animation Keyframes */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(100px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* General Animations */
.fade-in {
  animation: fadeIn 2s ease-in-out;
}

.slide-in {
  animation: slideIn 1s ease-out;
}

.btn-work {
  animation: slideIn 2s ease-out;
  transition: background-color 0.3s ease-in-out;
}

.btn-work:hover {
  background-color: #32A8A2; /* Adjust the hover color as needed */
}
/* videoSlider.css */
.slider-container {
  width: 100%;
  margin-left: 10px;
  padding: 0px;
}

.video-slide video {
  border-radius: 10px;
}
.slick-prev::before, .slick-next::before {
  font-family: 'slick';
  font-size: 25px;
  line-height: 1;
  opacity: .75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.prevv{
  font-family: verdana;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: .9rem;
  margin: 0 1rem 1rem 0;
  padding: 1em;
  width: auto;
  background: #106C65;
    color: #fff;
}


.video-slide video {
  width: 100% !important; /* يضمن عرض الفيديو الكامل */
  height: 100%;
  max-width: 90%;
}
.slider-container {
  overflow: hidden; /* منع تجاوز المحتوى */
  width: 100%; /* ملء الشاشة بالكامل */
  max-width: 100%; /* منع تجاوز العرض */
}

/* تصميم البطاقة */
.custom-slider-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  margin-left: 50px; /* مسافات بين العناصر */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* غطاء البطاقة العلوي */
.card-header-area {
  background-image: url('/public/images/covercard.jpg');
  background-size: cover;
  height: 100px; /* ارتفاع الغطاء */
  width: 100%; /* عرض كامل */
}

/* تصميم اللوجو */
.logo-wrapper {
  width: 85px; /* عرض اللوجو */
  height: 85px; /* ارتفاع اللوجو */
  margin-top: -35px; /* لرفعه قليلاً أسفل الغطاء */
  background-color: #fff; /* خلفية بيضاء */
  border: 2px solid #ddd; /* حدود رمادية */
  border-radius: 50%; /* دائرة */
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center; /* لتوسيط المحتوى */
  align-items: center; /* لتوسيط المحتوى */
}
.logo-wrapper  img{
  width: 85px; /* عرض اللوجو */
  height: 85px; /* ارتفاع اللوجو */
}

/* محتوى البطاقة */
.card-content-area {
  padding: 20px;
  text-align: center; /* لتوسيط النص */
}

.card-title-text {
  margin: 10px 0;
  font-size: 18px;
  color: #333;
}

.card-price-text {
  font-size: 16px;
  color: #555;
}

/* زر الاشتراك */
.card-action-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.card-action-btn:hover {
  background-color: #45a049; /* لون عند التمرير */
}

/* مسافات بين الكروت في السلايدر */
.slick-slide {
  padding: 15px; /* مسافات بين العناصر */
}
@media (max-width: 768px) {
  .slick-slide {
    margin: 10px 0; /* إضافة المسافات بين الكروت */
  }

  .custom-slider-item {
    width: 100% !important; /* التأكد أن الكرت يشغل العرض بالكامل */
  }

  .slick-track {
    display: flex !important;
    justify-content: center;
  }
}

.scrollable-container {
  width: 100%; /* عرض الإطار */
  height: 350px; /* طول الإطار */
  overflow-y: auto; /* تفعيل التمرير الرأسي */
  background-color: #f4f4f4; /* لون خلفية الإطار */
  border: none; /* إزالة الحدود لتجنب ظهور الإطار */
  padding: 10px; /* إضافة مساحة داخلية لتجنب ملامسة النصوص للحواف */
  margin-bottom: 25px;
}

