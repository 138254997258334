.navbar {
  background-color: #106C65;
  margin-top: 0px;
  margin-bottom: 0px;
  z-index: 30;
  position: fixed;
  width: 100%;
}
.navbar img{
width: 220px;
}

.navbar-toggler-icon{
  font-size: 29px;
  margin-top: 5px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.nav-link {
  color: white;
  font-size: 19px;
}
.nav-link.active,
.nav-link:hover,
.nav-link:focus {
  color: #33d1cc;
}
.search {
  color: #33d1cc;
  border-left: 1px solid #33d1cc;
}
.navbar .navbar-toggler {
  color: white;
  z-index: 6;
}
.navbar .navbar-toggler:focus {
  box-shadow: none;
}
.navbar .navbar-toggler[aria-expanded="true"] {
  border-color: #33d1cc;
  color: #33d1cc;
}
.nav-item.active > a {
  color: #37cfa2;
}
.navbar-toggler {
  background-color: transparent;
}

    .navbar-fixed {
      animation-name: example;
      animation-duration: 0.7s;
      animation-fill-mode: forwards;
    }
    .nav-tabs{
      display: flex;
      justify-content: center;
      }
      .nav-tabs .nav-link{
      font-size: 18px;
        }  
        @keyframes example {
          from {
            top: -80px;
          }
          to {
            top: 0px;
          }
        }

        .btn-wrapper{
        margin-left: 20px;
        }
        .btn-wrapper .login-btn.style-012 {
          border-radius: 5px;
          background-color: #f5861f;
          transition: all 0.4s ease-in;
          border: 2px solid white;
      }
        .btn-wrapper .login-btn.style-013 {
          border-radius: 5px;
          background-color: #32A8A2;
          transition: all 0.4s ease-in;
          border: 2px solid white;
      }
      .btn-wrapper .login-btn.style-013:hover {
        background-color: chocolate;
        border: 2px solid white;
      }
      .btn-wrapper .login-btn.style-012:hover {
        background-color: chocolate;
        border: 2px solid white;
      }
      .btn-wrapper .login-btn {
          color: #fff;
          font-size: 13px;
          padding: 10px 27px;
          margin-right: 20px;
          border-radius: 30px;
          transition: all 0.3s ease-in;
          text-decoration: none;
      }
      .btn-wrapper .boxed-btn.btn-finance.style-012 {
        background-color: #f5861f;
        border: 2px solid #fff;
        color: #fff;
        text-decoration: none;
       padding: 13px 13px;
    }
    .btn-wrapper .boxed-btn.btn-finance {
        
        background-color: #5fecfe;
        border-radius: 5px;
        color: #0c0765;
    }
    .btn-wrapper .boxed-btn.btn-finance.style-012:hover {
      background-color: #32A8A2;
      border: 2px solid #fff;
      color: #fff;
    }
    .btn-wrapper .boxed-btn {
        background-color: var(--secondary-color);
        color: #fff;
        display: inline-block;
        padding: 12px 40px;
        border-radius: 50px;
        text-transform: capitalize;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease-in;
        font-weight: 600;
        margin-right: 20px;
    }
   
    @media (max-width: 1188px) {
      .navbar {
        background-color: #106C65;
        margin-top: 0px;
        margin-bottom: 0px;
        z-index: 3000;
        position: fixed;
        width: 100%;
      
      }

      .btn-wrapper{
      margin: 0px;
      }
      .btn-wrapper a{
      display: block;
      width: max-content;
      margin-bottom: 10px;
      }
      .btn-wrapper .login-btn{
        margin-bottom: 10px;
        margin-right: 0px;
        }
        .btn-wrapper .boxed-btn {
          margin-right: 0px;
        }
        
    }
    .sidebar335 {
      position: fixed;
      top: 65px;
      width: 350px;
      height: 100%;
      background-color: #106C65;
      z-index: 2000;
      padding-top: 50px;
      transition: all 0.3s ease-in-out;
    }
    
    .sidebar335.rtl {
      left: -350px;
    }
    
    .sidebar335.rtl.open {
      left: 0;
    }
    
    .sidebar335.ltr {
      right: -350px;
    }
    
    .sidebar335.ltr.open {
      right: 0;
    }
    .sidebar335 ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    
    .sidebar335 ul li {
      text-align: center; /* محاذاة النص ليكون في المنتصف */
    }
  
    .sidebar335 ul li a {
      display: block;
      padding: 10px;
      color: white;
      text-decoration: none;
      font-size: 14px; /* حجم الخط أصغر */
      padding-bottom: 22px;
      border-bottom: 1px solid #444;
    }
  
    .sidebar335 ul li a:hover {
      background-color: #4cb8ca; /* تغيير لون الخلفية عند التمرير */
      color: white;
    }
    
    .sidebar335 ul li a.active {
      background-color: #4cb8ca; /* تغيير لون الخلفية عند التمرير */
      color: white;
    }
    
    /* القواعد المخصصة للشاشات الصغيرة */
    @media screen and (max-width: 630px) {
      .sidebar335 {
        width: 200px; /* تقليل العرض */
      }
      
      .sidebar335 ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }
      
      .sidebar335 ul li {
        text-align: center; /* محاذاة النص ليكون في المنتصف */
        
      }
    
      .sidebar335 ul li a {
        display: block;
        padding: 10px;
        color: white;
        text-decoration: none;
        font-size: 13px; /* حجم الخط أصغر */
        padding: 23px;
        border-bottom: 1px solid #444;
      }
    
      .sidebar335 ul li a:hover {
        background-color: #4cb8ca; /* تغيير لون الخلفية عند التمرير */
        color: white;
      }
    }
    


/* يمكنك أيضًا تعديل الأنماط الأخرى للأزرار مثل اللون أو الخلفية إذا لزم الأمر */
.sidebar335 .login-btn {
  margin-bottom: 10px; /* مساحة بين الأزرار */
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
