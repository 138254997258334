/* إعداد الصفحة الرئيسية */
.signup-members-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #2e8b57, #1c4732); 
  min-height: 100vh;
  width: 100%;
}

/* إعداد العنوان */
h2 {
  text-align: center;
  color: white;
  font-size: 32px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}

/* قائمة الأعضاء */
.members-list {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 20px; /* تباعد بين البطاقات */
}

/* بطاقة العضو */
.member-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
}

/* معلومات العضو */
.member-info {
  font-size: 18px;
  color: #333;
}

.info-label {
  font-weight: bold;
  color: #22543d;
}

/* أزرار الإجراءات */
.member-actions {
  margin-top: 10px;
  z-index: 10; /* تأكد من أن الزر في المقدمة */
  cursor: pointer;
}

.delete-button {
  background-color: #ff4c4c;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer; /* هنا نحدد أن البوينتر يجب أن يظهر */
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 10; /* تأكد من أن الزر في المقدمة */
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff3333;
}

/* تصميم متجاوب للشاشات الصغيرة */
@media (max-width: 768px) {
  .member-card {
    padding: 15px;
  }

  h2 {
    font-size: 24px;
  }

  .member-info {
    font-size: 16px;
  }

  .delete-button {
    padding: 6px 10px;
    font-size: 14px;
  }
}

