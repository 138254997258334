/* General Styles */
body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dashboard {
  width: 100%;
  height: 100vh;
  padding: 20px;
  margin-top: -65px;
}

/* navbar2 */
/* إعداد شريط التنقل */
.navbar2 {
  display: flex;
  justify-content: space-between; /* توزيع العناصر بشكل متساوي */
  align-items: center;
  background-color: #106C65; /* لون الخلفية */
  color: white; /* لون النص */
  padding: 15px 30px; /* المساحة الداخلية */
  border-radius: 10px; /* زوايا دائرية */
  margin-bottom: 30px; /* المسافة من الأسفل */
  flex-wrap: wrap; /* تفعيل التفاف العناصر */
}

/* تصميم الشعار */
.logo225 {
  font-size: 1.5rem; /* حجم الشعار */
  font-weight: bold; /* سمك النص */
}

/* تصميم الرسالة في الوسط */
.nav-center {
  font-size: 1.2rem; /* حجم نص الرسالة */
  text-align: center; /* محاذاة النص في الوسط */
  margin: 0 15px; /* إضافة مسافة أفقية للعنصر الوسط */
}

/* تصميم زر الخروج */
.logout-btn2 {
  background-color: #32A8A2; /* لون الزر */
  color: white; /* لون النص في الزر */
  border: none; /* إزالة الحدود */
  padding: 10px 20px; /* المساحة الداخلية للزر */
  border-radius: 5px; /* زوايا دائرية للزر */
  cursor: pointer; /* تغيير شكل المؤشر عند التمرير */
  font-size: 1rem; /* حجم نص الزر */
  transition: 0.3s ease-in;
}

/* تأثير التحويم على الزر */
.logout-btn2:hover {
  background-color: brown; /* تغيير لون الزر عند التحويم */
}



/* تصميم متجاوب */
@media (max-width: 768px) {
  .navbar2 {
      flex-direction: column; /* تغيير التخطيط إلى عمودي */
      align-items: flex-start; /* محاذاة العناصر إلى اليسار */
  }

  .nav-center {
      margin: 20px 0 40px 0; /* إضافة مسافة فوق وتحت العنصر الوسط */
      width: 100%;
      font-size: 18px;
      text-align: center; /* محاذاة النص إلى اليسار */
  }

  .logout-btn2 {
      width: 100%; /* جعل زر الخروج يحتل عرض كامل */
      text-align: center; /* محاذاة النص في الزر إلى الوسط */
      margin-top: 10px; /* إضافة مسافة فوق الزر */
  }
  .logo225 {
      font-size: 20px; /* حجم الشعار */
      font-weight: bold; /* سمك النص */
      width: 100%;
      text-align: center;
  }
}


/* Grid Styles */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.grid-item {
  background: linear-gradient(145deg, #fff, #f1f1f1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 40px 30px;
  height: 200px;
  text-align: center;
  transition: all 0.3s ease;
}

.grid-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 3rem;
  margin-bottom: 20px;
}

/* Matching Icon and Box Colors */
.members {
  background: linear-gradient(135deg, #32A8A2, #106C65);
  color: white;
}

.members .icon {
  margin-right: 15px;
  color: white;
}

.team {
  background: linear-gradient(135deg, #FF9900, #FFCC00);
  color: white;
}

.team .icon {
  color: white;
}

.wallet {
  background: linear-gradient(135deg, #A933FF, #D580FF);
  color: white;
}

.wallet .icon {
  color: white;
}

.orders {
  background: linear-gradient(135deg, #FF5733, #FF8D1A);
  color: white;
}

.orders .icon {
  color: white;
}

/* Grid Item Text */
.dashboard h3 {
  margin: 10px 0;
  font-size: 1.5rem;
  color: white;
}

