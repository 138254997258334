.Main2 {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start; /* تغيير align-items إلى flex-start */
    min-height: 100vh;
    direction: rtl;
    overflow-x: hidden; /* إخفاء التمرير الأفقي */
}
.header2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.back-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #106C65;
    font-size: 16px;
    cursor: pointer;
}

.back-button svg {
    margin-left: 5px;
}
.container2 {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 800px;
    overflow: auto; /* إضافة overflow: auto لتمكين التمرير */
}


.members-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.member-item2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d3d3d3;
    padding: 30px 10px;
    border-radius: 10px;
    position: relative;
}

.member-item2.deleted-account {
    background-color: #ff7b7b; /* لون أحمر أغمق */
}

.member-name {
    font-size: 16px;
    color: #000;
    margin-left: 10px;
}

.member-status {
    display: flex;
    gap: 5px;
}

.dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: 3px;
    display: inline-block;
}
.levvels p{
font-size: 11px;
}
.active2 {
    background-color: green;
}

.deleted {
    background-color: red;
}

.deleted-label {
    margin-top: 20px;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.6);
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    color: #000;
    
}
.LOWLEV{
display: flex;
justify-content: end;
font-size: 17px;
margin-right: 10px;
}



@media (max-width: 600px) {
    .container {
        width: 100%;
        padding: 10px;
    }

    .member-item2 {
        flex-direction: column;
        align-items: flex-start;
    }

    .member-status {
        margin-top: 10px;
        align-items: flex-end;
    }

    .deleted-label {
        top: auto;
        right: auto;
        bottom: 10px;
        left: 10px;
        transform: none;
    }
    .LOWLEV{
    justify-content: start;
    }
    .header2 h1{
    font-size: 22px;
    }
}
