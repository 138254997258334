.back-to-top {
    position: fixed;
    bottom: 130px; 
    right: 20px;  
    width: 50px; 
    height: 50px; 
    background-color: #1cc3b2; 
    border-radius: 50%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    transition: background-color 0.3s ease; 
    cursor: pointer; 
    z-index: 5;
  }
  
  .back-to-top i {
    color: white; 
    font-size: 24px; 
  }
  
  .back-to-top:hover {
    background-color: #000000; 
  }
  