
.PrImg{
    position: relative;
   width: 100%;
   height: 450px;
   background-color: black;
   z-index: 1;
   }
   .PrImg img{
   width: 100%;
   height: 100%;
   object-fit: cover;
   opacity:.3 ;
   transition : all ease 3s;
   }
   .PrImg h1{
   position: absolute;
   width: 100%;
   top: 40%;
   color: white;
   font-size: 55px;
   text-align: center;
   }
   .PrImg span{
  color: #32A8A2;
   }

.card {
    position: relative;
    width: 300px;
    height: 300px; 
    margin-left: 20px;
    background: lightgrey;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 1s ease-in-out;
    border: 2px solid rgb(255, 255, 255);
  }
  
  .background {
    position: absolute;
    inset: 0;
    background-color: #4158D0;
    background-image:  linear-gradient(43deg, #D4AF37 0%, #B8860B 46%, #FFD700 100%);
  }
  .background2 {
    position: absolute;
    inset: 0;
    background-color: #4158D0;
    background-image:    linear-gradient(43deg, #9DFDC7 0%, #70E1F5 46%, #FFD194 100%);
  }
  .background3 {
    position: absolute;
    inset: 0;
    background-color: #4158D0;
    background-image:  linear-gradient(90deg, #000000 0%, #2A2A2A 50%, #595959 100%);
  }
  
  .logo {
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    transition: all 0.6s ease-in-out;
    font-size: 1.3em;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 3px;
  }
  .name {
    position: absolute;
    right: 50%;
    bottom: 20%;
    text-align: center;
    transform: translate(50%, 50%);
    transition: all 0.6s ease-in-out;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 2px;
  }
  
  .logo .logo-svg {
    fill: white;
    width: 30px;
    height: 30px;
  }
  .logo2 {
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    transition: all 0.6s ease-in-out;
    font-size: 1.3em;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 3px;
  }
  .name2 {
    position: absolute;
    right: 50%;
    bottom: 20%;
    text-align: center;
    transform: translate(50%, 50%);
    transition: all 0.6s ease-in-out;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 2px;
  }
  
  .logo2 .logo-svg {
    fill: white;
    width: 30px;
    height: 30px;
  }
  
  .icon2 {
    display: inline-block;
    width: 30px;
    height: 30px;
  }
  
  .icon2 .svg {
    fill: rgba(255, 255, 255, 0.797);
    width: 100%;
    transition: all 0.5s ease-in-out;
  }
  
  .box {
    position: absolute;
    padding: 10px;
    text-align: right;
    border-top: 2px solid rgb(255, 255, 255);
    border-right: 1px solid white;
    border-radius: 10% 13% 42% 0%/10% 12% 75% 0%;
    box-shadow: rgba(100, 100, 111, 0.364) -7px 7px 29px 0px;
    transform-origin: bottom left;
    transition: all 1s ease-in-out;
  }
  
  .box::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  
  .box:hover .svg {
    fill: white;
  }
  
  .box1 {
    width: 60%;
    height: 60%;
    bottom: -70%;
    left: -70%;
  }
  
  .box1 {
    background: radial-gradient(circle at 30% 107%, #91e9ff 0%, #00ACEE 90%);
  }
  
  .box1:hover::before {
    opacity: 1;
  }
  
  .box1:hover .icon2 .svg {
    filter: drop-shadow(0 0 5px white);
  }
  
  .box2 {
    width: 40%;
    height: 40%;
    bottom: -50%;
    left: -50%;
    transition-delay: 0.2s;
  }
  
  .box2 {
    background: radial-gradient(circle at 30% 107%, #adebad 0%, #25d366 90%);
  }
  
  .box2:hover::before {
    opacity: 1;
  }
  
  .box2:hover .icon2 .svg {
    filter: drop-shadow(0 0 5px white);
  }
  
  .box3 {
    width: 20%;
    height: 20%;
    bottom: -30%;
    left: -30%;
    transition-delay: 0.4s;
  }
  
  .box3 {
    background: radial-gradient(circle at 30% 107%, #e5e5ff 0%, #a29bfe 5%, #ff4a93 60%, #0078ff 90%);
  }
  
  .box3:hover::before {
    opacity: 1;
  }
  
  .box3:hover .icon2 .svg {
    filter: drop-shadow(0 0 5px white);
  }
  
  .box4 {
    width: 10%;
    height: 10%;
    bottom: -10%;
    left: -10%;
    transition-delay: 0.6s;
  }
  
  .card:hover {
    transform: scale(1.1);
  }
  
  .card:hover .box {
    bottom: -1px;
    left: -1px;
  }
  
  .card:hover .logo {
    transform: translate(175px, -20px);
    letter-spacing: 0px;
  }
  .card:hover .name {
    transform: translate(-30px, -150px);
    letter-spacing: 0px;
  }
.adminnn{
width: 70px;

}
.card:hover .logo2 {
  transform: translate(145px, -50px);
  letter-spacing: 0px;
}
.card:hover .name2 {
  transform: translate(-50px, -150px);
  letter-spacing: 0px;
}

  @media (max-width: 965px) {
.row .card{
margin-bottom: 35px;
}
  }
  @media (min-width: 965px) and (max-width: 1285px) {
    .row .card{
    width: 250px;
    height: 270px;
    margin-bottom: 15px;
    }
    .card:hover .logo {
      transform: translate(145px, -10px);
      letter-spacing: 0px;
    }
    .card:hover .name {
      transform: translate(-8px, -120px);
      letter-spacing: 0px;
    }
    .card:hover .logo2 {
      transform: translate(115px, -40px);
      letter-spacing: 0px;
    }
    .card:hover .name2 {
      transform: translate(-30px, -130px);
      letter-spacing: 0px;
    }
      }