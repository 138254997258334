.user-profile-container {
    /* ... باقي الأكواد ... */
  }
  
  .user-profile-details p {
    position: relative;
  }
  
  .user-profile-details i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #3498db;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .save-button:hover {
    background-color: #218838;
  }
  .user-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f7f8fc;
  }
  
  .user-profile-card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    margin-top: 20px;
  }
  
  .user-profile-picture img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .user-profile-details p {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
  }
  
  .user-id-images {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .user-id-image img {
    width: 150px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .user-id-image img:hover {
    transform: scale(1.05);
  }
  
  .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .custom-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .custom-modal-image {
    width: 100%;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 8px;
  }
  
  .custom-modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #f44336;
    color: white;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  