/* إعداد الصفحة الرئيسية */
.under-review-members-page {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #f39c12, #e67e22); /* خلفية بتدرجات البرتقالي */
    min-height: 100vh;
    width: 100%;
    font-family: 'Roboto', sans-serif;
  }
  
  /* إعداد العنوان */
  h2 {
    text-align: center;
    color: white;
    font-size: 32px;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px; /* توسيع المسافات بين الحروف لتأثير عصري */
  }
  
  /* تصميم قائمة الأعضاء */
  .members-list {
    width: 100%;
    max-width: 900px; /* زيادة العرض الأقصى للمربعات */
    display: flex;
    flex-direction: column;
    gap: 20px; /* توسيع المسافات بين المربعات */
  }
  
  /* تصميم المربعات */
  .member-card3 {
    background: linear-gradient(135deg, #ffffff, #f8f9fa); /* تدرجات لونية عصرية للمربعات */
    border-radius: 15px; /* زوايا دائرية للمربعات */
    padding: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* تأثير الظل */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .member-card3:hover {
    transform: translateY(-5px); /* حركة لأعلى عند التمرير فوق المربع */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* تأثير ظل أكبر عند التمرير */
  }
  
  /* إعداد معلومات الأعضاء */
  .member-info268 {
    font-size: 20px; /* تكبير الخط */
    margin-bottom: 10px;
    color: #333;
    letter-spacing: 1px;
  }
  
  .info-label3 {
    font-weight: bold;
    color: #e67e22; /* لون مميز لتفاصيل المعلومات */
    margin-right: 8px;
  }
  
  .member-actions {
    margin-top: 15px;
  }
  
  .profile-button {
    background-color: #e67e22;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .profile-button:hover {
    background-color: #d35400; /* تغيير لون الزر عند التمرير */
    transform: translateY(-2px); /* حركة بسيطة للزر عند التمرير */
  }
  
  /* تصميم متجاوب للشاشات الصغيرة */
  @media (max-width: 768px) {
    .member-card3 {
      padding: 15px;
    }
  
    h2 {
      font-size: 28px;
    }
  
    .member-info268 {
      font-size: 18px;
    }
  
    .profile-button {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
  