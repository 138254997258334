    .comment-box {
    padding: 20px;
    background-color: #ffffff;
  }
  
  .comment-form {
    display: flex;
    flex-direction: column;
  }
  
  .comment {
    margin-top: 10px;
    padding: 10px;
  }
  
  .comment h6 {
    color: #106C65;
  }
  
  .comment p {
    margin: 5px 0;
  }
  
  .comment .MuiTypography-caption {
    font-size: 0.8em;
    color: #999;
  }
  