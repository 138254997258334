/* إعداد صفحة تسجيل الدخول */
.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #74ebd5, #ACB6E5);
    font-family: 'Poppins', sans-serif;
}

/* تصميم بطاقة تسجيل الدخول */
.signin-card {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 400px;
    width: 100%;
    text-align: center;
}

/* تصميم العناوين */
.signin-card h2 {
    margin-bottom: 20px;
}

/* تصميم عناصر النموذج */
.form-group {
    margin-bottom: 15px;
    text-align: left;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* تصميم زر تسجيل الدخول */
.signin-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
}

.signin-button:hover {
    background-color: #2980b9;
}
