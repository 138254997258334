  .App{
width: 100%;
height: 100%;
}  


[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

[dir="ltr"] {
  direction: ltr;
  text-align: left;
}
html, body {
  height: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* لتحسين التمرير على أجهزة iOS */
}