.language-switcher {
    position: relative;
    display: inline-block;
   
  }
  
  .toggle-language {
    background-color: #32A8A2;
    color: #fff;
    border: 1px solid #106C65;
    padding: 8px 22px;
    border: 2px solid #fff;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
    transition: all 0.4s ease-in;
  }
  
  .toggle-language:hover {
    background-color: #004d40;
    color: #fff;
  }
  


  