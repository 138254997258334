.footer {
    position: relative;
    bottom: 0;
    padding: 30px 0 0;
    background-color: #106C65 ;
    margin-top: 60px;
    width: 100%;
    height: 100%;
}
.footer .footer-top {
	padding: 40px 0px 0px 0px;
}
.footer.custom-footer .footer-top {
    border-top: 4px solid #fff;
	padding: 60px 0;
}

.footer-title {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: capitalize;
    text-align: left;
}
.footer .footer-widget.footer-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.footer .footer-widget .footer-logo {
	margin-bottom: 20px;

}
.footer-about{
  position: relative;
  top: -40px;
}
.footer-about p{
text-align: justify;
width: 80%;
}
.footer .footer-widget .footer-about-content p {
	color: #F0F0F0;
}
.footer .footer-widget .footer-about-content p:last-child {
	margin-bottom: 0;
}
.footer .footer-menu ul li {
    margin-bottom: 10px;
    position: relative;
}
.footer .footer-menu ul li:last-child {
	margin-bottom: 0;
}
.footer .footer-menu ul li a {
	color: #F0F0F0;
	font-size: 15px;
	padding-left: 20px;
	position: relative;
	transition: all 0.4s ease 0s;
}
.footer .footer-widget.footer-menu ul li a:hover {
	color: #fff;
	letter-spacing: 0.5px;
	padding-left: 30px;
}
.footer .footer-menu ul li a::before {
    content: "\f101";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 0;
    margin-right: 5px;
    position: absolute;
    top: 0;
}
.footer-contact-info {
	color: #F0F0F0;
	font-size: 15px;
}
.footer-contact-info .footer-address {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.footer-contact-info .footer-address span {
	margin-right: 20px;
}
.footer-contact-info .footer-address span i.fa-map-marker-alt {
	font-size: 20px;
}
.footer-contact-info p i {
	margin-right: 15px;
}
.footer .footer-bottom .copyright {
    border-top: 1px solid #fff;
	padding: 30px 0;
}
.footer .footer-bottom .copyright-text p {
	color: #F0F0F0;
	font-size: 15px;
}
.footer .footer-bottom .copyright-text p a {
	color: #0071DC;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}
.footer .footer-bottom .copyright-text p a:hover {
	color: #fff;
}
.footer .footer-bottom .copyright-text p.title {
	font-weight: 400;
	margin: 10px 0 0;
}
.footer .social-icon ul {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	list-style: none;
	padding: 0;
	margin: 0;
}
.footer .social-icon ul li {
	margin-right: 15px;
}
.footer .social-icon ul li:last-child {
	margin-right: 15px;
}
.footer .social-icon ul li a {	
	color: #fff;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 20px;
	transition: all 0.4s ease 0s;
}
.footer .social-icon ul li a:hover {
	color: #09e5ab;
}
.policy-menu {
    font-size: 14px;
    margin: 0;
    padding: 0;
    text-align: right;
}
.policy-menu li {
    display: inline-block;
    margin-right: 15px;
}
.policy-menu li:last-child {
	margin-right: 0;
}
.policy-menu li a {
    color: #fff;
}
.policy-menu li a:hover, .policy-menu li a:focus {
	color: #09dca4;
}
.policy-menu li::after {
	color: #fff;
    content: "|";
    font-weight: 300;
    position: relative;
    left: 10px;
}
.policy-menu li:last-child::after {
    content: "";
}
.footer.footer-2 .footer-menu ul li a::before {
	display: none;
}
.footer.footer-2 .footer-menu ul li a {
    font-size: 13px;
    padding-left: 0;
}
.footer.footer-2 .footer-widget.footer-menu ul li a:hover {
    letter-spacing: 0;
    padding-left: 0;
}
.footer.footer-2 .footer-title {
    color: #0071DC;
    font-size: 18px;
	margin-bottom: 10px;
}
.footer.footer-2 .footer-title-two {
    color: #20C0F3;
    font-size: 18px;
	margin-bottom: 10px;
}
.footer.footer-2 .footer-widget .footer-logo {
    margin-bottom: 10px;
}
.footer.footer-2 .footer-widget .footer-logo img {
    width: 120px;
}
.footer.footer-2 .footer-widget .footer-about-content p {
    font-size: 13px;
}
.footer.footer-2 .footer-address p {
    font-size: 13px;
}
.footer.footer-2 .footer-contact-info {
    font-size: 13px;
}
.footer.footer-2 .footer-contact-info .footer-address span i.fa-map-marker-alt {
    font-size: 16px;
}
.footer-top {
  position: relative;
  z-index: 0;
  background-color: #106C65  ;
  border-bottom: 1px solid rgba(114, 108, 148, 0.2);
}
.footer-top.style-01 {
  border-bottom: 1px solid rgba(114, 108, 148, 0.2);
  background-color: #e9eff0;
}
.footer-top.style-01 .widget .widget-title {
  color: var(--main-color-one);
}
.footer-top.style-02 {
  border-bottom: 1px solid rgba(114, 108, 148, 0.2);
  background-color: #fff;
}
.footer-top.style-02 .widget .widget-title {
  color: var(--main-color-one);
}
.footer-top.style-03 {
  border-bottom: 1px solid rgba(114, 108, 148, 0.2);
  background-color: #2e2a59;
}
.footer-top.style-03 .widget .widget-title {
  color: var(--main-color-one);
}
.footer-top.style-04 {
  border-bottom: 1px solid rgba(114, 108, 148, 0.2);
  background-color: #fafafa;
}
.footer-top.style-04 .widget .widget-title {
  color: var(--main-color-one);
}
.footer-top.style-05 {
  background-color: #000629;
}
.footer-top.style-06 {
  background-color: #04000f;
}
.footer-top .bg-img {
  position: absolute;
  top: 40%;
  left: 10%;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  z-index: -1;
}
.footer-top .bg-img-02 {
  position: absolute;
  top: 10%;
  right: 10%;
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  z-index: -1;
}
.footer-top .bg-img-03 {
  position: absolute;
  top: 0;
  left: 30%;
  width: 120px;
  height: 95px;
  background-repeat: no-repeat;
  z-index: -1;
}
.Footercen{
text-align: center;
}
.footercen-text{
margin-top: 30px;
color: white;
}
.social-link {
  text-decoration: none;
  color: white;
  transition: all 0.3s ease;
}
.footercen-text span{
font-size: 25px;
}
.social-link i {
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-link span {
  font-size: 1rem;
  margin-left: 8px; /* إضافة مسافة بين الأيقونة والنص */
  transition: color 0.3s ease;
}

.social-link:hover {
  transform: scale(1.1);
}

.telegram i {
  background: linear-gradient(45deg, #0088cc, #33bfff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.facebook i {
  background: linear-gradient(45deg, #1877f2, #4c96f5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.instagram i {
  background: linear-gradient(45deg, #f58529, #dd2a7b, #8134af);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: white;
}

.twitter i {
  background: linear-gradient(45deg, #1da1f2, #3aa4f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* تخصيص لون الرمز فقط ليكون أبيض */
.telegram i,
.facebook i,
.instagram i,
.twitter i {
  color: white; /* اللون الأبيض للأيقونات */
}
.social-item a:hover {
color: white;
}