/* EditProfile.css */

.edit-profile {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .edit-profile h2 {
    text-align: center;
    color: #333;
  }
  
  .edit-profile form div {
    margin-bottom: 15px;
  }
  
  .edit-profile label {
    display: block;
    margin-bottom: 5px;
    color: #666;
    font-weight: bold;
  }
  
  .edit-profile input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .edit-profile button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-profile button:hover {
    background-color: #45a049;
  }
  