/* إعداد الصفحة */
.statistics-container {
    padding: 20px;
    background: linear-gradient(135deg, #f0e68c, #f1d4d4);
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* إعداد العنوان */
  .statistics-title {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* إعداد حاوية الكروت */
  .statistics-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* زيادة الحد الأدنى لعرض الكرت */
    gap: 20px;
    width: 100%;
    max-width: 1200px;
  }
  
  /* تصميم الكروت */
  .statistics-card {
    background-color: #fff;
    border-radius: 15px;
    padding: 30px; /* زيادة المساحة الداخلية */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: transform 0.3s ease;
    min-height: 120px; /* تعيين ارتفاع أدنى للكرت */
  }
  
  /* تأثير التحويم */
  .statistics-card:hover {
    transform: translateY(-5px);
  }
  
  /* تصميم تدرج الخلفية */
  .card-gradient {
    background: linear-gradient(135deg, #6dd5ed, #2193b0);
    color: white;
  }
  
  /* إعداد النصوص */
  .statistics-card h2 {
    font-size: 22px; /* زيادة حجم العنوان */
    margin-bottom: 10px;
  }
  
  .statistics-card p {
    font-size: 28px; /* زيادة حجم النص */
    font-weight: bold;
    margin: 0;
  }
  
  /* تصميم متجاوب للشاشات الصغيرة */
  @media (max-width: 768px) {
    .statistics-title {
      font-size: 28px;
    }
  
    .statistics-card h2 {
      font-size: 20px; /* زيادة حجم العنوان في الشاشات الصغيرة */
    }
  
    .statistics-card p {
      font-size: 24px; /* زيادة حجم النص في الشاشات الصغيرة */
    }
  }
  