body {
    font-family: 'Arial', sans-serif;
    margin: 0px;
    color: #333;
    animation: fadeIn 2s ease-in-out;
    overflow-x: hidden;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.back-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #106C65;
    font-size: 16px;
    cursor: pointer;
}

.back-button svg {
    margin-left: 5px;
}
.withdrawal-container {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    text-align: center;
    animation: fadeInUp 1s ease-in-out;
}

h2 {
    color: #106C65;
    margin-bottom: 20px;
    font-size: 1.8em;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.input-field {
    width: 100%;
    padding: 15px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 1em;
}

.input-field[readonly] {
    background-color: #e9ecef;
    cursor: not-allowed;
}

small {
    display: block;
    margin-top: 5px;
    color: #666;
}

.total-amount {
    margin: 20px 0;
    font-size: 1.2em;
    color: #32A8A2;
    font-weight: bold;
}

.withdraw-button {
    padding: 15px 30px;
    font-size: 1.2em;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background-color: #106C65;
    color: #fff;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.withdraw-button:hover {
    background-color: #32A8A2;
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    text-align: center;
    border-radius: 10px;
    animation: fadeInUp 0.5s ease-in-out;
}

.checkmark {
    font-size: 2.5em;
    color: #32A8A2;
    margin-bottom: 20px;
}

.close-modal-button {
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background-color: #106C65;
    color: #fff;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.close-modal-button:hover {
    background-color: #32A8A2;
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .withdrawal-container {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .withdrawal-container {
        width: 95%;
        padding: 20px;
    }

    .input-field {
        font-size: 0.9em;
    }

    .withdraw-button {
        font-size: 1em;
    }

    .total-amount {
        font-size: 1em;
    }
}
